import {useQuery} from 'react-query'
import {isNotEmpty, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {CreateBuyout} from './CreateBuyout'

const BuyoutModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: buyout,
    error,
  } = useQuery(
    `${QUERIES.BUYOUTS_LIST}-buyout-${itemIdForUpdate}`,
    () => {
      // console.log(buyout)
      // return getCategoryById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <CreateBuyout />
  }

  // if (!isLoading && !error && buyout) {
  //   return <EditCategory isCategoryLoading={isLoading} dataForEditing={buyout} />
  // }

  return null
}

export {BuyoutModalFormWrapper}
