import {useQueryRequest} from '../../../core/QueryRequestProvider'
import ListSearch from '../../../../../../modules/custom/lists/ListSearch'

const BuyoutsListHeader = () => {
  const {updateState} = useQueryRequest()

  return (
    <div className='card-header border-0 pt-6 justify-content-start'>
      <ListSearch updateState={updateState} />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {/* {selected.length > 0 ? <BuyoutsListGrouping /> : <BuyoutsListToolbar />} */}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {BuyoutsListHeader}
