/* eslint-disable jsx-a11y/anchor-is-valid */
import {Parcel} from '../../../../core/_models/_parcels-models'
import {FormattedMessage, useIntl} from 'react-intl'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

type Props = {
  parcel: Parcel
}

const ParcelCostCell = ({parcel}: Props) => {
  const intl = useIntl()

  const {flow, status_logs} = parcel

  const renderTooltip = (props: any) => (
    <Tooltip id='button-tooltip' {...props}>
      <FormattedMessage id='PARCELS.LIST.DEBT.TOOLTIP' />
    </Tooltip>
  )
  return (
    <div className='text-center'>
      {flow && status_logs?.some((item) => item.id_status === 9) && (
        <>
          {' '}
          <p className='text-dark fw-bold d-block fs-6 mb-2'>
            {flow.sum.cost && `$${flow.sum.cost}`}
          </p>
          {flow.sum.debt > 0 && (
            <OverlayTrigger
              placement='right'
              delay={{show: 250, hide: 400}}
              overlay={renderTooltip}
            >
              <p
                className={`p-1 mb-2 fw-semibold text-dark fs-9 StatusBar__status bg-gray-300 d-inline`}
              >
                {intl.formatMessage({id: 'PARCELS.LIST.DEBT'})} ${flow.sum.debt}
              </p>
            </OverlayTrigger>
          )}
        </>
      )}
    </div>
  )
}

export {ParcelCostCell}
