/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {
  getSavedFilter,
  getSavedPagination,
  initialQueryState,
  KTCard,
  KTSVG,
  QUERIES,
  TABLE_LIST_FILTER_KEY,
} from '../../../../_metronic/helpers'
import {TracksTable} from './table/TracksTable'
import {TracksListHeader} from './components/header/TracksListHeader'
import {useLocation, useParams} from 'react-router-dom'
import {FormattedMessage, useIntl} from 'react-intl'
import OnePageModal from '../_modals/one-page/OnePageModal'
import {pathnameValue} from '../core/_funcs'
import {TracksListFilter} from './components/header/TracksListFilter'

const TracksList = () => {
  const {itemIdForUpdate, setItemIdForUpdate, isArchive, setIsArchive} = useListView()
  const {type} = useParams()

  const location = useLocation()
  const {state: locState}: {pathname: string; state: any} = location
  const {updateState} = useQueryRequest()
  const intl = useIntl()

  const openAddTrackModal = () => {
    setItemIdForUpdate(null)
  }

  useEffect(() => {
    const retrievedData = getSavedPagination()
    const savedFilter = getSavedFilter(QUERIES.TRACKS_LIST)

    let state = {
      ...initialQueryState,
      filter: {...savedFilter},
      itemsPerPage: retrievedData,
    }

    if (setIsArchive) {
      if (type === 'archive') {
        setIsArchive(true)
        state = {
          ...initialQueryState,
          filter: {...savedFilter, archive: 1},
        }
      } else if (type === 'sea') {
        setIsArchive(false)
        state = {
          ...initialQueryState,
          filter: {...savedFilter, archive: 0, type_delive: 1},
          //locState.filters to add when division in dashboard gets added
        }
      } else if (type === 'air') {
        setIsArchive(false)
        state = {
          ...initialQueryState,
          filter: {...savedFilter, archive: 0, type_delive: 0},
          //locState.filters to add when division in dashboard gets added
        }
      }
    }

    updateState(state)
    window.localStorage.setItem(
      TABLE_LIST_FILTER_KEY(QUERIES.TRACKS_LIST),
      JSON.stringify(savedFilter)
    )
  }, [type])

  useEffect(() => {
    if (locState?.create === true) {
      setItemIdForUpdate(null)
    } else {
      setItemIdForUpdate(undefined)
    }
  }, [locState])

  return (
    <>
      <div
        style={{marginTop: '-4%', zIndex: '60'}}
        className='d-flex justify-content-between align-items-center mb-8 position-relative'
      >
        <h3>{pathnameValue(type, intl).pageTitle}</h3>
        <div className='d-flex flex-gutter'>
          {isArchive === false && (
            <>
              <TracksListFilter />
              <button type='button' className='btn btn-sm btn-primary' onClick={openAddTrackModal}>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                <FormattedMessage id='TRACKS.PAGE.LIST.ADD_BUTTON' />
              </button>
            </>
          )}
        </div>
      </div>
      <KTCard>
        <TracksListHeader />
        <TracksTable />

        {itemIdForUpdate !== undefined && <OnePageModal />}
      </KTCard>
    </>
  )
}

const TracksListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TracksList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {TracksListWrapper}
