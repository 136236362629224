import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {setYupLocale} from '../../../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../../../_metronic/assets/ts/components'
import {updatePassword} from '../../../../../modules/auth/core/_requests'
import {FormattedMessage, useIntl} from 'react-intl'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {passwordRegex} from '../../../../../../_metronic/helpers/custom/regexs'

export function ProfilePassword() {
  const initialValues = {
    password: '',
    confirmPassword: '',
  }
  const intl = useIntl()
  setYupLocale(intl)
  const registrationSchema = Yup.object().shape(
    {
      password: Yup.string()
        .min(8)
        .max(20)
        .matches(passwordRegex, intl.formatMessage({id: 'VALIDATION.INVALID'}))
        .required(),
      confirmPassword: Yup.string()
        .required()
        .when('password', {
          is: (val: string) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref('password')],
            intl.formatMessage({id: 'VALIDATION.PASSWORDS'})
          ),
        }),
    },
    // @ts-ignore
    ['password', 'confirmPassword']
  )
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        const res = await updatePassword(values.password)
        console.log('upd p', res)

        setSuccess(true)

        setTimeout(() => {
          setSuccess(false)
        }, 3000)
      } catch (error: any) {
        console.error(error)
      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <form
          className='form fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                <FormattedMessage id='PROFILE.PAGE.UPDATE.NEW_PASSWORD' />{' '}
              </label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-8 fv-row' data-kt-password-meter='true'>
                    <div className='position-relative mb-3'>
                      <input
                        type='password'
                        autoComplete='off'
                        {...formik.getFieldProps('password')}
                        className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.password && formik.errors.password,
                          },
                          {
                            'is-valid': formik.touched.password && !formik.errors.password,
                          }
                        )}
                      />
                      {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* begin::Meter */}
                    <div
                      className='d-flex align-items-center mb-3'
                      data-kt-password-meter-control='highlight'
                    >
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2' />
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px' />
                    </div>
                    {/* end::Meter */}

                    <div className='text-muted'>
                      <FormattedMessage
                        id='AUTH.INPUT.PASSWORD_COND'
                        values={
                          // @ts-ignore
                          {b: (chunks: any) => <b>{chunks}</b>}
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                <FormattedMessage id='AUTH.INPUT.CONFIRM_PASSWORD' />
              </label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='password'
                      autoComplete='off'
                      {...formik.getFieldProps('confirmPassword')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid':
                            formik.touched.confirmPassword && formik.errors.confirmPassword,
                        },
                        {
                          'is-valid':
                            formik.touched.confirmPassword && !formik.errors.confirmPassword,
                        }
                      )}
                    />
                    {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.confirmPassword}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CardFooter loading={loading} success={success} />
        </form>
      </div>
    </>
  )
}
