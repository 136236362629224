const ReportError = ({error, location}: any) => {
  return (
    <button
      className='show-chat btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
      style={{fontFamily: 'inherit !important', width: 'auto', padding: 'inherit'}}
      data-id={'new'}
    >
      Сповістити про помилку
    </button>
  )
}

export {ReportError}
