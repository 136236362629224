import {Buyout} from '../../core/_models/_buyout-models'
import {BuyoutPriceItem} from './BuyoutPriceItem'
import {useIntl} from 'react-intl'
import {establishCountryFromOwner} from '../../../../../../_metronic/helpers/custom/funcs/establishOwnerFromDomain'

interface Props {
  buyout: Buyout
  isArchive: boolean
}

export function BuyoutPrices({buyout, isArchive}: Props) {
  const {
    sug_sum,
    real_sum,
    summary,
    discount,
    delivery_cost,
    invoice_cost,
    comm_sum,
    cost_of_delivery,
    commission_for_ransom,
  } = buyout
  const intl = useIntl()
  const country = establishCountryFromOwner()

  const data = [
    {
      label: intl.formatMessage({id: 'BUYOUT_PAGE.COST_TAB.EXPECTED'}),
      price: sug_sum,
      currency: '$',
    },
    {label: intl.formatMessage({id: 'BUYOUT_PAGE.COST_TAB.REAL'}), price: real_sum, currency: '$'},
    {
      label: intl.formatMessage({id: 'BUYOUT_PAGE.COST_TAB.RANSOM_FEE'}, {commission_for_ransom}),
      price: comm_sum,
      currency: '$',
      details: (
        <p
          className='text-muted fs-6 m-0'
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({id: 'BUYOUT_PAGE.COST_TAB.MIN_FEE'}),
          }}
        ></p>
      ),
    },

    {
      label: intl.formatMessage({id: 'BUYOUT_PAGE.COST_TAB.BROKERAGE'}),
      price: invoice_cost,
      currency: '$',
      notShowIfNull: true,
    },
    {
      label: intl.formatMessage({id: 'BUYOUT_PAGE.COST_TAB.ADD_DISCOUNTS'}),
      price: discount,
      currency: '$',
    },
    {
      label: intl.formatMessage({id: `BUYOUT_PAGE.COST_TAB.${country.intl}_DELIVERY`}),
      price: cost_of_delivery,
      currency: '$',
      notShowIfNull: true,
    },
    {
      label: intl.formatMessage({id: 'BUYOUT_PAGE.COST_TAB.TOTAL_COST'}),
      bold: true,
      price: summary,
      currency: '$',
    },

    {
      label: intl.formatMessage({id: 'BUYOUT_PAGE.COST_TAB.REC_COUNTRY_DELIVERY'}),
      price: delivery_cost,
      currency: '$',
    },
    {
      label: intl.formatMessage({id: 'BUYOUT_PAGE.COST_TAB.FULL_COST_WITH_DELIVERY'}),
      price: +(real_sum + comm_sum + cost_of_delivery + delivery_cost).toFixed(2),
      currency: '$',
    },
  ]

  return (
    <div className='card mb-5 mb-xl-10 pt-10'>
      <div className='card-body py-9 px-20 cardBody'>
        <div className='table-responsive'>
          <h3>{intl.formatMessage({id: 'BUYOUT_PAGE.COST_TAB'})}</h3>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <tbody>
              {data?.map((item, index) => (
                <BuyoutPriceItem key={index} price={item} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
