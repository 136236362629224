import Carousel, {Modal, ModalGateway} from 'react-images'
import {ILightboxImageObject} from '../../../../_metronic/helpers/custom/tsHelpers/fileHelpers'

interface Props {
  viewerIsOpen: boolean
  closeLightbox: () => void
  currentImage: number
  images: ILightboxImageObject[]
}

export function ImageLightbox({viewerIsOpen, closeLightbox, currentImage, images}: Props) {
  return (
    <>
      {/* @ts-ignore */}
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={images.map((x: ILightboxImageObject) => ({
                source: x.src,
                alt: x.title,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
