import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUser, requestPassword, switchToVector} from '../core/_requests'
import {FormattedMessage, useIntl} from 'react-intl'
import {setYupLocale, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {crauzerProfileApi} from '../../../pages/profile/_requests'

const initialValues = {
  email: '',
}

export function ForgotPassword() {
  const location = useLocation()
  let navigate = useNavigate()
  let {token: paramsToken, id: uid} = useParams()
  // @ts-ignore
  initialValues.email = location.state?.email || ''
  const intl = useIntl()
  setYupLocale(intl)
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email().min(5).max(50).required(),
  })
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      requestPassword(values.email)
        .then(({data}) => {
          setHasErrors(false)
          setStatus(data.message)
        })
        .catch((error) => {
          setHasErrors(true)
          setStatus(
            error.response?.data.message || intl.formatMessage({id: 'VALIDATION.UNKNOWN_EMAIL'})
          )
        })
        .finally(() => {
          setLoading(false)
          setSubmitting(false)
        })
    },
  })

  const {saveAuth, setCurrentUser} = useAuth()
  const try_token_auth = async (authToken: string, vector: boolean) => {
    // if (vector === true) {
    //   switchToVector(true)
    // }
    saveAuth({token: authToken})
    const {data: user} = await getUser()
    const crUser = await crauzerProfileApi.getCurrentUser()
    setCurrentUser({...user, ...crUser})
    navigate('/profile/password')
  }
  const try_recovery_link = (token: string, id?: string) => {
    // if (id) {
    //   switchToVector(true)
    //   requestRecovery(token, id)
    //     .then(({data: {success, message, token}}) => {
    //       setHasErrors(false)
    //       if (success && token) {
    //         try_token_auth(token, true)
    //       }
    //     })
    //     .catch((error) => {
    //       setHasErrors(true)
    //       formik.setStatus(error.response?.data.message)
    //     })
    // } else {
    switchToVector(false)

    try_token_auth(token, false)
    // }
  }

  useEffect(() => {
    // if (paramsToken && uid) {
    //   try_recovery_link(paramsToken, uid)
    // } else
    if (paramsToken) {
      try_recovery_link(paramsToken)
    }
  }, [paramsToken, uid])

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-7'>
          {/* begin::Title */}
          <h1 className='text-dark mb-5'>
            <FormattedMessage id='AUTH.FORGOT.TITLE' />
          </h1>
          {/* end::Title */}

          {/* begin::Link */}
          {!hasErrors && formik.status ? (
            <Link to='/' className='btn btn-sm btn-primary'>
              <FormattedMessage id='GENERAL.BUTTONS.RETURN_HOME' />
            </Link>
          ) : (
            <div className='text-gray-400 fw-bold fs-4'>
              <FormattedMessage id='AUTH.FORGOT.DESC' />
            </div>
          )}
        </div>

        {/* begin::Title */}
        {formik.status && (
          <div
            className={clsx('mb-lg-15 alert', {
              'alert-danger': hasErrors,
              'alert-success': !hasErrors,
            })}
          >
            <div className='alert-text font-weight-bold'>
              <FormattedMessage id={formik.status} />
            </div>
          </div>
        )}
        {/* end::Title */}
        {!hasErrors && formik.status ? (
          <div className='text-center'>
            <img
              src={toAbsoluteUrl('/media/auth/ok.png')}
              className='mw-100 mh-300px theme-light-show'
              alt=''
            />
          </div>
        ) : (
          <>
            <div className='fv-row mb-10'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
              <input
                type='email'
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
              <Link to='/auth/login'>
                <button
                  type='button'
                  id='kt_login_password_reset_form_cancel_button'
                  className='btn btn-lg btn-light-primary fw-bolder me-4'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  <FormattedMessage id='AUTH.GENERAL.CANCEL_BUTTON' />
                </button>
              </Link>{' '}
              <button
                type='submit'
                id='kt_password_reset_submit'
                className='btn btn-lg btn-primary fw-bolder'
              >
                <span className='indicator-label'>
                  <FormattedMessage id='AUTH.GENERAL.SUBMIT_BUTTON' />
                </span>
                {loading && (
                  <span className='indicator-progress'>
                    <FormattedMessage id='AUTH.GENERAL.WAIT' />
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </>
        )}
      </form>
    </>
  )
}
