import {Toast} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {Notification} from '../Notification'

interface Props {
  loading?: boolean
  buttonText?: string
  sendRequest?: (bool?: any) => any
  handleApi?: any
  apiData?: any
  disabled?: boolean
  success: boolean
  notification?: {
    success: boolean
    color: string
    intl: string
    icon: string
  } | null
}
export function CardFooter({
  loading,
  disabled = false,
  buttonText,
  sendRequest,
  handleApi,
  apiData,
  success,
  notification,
}: Props) {
  const intl = useIntl()
  const buttonTextFinal = buttonText ? buttonText : intl.formatMessage({id: 'GENERAL.BUTTONS.SAVE'})
  const disabledSubmit = loading === true || disabled === true ? true : false
  const performRequest = (e?: React.MouseEvent<HTMLElement>, preventDefault?: boolean) => {
    try {
      if (preventDefault === true && e) {
        e.preventDefault()
      }
      if (sendRequest) {
        sendRequest(true)
      } else if (handleApi) {
        handleApi(apiData)
      } else {
        console.log('empty click')
      }
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <div className='card-footer d-flex justify-content-end py-6 px-9 position-relative'>
      <Toast
        show={success}
        // onClose={() => handleClose(data.id)}
        delay={3000}
        autohide
        style={{zIndex: '0'}}
        className={`bg-light-${notification?.color} text-gray-800 w-100 position-absolute top-0 start-0 border-0`}
      >
        <Toast.Body className={`bg-light-${notification?.color}`}>
          <Notification
            width='w-50'
            // noteText={intl.formatMessage({id: 'GENERAL.TOOLTIPS.SUCCESSFUL_SAVING'})}
            // color={'success'}
            // icon='/media/icons/duotune/general/gen043.svg'
            noteText={intl.formatMessage({
              id: notification?.intl || 'GENERAL.TOOLTIPS.SUCCESSFUL_SAVING',
            })}
            color={notification?.color || 'success'}
            icon={notification?.icon || '/media/icons/duotune/general/gen043.svg'}
          />
          <p className='m-0 p-0'></p>
        </Toast.Body>
      </Toast>
      {handleApi ? (
        <button
          type='submit'
          className='btn btn-primary'
          disabled={loading}
          style={{zIndex: '1'}}
          onClick={(e) => performRequest(e, true)}
        >
          {!loading && buttonTextFinal}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              <FormattedMessage id='GENERAL.BUTTONS.WAIT' />

              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      ) : (
        <button
          type='submit'
          className='btn btn-primary'
          disabled={disabledSubmit}
          style={{zIndex: '1'}}
          onClick={() => performRequest()}
        >
          {!loading && buttonTextFinal}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              <FormattedMessage id='GENERAL.BUTTONS.WAIT' />

              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      )}
    </div>
  )
}
