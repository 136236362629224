/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {
  confirmEmailResend,
  confirmViaTelegram,
  facebookLogin,
  getUser,
  googleLogin,
  login,
  switchToVector,
  telegramLogin,
} from '../core/_requests'
import {toAbsoluteUrl, setYupLocale} from '../../../../_metronic/helpers'
import {FormattedMessage, useIntl} from 'react-intl'
import {useAuth} from '../core/Auth'
import SocialButton from './SocialButton'
import {useLayout, usePageData} from '../../../../_metronic/layout/core'
import {emailRegex, passwordRegex, phoneRegex} from '../../../../_metronic/helpers/custom/regexs'
import {getHostUrl} from '../../../../_metronic/helpers/custom/funcs/establishOwnerFromDomain'
import TelegramLoginButton, {TelegramUser} from 'telegram-login-button'
import {crauzerProfileApi} from '../../../pages/profile/_requests'
import {ScrollTopComponent} from '../../../../_metronic/assets/ts/components'
import {useMutation} from 'react-query'

const initialValues = {
  email: '',
  password: '',
  confToken: undefined,
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const location = useLocation()
  let {token: paramsToken} = useParams()
  const {websiteData} = useLayout()
  const {setPageCustomData} = usePageData()

  const try_token_auth = async () => {
    const {data: user} = await getUser()
    const crUser = await crauzerProfileApi.getCurrentUser()
    setCurrentUser({...user, ...crUser, justLoggedIn: true})
    // setPageCustomData((prev: any) => ({...prev, }))
    console.log('set')
  }

  useEffect(() => {
    if (paramsToken) {
      switchToVector(true)
      saveAuth({token: paramsToken})
      try_token_auth()
    }
  }, [paramsToken])
  // @ts-ignore
  initialValues.email = location.state?.email || initialValues.email
  const intl = useIntl()
  setYupLocale(intl)
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .min(5)
      .max(50)
      .required()
      .test('username', intl.formatMessage({id: 'VALIDATION.USERNAME'}), function (value) {
        value = value ? value : ''
        const phoneValue = value.replace(/\D/g, '')
        let isValidEmail = emailRegex.test(value)
        let isValidPhone = phoneRegex.test(phoneValue)
        return !(!isValidEmail && !isValidPhone)
      }),
    password: Yup.string()
      .min(6)
      .max(20)
      .matches(passwordRegex, intl.formatMessage({id: 'VALIDATION.INVALID'}))
      .required(),
  })
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  let navigate = useNavigate()

  const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    return JSON.parse(jsonPayload)
  }

  const resendEmail = async (email: string) => {
    try {
      await confirmEmailResend(email)
      return navigate('/auth/registration/done', {state: {email}})
    } catch (error) {
      console.log('resend err', error)
    }
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting, setFieldError}) => {
      setStatus(false)
      setLoading(true)
      let username = values.email
      const phoneValue = username.replace(/\D/g, '')
      let isValidPhone = phoneRegex.test(phoneValue)
      if (isValidPhone) username = phoneValue
      let authData = undefined
      try {
        const {data: auth} = await login(username, values.password, intl.locale, false)

        const tokenInfo = parseJwt(auth.token)
        console.log('token', tokenInfo)

        if (tokenInfo.roles.indexOf('ROLE_COMPANY_ADMIN') !== -1) {
          // @ts-ignore
          window.location.href = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_DASHBOARD}${process.env.REACT_APP_URL}/auth/${auth.token}`
        } else {
          authData = auth
        }
      } catch (error: any) {
        let errMsg = intl.formatMessage({id: 'ERROR'})
        const receivedErr = error.response.data?.message

        if (receivedErr && receivedErr === 'AUTH.REGISTRATION.EMAIL_CONFIRMATION_REQUIRED') {
          setFieldError('email', intl.formatMessage({id: receivedErr}))
          setStatus({show: false, text: 'show-resend'})
        } else if (receivedErr) {
          errMsg = intl.formatMessage({
            id: receivedErr,
          })
          setStatus(errMsg)
        }
      } finally {
        saveAuth(authData)
        if (authData) {
          try_token_auth()
        }

        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const socialLogin = async (res: {
    _provider: string
    _token: {idToken: string; accessToken: string}
  }) => {
    let func, token
    if (res._provider === 'google') {
      func = googleLogin
      token = res._token.accessToken
    }
    if (res._provider === 'facebook') {
      func = facebookLogin
      token = res._token.accessToken
    }
    try {
      if (func && token) {
        // switchToVector(true)
        const {data: auth} = await func(token)
        saveAuth(auth)
        try_token_auth()
      }
    } catch (error) {
      console.error(error)
      saveAuth(undefined)
    } finally {
      setLoading(false)
    }
  }

  const [confToken, setConfToken] = useState<string | null>(null)

  const handleTelegramResponse = async (user: TelegramUser) => {
    try {
      console.log(user)

      const res = await telegramLogin(user)
      console.log('htr res', res)
      saveAuth({token: res.data.token})
      try_token_auth()
    } catch (error: any) {
      console.log('telegram err', error)
      let errMsg = intl.formatMessage({id: 'ERROR'})
      const receivedErr = error.response.data?.message

      if (receivedErr && receivedErr === 'AUTH.REGISTRATION.EMAIL_CONFIRMATION_REQUIRED') {
        formik.setFieldError('email', intl.formatMessage({id: receivedErr}))
        formik.setStatus({show: false, text: 'show-resend'})
      } else if (receivedErr && receivedErr === 'AUTH.TELEGRAM_PHONE_NUMBER_REQUIRED') {
        console.log('err token', error?.response?.data?.data?.telegram_check_token)
        errMsg = intl.formatMessage({
          id: receivedErr,
        })

        formik.setFieldValue('confToken', error?.response?.data?.data?.telegram_check_token)
      } else if (receivedErr) {
        errMsg = intl.formatMessage({
          id: receivedErr,
        })
      }

      ScrollTopComponent.goTop()
      formik.setStatus(errMsg)
    }
  }
  const intervalRef = useRef<NodeJS.Timer | null>(null)

  // @ts-ignore
  const mutation = useMutation(() => confirmViaTelegram(confToken), {
    onSuccess: (data: {data: {token: string; message?: string}}) => {
      console.log('mut data', data)

      let errMsg = intl.formatMessage({id: 'ERROR'})

      if (data.data.token) {
        clearInterval(intervalRef.current!)
        errMsg = ''
        return navigate(`/auth/confirm/${data.data.token}`, {state: {telegram: true}})
      } else if (data.data.message) {
        errMsg = intl.formatMessage({
          id: data.data.message,
        })

        if (data.data.message === 'AUTH.TELEGRAM_USER_NOT_FOUND') {
          clearInterval(intervalRef.current!)
        }
      }

      formik.setStatus(errMsg)
    },
    onSettled: () => {},
    onError: (error: any) => {
      console.log('t login e', error)
      let errMsg = intl.formatMessage({id: 'ERROR'})
      if (error.response.data?.message) {
        console.log('nested err', error.response.data?.message)

        errMsg = intl.formatMessage({
          id: error.response.data?.message,
        })
      }
      formik.setStatus(errMsg)
    },
  })

  const triggerMutation = () => {
    if (formik.values.confToken && confToken) {
      mutation.mutate(formik.values.confToken)
    }
  }

  useEffect(() => {
    if (confToken) {
      intervalRef.current = setInterval(() => {
        triggerMutation()
      }, 2000)

      return () => {
        clearInterval(intervalRef.current!)
      }
    } else {
      return () => {
        clearInterval(intervalRef.current!)
      }
    }
  }, [confToken])

  useEffect(() => {
    console.log('formik.values.confToken', formik?.values?.confToken)

    if (formik.values.confToken) setConfToken(formik.values.confToken)
  }, [formik.values.confToken])

  useEffect(() => {
    const el = document.querySelector('.tgme_widget_login_button')

    if (el) {
      el.classList.add('bg-transparent', 'fs-7', 'text-grey')
    }
  }, [websiteData.telegramBotName])

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>
          <FormattedMessage id='AUTH.LOGIN.TITLE' />
        </h1>
        <div className='text-gray-400 fw-bold fs-4'>
          <FormattedMessage id='AUTH.LOGIN.FIRST_VISIT' />{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            <FormattedMessage id='AUTH.REGISTER.CREATE' />
          </Link>
        </div>
      </div>
      {/* begin::Heading */}
      {formik.status && formik.status.show !== false ? (
        <div className='mb-lg-10 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : null}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          <FormattedMessage id='AUTH.INPUT.EMAIL' />
          {/*{' '}<span className="text-lowercase"><FormattedMessage id="AUTH.GENERAL.OR" /></span>{' '}<FormattedMessage id="AUTH.INPUT.PHONE" />*/}
        </label>
        {/* {JSON.stringify(formik.values)} */}
        <input
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              {' '}
              <span role='alert'>{formik.errors.email}</span>
            </div>
            {formik.status?.text && formik.status?.text === 'show-resend' && (
              <div className='d-flex justify-content-center mt-2'>
                <a
                  className='btn text-primary flex-center btn-light btn-secondary btn-sm'
                  href='#'
                  onClick={() => resendEmail(formik.values.email)}
                >
                  {intl.formatMessage({id: 'AUTH.EMAIL_RESEND'})}
                </a>
              </div>
            )}
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex flex-stack mb-2 mt-n5'>
          {/* begin::Label */}
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>
            <FormattedMessage id='AUTH.INPUT.PASSWORD' />
          </label>
          {/* end::Label */}
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
        <div className='mt-1 d-flex justify-content-end'>
          <Link
            to='/auth/forgot-password'
            state={{email: formik.values.email}}
            className='link-primary fs-6 fw-bolder'
            style={{marginLeft: '5px'}}
          >
            <FormattedMessage id='AUTH.GENERAL.FORGOT_BUTTON' />?
          </Link>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className='indicator-label'>
              <FormattedMessage id='AUTH.GENERAL.CONTINUE' />
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              <FormattedMessage id='AUTH.GENERAL.WAIT' />
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          )}
        </button>

        {/* Social Login */}
        {(websiteData.authParams?.google || websiteData.authParams?.facebook) && (
          <div className='text-center text-muted text-uppercase fw-bolder mb-5'>
            <FormattedMessage id='AUTH.GENERAL.OR' />
          </div>
        )}

        {websiteData.authParams?.google && (
          <SocialButton
            type='button'
            className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5 me-3 fs-7 px-4'
            provider='google'
            scope={'email'}
            appId={websiteData.authParams.google}
            onLoginSuccess={socialLogin}
            onLoginFailure={console.log}
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-25px me-1'
            />
            <FormattedMessage id='AUTH.GENERAL.CONTINUE' />{' '}
            <FormattedMessage id='AUTH.GENERAL.WITH' /> Google
          </SocialButton>
        )}

        {websiteData.authParams?.facebook && (
          <SocialButton
            type='button'
            className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5 me-3 fs-7 px-4'
            provider='facebook'
            scope={'email'}
            appId={websiteData.authParams.facebook}
            onLoginSuccess={socialLogin}
            onLoginFailure={console.log}
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
              className='h-25px me-1'
            />
            <FormattedMessage id='AUTH.GENERAL.CONTINUE' />{' '}
            <FormattedMessage id='AUTH.GENERAL.WITH' /> Facebook
          </SocialButton>
        )}

        {(getHostUrl() === 'vector-usa.biz' ||
          getHostUrl() === 'vectorlogistic.net' ||
          getHostUrl() === 'localhost') &&
          websiteData.telegramBotName && (
            <div>
              <TelegramLoginButton
                botName={websiteData.telegramBotName}
                dataOnauth={handleTelegramResponse}
                className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5 px-4 fs-7 text-grey'
                buttonSize='medium'
              />
            </div>
          )}

        {/* Social Login end */}

        {/*/!* begin::Apple link *!/*/}
        {/*<a href='#' className='btn btn-flex flex-center btn-light btn-lg d-none'>*/}
        {/*  <img*/}
        {/*    alt='Logo'*/}
        {/*    src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}*/}
        {/*    className='h-20px me-3'*/}
        {/*  />*/}
        {/*  <FormattedMessage id='AUTH.GENERAL.CONTINUE' />{' '}*/}
        {/*  <FormattedMessage id='AUTH.GENERAL.WITH' /> Apple*/}
        {/*</a>*/}
        {/*/!* end::Apple link *!/*/}
      </div>
      {/* end::Action */}
    </form>
  )
}
