/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {Formik, Form, FormikValues, Field} from 'formik'
import {useMutation} from 'react-query'
import {StepperComponent} from '../../../../../../../_metronic/assets/ts/components'
import {Modal} from 'react-bootstrap'
import {InputTemplate} from '../../../../../../modules/custom/form-elements/InputTemplate'
import {сreateBuyoutContentCardSchema} from '../../../core/yup/buyout-page'
import {Buyout, IBuyoutFormProds} from '../../../core/_models/_buyout-models'
import {editBuyout, getBuyoutCategories} from '../../../core/_requests'
import {reformOneBuyoutProduct} from '../../../../../../../_metronic/helpers/custom/funcs/reformProducts'
import {InspectionConditions} from '../../../_modals/tabs/goods/InspectionConditions'
import {FormattedMessage, useIntl} from 'react-intl'
import {CategoriesWrapper} from '../../../../../../modules/custom/sections/categories/CategoriesWrapper'
import {useAuth} from '../../../../../../modules/auth'

const inits: IBuyoutFormProds = {
  group: {label: '', value: ''},
  name: {label: '', value: '', brands: []},
  brand: {label: '', value: ''},
  qty: 1,
  cost: '',
  total: '',
  repack: false,
  check_control: false,
  check_control_desc: '',
  link: '',
  alt_link: '',
  sku: '',
  color: '',
  size: '',
  unavaliable: 0,
  weight: '',
  used: false,
  user_name: '',
  model: '',
}
interface Props {
  setIsOpen: (bool: boolean) => void
  isOpen: boolean
  buyout: Buyout
  setCurrBuyout: (obj: Buyout) => void
}

const CreateBuyoutContentCard = ({setIsOpen, isOpen, buyout, setCurrBuyout}: Props) => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const [currentSchema, setCurrentSchema] = useState(
    сreateBuyoutContentCardSchema(intl, +buyout.type_delive === 0 ? 'avia' : 'sea')[0]
  )
  const [initValues] = useState<IBuyoutFormProds>(inits)

  const createBuyoutContentMutation = useMutation(editBuyout, {
    onMutate: () => {
      setIsLoading(true)
    },
    onSuccess: (data) => {
      if (data) {
        setCurrBuyout(data)
      }
      closeModal()
    },

    onError: (error) => {
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
    },
  })

  const closeModal = () => {
    setIsOpen(false)
  }

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(
      сreateBuyoutContentCardSchema(intl, +buyout.type_delive === 0 ? 'avia' : 'sea')[
        stepper.current.currentStepIndex - 1
      ]
    )
  }

  const submitStep = (values: IBuyoutFormProds, actions: FormikValues) => {
    console.log('values', values)

    if (!stepper.current) {
      return
    }

    setCurrentSchema(
      сreateBuyoutContentCardSchema(intl, +buyout.type_delive === 0 ? 'avia' : 'sea')[
        stepper.current.currentStepIndex
      ]
    )

    if (stepper.current.currentStepIndex === stepper.current.totatStepsNumber) {
      stepper.current?.goNext()
      const dataToSend = {
        ...buyout,
        prods: [...buyout.prods, {...reformOneBuyoutProduct(values)}],
        repack: values.repack === true ? 1 : 0,
      }
      console.log('dataToSend', dataToSend)

      createBuyoutContentMutation.mutate(dataToSend)
    } else if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <Modal
      className='modal fade'
      show={isOpen}
      dialogClassName='modal-dialog-centered mw-1000px'
      id='kt_modal_create_buyout_prod'
      aria-hidden='true'
    >
      <div className='modal-content    CreateWebsite__overflow-container'>
        <div className='modal-header'>
          <h2>
            <FormattedMessage id='BUYOUT_PAGE.CONTENT_TAB.ADD_GOODS' />
          </h2>

          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            data-bs-dismiss='modal'
            onClick={closeModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>

        <div className='modal-body py-lg-10 px-5 px-lg-10'>
          <div
            ref={stepperRef}
            className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
            id='kt_modal_create_buyout_prod_stepper'
          >
            <h3 className='text-center fs-2 mb-5 d-md-none d-lg-none fw-normal'>
              {stepper.current ? (
                document.querySelectorAll('.stepper-title')[stepper.current.currentStepIndex - 1]
                  .textContent
              ) : (
                <FormattedMessage id='BUYOUT_PAGE.CONTENT_TAB.GOODS_FORM.GOODS_TAB' />
              )}
            </h3>
            <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
              <div className='stepper-nav ps-lg-10'>
                <div className='stepper-item current CustomStepper' data-kt-stepper-element='nav'>
                  <div className='stepper-wrapper'>
                    {' '}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>1</span>
                    </div>
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>
                        <FormattedMessage id='BUYOUT_PAGE.CONTENT_TAB.GOODS_FORM.GOODS_TAB' />
                      </h3>
                    </div>
                  </div>
                </div>
                <div className='stepper-item CustomStepper' data-kt-stepper-element='nav'>
                  <div className='stepper-line h-40px'></div>

                  <div className='stepper-wrapper'>
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>2</span>
                    </div>

                    <div className='stepper-label'>
                      <h3 className='stepper-title'>
                        <FormattedMessage id='BUYOUT_PAGE.CONTENT_TAB.GOODS_FORM.INSPECTION_TAB' />
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='flex-row-fluid py-lg-5 px-lg-15'>
              <Formik
                validationSchema={currentSchema}
                initialValues={initValues}
                onSubmit={submitStep}
                enableReinitialize={true}
              >
                {({values, setFieldValue}) => (
                  <Form className='form' noValidate id='kt_modal_create_buyout_prod_form'>
                    {' '}
                    <div className='current' data-kt-stepper-element='content'>
                      <div className='w-100'>
                        <div className='fv-row mb-10'>
                          <div className='d-flex flex-column fv-row w-100'>
                            <InputTemplate
                              inputName={`link`}
                              type='text'
                              required={true}
                              title={intl.formatMessage({id: 'BUYOUTS.FORM.GOODS_LINK'})}
                              custom={
                                <Field
                                  className={`form-control form-control-lg form-control-solid`}
                                  name='link'
                                  value={values.link}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setFieldValue('link', e.target.value.replace(/\s/g, ''))
                                  }
                                />
                              }
                            />
                          </div>
                          <div className='d-flex flex-column fv-row w-100'>
                            {' '}
                            <InputTemplate
                              inputName={`alt_link`}
                              type='text'
                              title={intl.formatMessage({id: 'BUYOUTS.FORM.GOODS_SUBST_LINK'})}
                              custom={
                                <Field
                                  className={`form-control form-control-lg form-control-solid`}
                                  name='alt_link'
                                  value={values.alt_link}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setFieldValue('alt_link', e.target.value.replace(/\s/g, ''))
                                  }
                                />
                              }
                            />
                          </div>
                          <div className='d-flex flex-column fv-row w-100'>
                            <CategoriesWrapper
                              inputName={{
                                groups: `group`,
                                categories: `name`,
                                brands: 'brand',
                                user_name: `user_name`,
                                model: `model`,
                              }}
                              setFieldValue={setFieldValue}
                              selectValues={{
                                groups: values.group,
                                categories: values.name,
                                brands: values.brand,
                              }}
                              apiRequest={getBuyoutCategories}
                              values={values}
                              delType={+buyout.type_delive === 0 ? 'avia' : 'sea'}
                            />
                          </div>
                          <div className='d-flex'>
                            {values.name.repack === 1 && (
                              <InputTemplate
                                inputName={`repack`}
                                type='checkbox'
                                checkboxProps={{
                                  checked: values.repack || false,
                                  disabled: false,
                                  setFieldValue: setFieldValue,
                                  value: 1,
                                  inputName: `repack`,
                                  type: 'checkbox',
                                }}
                                title={intl.formatMessage({
                                  id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.REMOVE_BOXES',
                                })}
                              />
                            )}
                            {values.name.used === 1 && <InputTemplate
                              inputName='used'
                              type='checkbox'
                              checkboxProps={{
                                checked: values.used,
                                disabled: false,
                                setFieldValue: setFieldValue,
                                value: 1,
                                inputName: 'used',
                                type: 'checkbox',
                              }}
                              title={intl.formatMessage({ id: 'GENERAL.STATUSES.USED' })}
                            />}
                          </div>
                          <div className='d-flex flex-gutter w-100'>
                            <InputTemplate
                              inputName={`sku`}
                              fieldWidth='w-50'
                              type='text'
                              title={intl.formatMessage({id: 'BUYOUTS.FORM.ART_LABEL'})}
                              // value={item.sku || ''}
                            />
                            <InputTemplate
                              inputName={`color`}
                              fieldWidth='w-50'
                              type='text'
                              title={intl.formatMessage({id: 'BUYOUTS.FORM.COLOR_LABEL'})}
                              // value={item.color || ''}
                            />
                          </div>{' '}
                          <div className='d-flex flex-gutter w-50'>
                            <InputTemplate
                              inputName={`size`}
                              type='text'
                              title={intl.formatMessage({id: 'BUYOUTS.FORM.SIZE_LABEL'})}
                              // value={item.size || ''}
                            />
                          </div>
                          <div className='d-flex flex-gutter w-100'>
                            <InputTemplate
                              inputName={`qty`}
                              fieldWidth='w-50'
                              type='number'
                              required={true}
                              title={intl.formatMessage({
                                id: 'BUYOUT_PAGE.CONTENT_TAB.GOODS_FORM.QTY',
                              })}
                              value={values.qty}
                              handleChange={(val: number) =>
                                setFieldValue(`total`, (val * +values.cost).toFixed())
                              }
                              numberWithControls={true}
                            />

                            <InputTemplate
                              inputName={`cost`}
                              type='number'
                              fieldWidth='w-50'
                              required={true}
                              title={intl.formatMessage({
                                id: 'BUYOUT_PAGE.CONTENT_TAB.GOODS_FORM.COST',
                              })}
                              addSymbol={'$'}
                              value={values.cost}
                              handleChange={(val: number) =>
                                setFieldValue(`total`, (val * +values.qty).toFixed())
                              }
                            />
                          </div>{' '}
                          <div className='d-flex flex-gutter w-100'>
                            {/* {values.name.require_weight && values.name.require_weight === 1 && (
                              <InputTemplate
                                inputName={`weight`}
                                type='number'
                                fieldWidth='w-50'
                                title={intl.formatMessage({
                                  id: 'TRACKS.ADD_MODAL.FORM.GOODS_ITEM.WEIGHT',
                                })}
                                addSymbol={'кг'}
                                value={values.weight}
                              />
                            )} */}
                            <InputTemplate
                              inputName={`total`}
                              addSymbol={'$'}
                              type='number'
                              fieldWidth='w-50'
                              required={true}
                              title={intl.formatMessage({
                                id: 'BUYOUT_PAGE.CONTENT_TAB.GOODS_FORM.TOTAL',
                              })}
                              value={values.total}
                              handleChange={(val: number) => {
                                const newVal = (val / +values.qty).toFixed(1)
                                setFieldValue(`cost`, newVal.replace(/\.0+$/, ''))
                              }}
                            />
                          </div>{' '}
                          {/* {checkProductForCommercialValues(values) === true && (
                            <div className='d-flex justify-content-center px-auto'>
                              <Notification
                                noteText={
                                  <>
                                    <h3 className='fs-6'>
                                      <FormattedMessage id='BUYOUTS.ADD_MODAL.FORM.COMMERCIAL.TITLE' />
                                    </h3>
                                    <TrackTarrifs
                                      delivery_type={
                                        currentUser?.settings?.delivery_type
                                          ? +currentUser?.settings?.delivery_type
                                          : 0
                                      }
                                      parcel_type={1}
                                    />
                                  </>
                                }
                              />
                            </div>
                          )} */}
                        </div>
                      </div>
                    </div>
                    <div data-kt-stepper-element='content'>
                      <div className='w-100'>
                        <div className='fv-row'>
                          <div className='fv-row'>
                            <InputTemplate
                              inputName='check_control'
                              type='checkbox'
                              checkboxProps={{
                                checked: values.check_control,
                                disabled: false,
                                setFieldValue: setFieldValue,
                                value: 1,
                                inputName: 'check_control',
                                type: 'checkbox',
                              }}
                              title={intl.formatMessage({
                                id: 'BUYOUT_PAGE.CONTENT_TAB.GOODS_FORM.ORDER_INSP_DESC',
                              })}
                              containerMarginBottom={false}
                            />
                          </div>

                          <InspectionConditions />
                        </div>
                        {values.check_control === true && (
                          <div className='fv-row'>
                            <InputTemplate
                              type='textarea'
                              inputName='check_control_desc'
                              title={intl.formatMessage({id: 'TRACK_PAGE.INSPECTION_TAB.DETAILS'})}
                              required={false}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='d-flex flex-stack pt-10'>
                      <div className='me-2'>
                        <button
                          onClick={prevStep}
                          disabled={isLoading}
                          type='button'
                          className='btn btn-lg btn-light-primary me-3'
                          data-kt-stepper-action='previous'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr063.svg'
                            className='svg-icon-4 me-1'
                          />
                          <FormattedMessage id='GENERAL.BUTTONS.BACK' />
                        </button>
                      </div>

                      <div>
                        <button
                          type='submit'
                          disabled={isLoading}
                          className='btn btn-lg btn-primary me-3'
                        >
                          <span className='indicator-label'>
                            {stepper.current?.currentStepIndex !==
                              stepper.current?.totatStepsNumber! && (
                              <FormattedMessage id='GENERAL.BUTTONS.NEXT' />
                            )}
                            {stepper.current?.currentStepIndex ===
                              stepper.current?.totatStepsNumber! && (
                              <FormattedMessage id='GENERAL.BUTTONS.CREATE' />
                            )}
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {CreateBuyoutContentCard}
