function getFileType(ending: string) {
  switch (ending) {
    case 'pdf':
      return 1
    default:
      return 0
  }
}

export default getFileType
