import {ITrackFormProds} from '../../core/_models/_tracks-models'

interface Props {
  item: ITrackFormProds
  bgColor?: string
}

export function TrackGoodsItemMinifiedHeader({item, bgColor = ''}: Props) {
  const defImg = '/media/icons/duotune/ecommerce/ecm002.svg'
  return (
    <div className={`table-responsive ${bgColor}`}>
      <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
        <tbody>
          <tr>
            <td className='py-0'>
              <img
                width='45'
                height='45'
                className='rounded-circle w-45px me-2'
                src={item.name.image || defImg}
                alt='goods logo'
              />
            </td>
            {item.name.label && (
              <td className='py-0'>
                {item.name.label}{' '}
                {item.brand &&
                  item.brand?.value &&
                  +item.brand?.value !== 3 &&
                  `(${item.brand.name})`}
              </td>
            )}
            {item.qty && item.cost && (
              <td className='py-0'>
                {item.qty} X {item.cost}$ {item.total && `(${item.total}$)`}{' '}
                {/* {item.weight && `${item.weight} кг`} */}
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  )
}
