import {FC, useEffect, useState} from 'react'
import {DemosToggleDrawer} from './demos-drawer/DemosToggleDrawer'
import {ToggleHelpDrawer} from './help-drawer/ToggleHelpDrawer'
import {DemosDrawer} from './demos-drawer/DemosDrawer'
import {HelpDrawer} from './help-drawer/HelpDrawer'
import {PurchaseButton} from './purchase/PurchaseButton'
import {ToggleReportDrawer} from './report-drawer/ToggleReportDrawer'
import {ReportDrawer} from './report-drawer/ReportDrawer'
import {useAuth} from '../../../app/modules/auth'
import {TogglePollDrawer} from './poll-drawer/TogglePollDrawer'
import {PollDrawer} from './poll-drawer/PollDrawer'
import {usePageData} from '../../layout/core'

const RightToolbar: FC = () => {
  const {currentUser} = useAuth()

  const [anyPollsToPass, setAnyPollsToPass] = useState(false)
  useEffect(() => {
    setAnyPollsToPass(
      !!(currentUser?.availablePolls && currentUser?.availablePolls?.length > 0) ||
        !!(currentUser?.rescheduledPolls && currentUser?.rescheduledPolls?.length > 0)
    )
  }, [currentUser?.availablePolls])

  return (
    <>
      <div className='engage-toolbar d-flex position-fixed px-5 fw-bolder zindex-2 top-50 end-0 transform-90 mt-20 gap-2'>
        {/* <DemosToggleDrawer /> */}
        {/* <PurchaseButton /> */}
        {/* <ToggleHelpDrawer /> */}
      </div>
      <div className='engage-toolbar d-flex flex-column position-fixed px-5 fw-bolder zindex-2 top-50 end-0 mt-20 gap-2'>
        {anyPollsToPass && <TogglePollDrawer />}

        <ToggleReportDrawer />
      </div>
      <PollDrawer />
      <ReportDrawer />
      {/* <DemosDrawer />
        <HelpDrawer /> */}
    </>
  )
}

export {RightToolbar}
