import {Dispatch, SetStateAction} from 'react'
import {ID} from '../../crud-helper/models'
import {IBasicSelect} from './generalHelpers'
import {IShipmentAddressForm} from './profileHelpers'

// vector
export interface IAddressCities {
  id: string
  region: string
  country: string
  name: string
}

export interface ICityStreet {
  ref: string
  name: string
}

export interface INovaPoshtaOffice {
  id: string | number
  big: boolean
  name: string
  number: string | number | null
  pmw: string | number //Place max weight
  tmw: string | number //Total max weight
}

export interface IPostShipmentTitles {
  title?: string
  cityTitle?: string
  officeTitle?: string
  choosePlaceholder?: string
}

export const postMainShipmentDefault = {
  title: 'COMPONENTS.PostShipment.MAIN_NP_TITLE',
  cityTitle: 'COMPONENTS.PostShipment.CITY',
  officeTitle: 'COMPONENTS.PostShipment.NP',
  choosePlaceholder: 'COMPONENTS.PostShipment.NP_PLACEHOLDER',
}

export const postReserveShipmentDefault = {
  title: 'COMPONENTS.PostShipment.RESERVE_NP.TITLE',
  cityTitle: 'COMPONENTS.PostShipment.CITY',
  officeTitle: 'COMPONENTS.PostShipment.NP',
  choosePlaceholder: 'COMPONENTS.PostShipment.NP_PLACEHOLDER',
}
export interface IPostShimpentProps {
  citiesLoading: boolean
  citiesResults: IBasicSelect[]
  offLoading: boolean
  offResults: IBasicSelect[]
  values: IShipmentAddressForm
  filterCities: (query: string) => Promise<void>
  setQuery: Dispatch<SetStateAction<string>>
  titles: IPostShipmentTitles
  withHeadTitles: boolean
}

// crauzer

export interface ICrauzerAddressCities {
  id: number
  hasWarehouse: boolean
  name: string
  region: string
}

export interface ICrauzerNovaPoshtaOffice {
  id: number
  name: string
}
