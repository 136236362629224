import React from 'react'
import {useQuery} from 'react-query'
import {QUERIES} from '../../../_metronic/helpers'
import {locationsApi} from '../../../_metronic/helpers/custom/api/locationsApi'
import {Warehouse} from './Warehouse'

const WarehousesList = () => {
  const {data: countriesList} = useQuery(
    QUERIES.COUNTRY_ABBR_LIST,
    locationsApi.fetchDirectionCountriesList
  )

  return <Warehouse />
}

export default WarehousesList
