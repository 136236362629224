import {Buyout} from '../../core/_models/_buyout-models'
import {BuyoutStatusItem} from './BuyoutStatusItem'
import {FormattedMessage, useIntl} from 'react-intl'

interface Props {
  buyout: Buyout
}

export function BuyoutStatus({buyout}: Props) {
  const intl = useIntl()

  const {statuses} = buyout
  console.log('buyout', buyout)

  return (
    <div className='card mb-5 mb-xl-10 pt-10'>
      <div className='card-body py-9 px-20 cardBody'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-190px'>
                  <FormattedMessage id='PARCELS.P_PAGE.STATUSES.DATE_COLUMN' />
                </th>

                <th className='min-w-120px'>
                  <FormattedMessage id='PARCELS.P_PAGE.STATUSES.STATUS_COLUMN' />
                </th>
                <th className='min-w-100px '>
                  <FormattedMessage id='PARCELS.P_PAGE.STATUSES.AUTHOR_COLUMN' />
                </th>
              </tr>
            </thead>

            <tbody>
              {statuses.map((item) => (
                <BuyoutStatusItem key={item.id_status} log={item} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
