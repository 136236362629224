import {useFormikContext} from 'formik'
import {FormattedMessage, useIntl} from 'react-intl'
import {ISettingsForm} from '../../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {SettingsRadio} from '../../../../../modules/custom/form-elements/radios/SettingsRadio'
import {Notification} from '../../../../../modules/custom/Notification'

const NotificationSettings = () => {
  const intl = useIntl()
  const {setFieldValue, values} = useFormikContext<ISettingsForm>()

  return (
    <div>
      <span className='form-check-label d-flex flex-column align-items-start mb-5'>
        <span className='fw-bolder fs-5 '>
          <FormattedMessage id='PROFILE.PAGE.SETTINGS.NOTIFICATIONS' />
        </span>
      </span>{' '}
      <div>
        {' '}
        <SettingsRadio
          title={intl.formatMessage({
            id: 'PROFILE.PAGE.SETTINGS.NOTIFICATIONS.EMAIL_OPTION',
          })}
          type='checkbox'
          inputName='notification_email'
          formValues={values}
          value={1}
          setFieldValue={setFieldValue}
          checkboxIsChecked={values.notification_email}
        />
        <SettingsRadio
          title={intl.formatMessage({
            id: 'PROFILE.PAGE.SETTINGS.NOTIFICATIONS.TELEGRAM_OPTION',
          })}
          description={
            <a
              href='https://t.me/vectorDbot'
              target='_blank'
              rel='noreferrer noopener'
              className='text-muted fw-bold text-hover-muted d-block fs-6 mb-6'
            >
              <FormattedMessage id='PROFILE.PAGE.SETTINGS.TELEGRAM.OPEN' />
            </a>
          }
          inputName='notification_bm'
          formValues={values}
          value={1}
          type='checkbox'
          setFieldValue={setFieldValue}
          checkboxIsChecked={values.notification_bm}
        />
        <Notification
          noteText={intl.formatMessage({
            id: 'PROFILE.PAGE.SETTINGS.TELEGRAM.NOTE',
          })}
        />
      </div>
    </div>
  )
}

export default NotificationSettings
