import {useRef, useState} from 'react'
import {Overlay, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'
import {copyToClipboard} from '../../../../../_metronic/helpers/custom/funcs/copyToClipboard'
import {statusColor} from '../../../../../_metronic/helpers/custom/funcs/statusColor'
import {Parcel} from '../../core/_models/_parcels-models'

interface Props {
  parcel: Parcel
}

export function ParcelHeaderInfo({parcel}: Props) {
  const {c_code, code, type_delive, weight, length, width, height, prods, c_debt} = parcel
  const parcelCode = c_code || code
  const intl = useIntl()

  const codeRef = useRef(null)
  const [show, setShow] = useState(false)
  const target = useRef(null)

  return (
    <div className='flex-grow-1 w-100'>
      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
        <div className='d-flex flex-column overflow-auto overflow-md-unset overflow-lg-unset'>
          <div className='d-flex align-items-md-center align-items-start mb-4 flex-column flex-md-row justify-content-between justify-content-md-start'>
            <div className='d-flex align-items-center position-relative mb-2 mb-md-0'>
              {+type_delive === 0 ? (
                <KTSVG
                  path='/media/icons/flight.svg'
                  className='svg-icon-primary svg-icon-3 me-1'
                />
              ) : (
                <KTSVG
                  path='/media/icons/anchor.svg'
                  className='svg-icon-primary svg-icon-3 me-1'
                />
              )}
              <p
                className='text-gray-800 m-0 fs-6 fs-md-2 fw-bolder me-3 d-none d-md-block'
                ref={codeRef}
              >
                {parcelCode}
              </p>
              <div className='d-md-none d-lg-none'>
                {' '}
                <OverlayTrigger placement={'top'} overlay={<Tooltip>{parcelCode}</Tooltip>}>
                  <p className='text-gray-800 m-0 fs-6 fs-md-2 fw-bolder me-3'>{parcelCode}</p>
                </OverlayTrigger>
              </div>{' '}
            </div>
            <div
              ref={target}
              onClick={() => {
                copyToClipboard(codeRef, setShow)
                setTimeout(() => {
                  setShow(false)
                }, 1000)
              }}
              className='me-2 mb-2 mb-md-0'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen054.svg'
                className={`svg-icon-3 ${show ? 'svg-icon-dark' : 'svg-icon-gray-600'}`}
              />
            </div>{' '}
            <Overlay target={target.current} show={show} placement='top'>
              {(props) => (
                <Tooltip id='overlay-example' {...props}>
                  {intl.formatMessage({id: 'GENERAL.TOOLTIPS.COPIED'})}{' '}
                </Tooltip>
              )}
            </Overlay>
            {c_debt && c_debt.to_release > 0 && (
              <span className={`p-1 me-2 fw-semobold  StatusBar__status ${statusColor(false)}`}>
                <FormattedMessage id='PARCELS.LIST.STATUSES.TO_BE_PAID' />
              </span>
            )}
          </div>

          <div className='d-flex flex-wrap fw-bold fs-7 mb-4 pe-2'>
            <span className='d-flex align-items-center text-gray-400 me-5 mb-2'>
              <KTSVG
                path='/media/icons/duotune/electronics/elc008.svg'
                className='svg-icon-4 me-1'
              />
              {weight ? `${weight} ${intl.formatMessage({id: 'GENERAL.MEASURES.KG'})}` : '--,'}
            </span>

            <span className='d-flex align-items-center text-gray-400 me-5 mb-2'>
              {`${
                height ? `${height}${intl.formatMessage({id: 'GENERAL.MEASURES.CM'})}` : '--'
              } x ${
                width ? `${width}${intl.formatMessage({id: 'GENERAL.MEASURES.CM'})}` : '--'
              } x ${length ? `${length}${intl.formatMessage({id: 'GENERAL.MEASURES.CM'})}` : '--'}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
