interface Props {
  track: {id: string | number; track_code: string}
}

function ParcelTracksItem({track}: Props) {
  return (
    <tr>
      <td>
        <span className='text-dark text-hover-primary d-block fs-6'>{track.track_code}</span>
      </td>
    </tr>
  )
}

export {ParcelTracksItem}
