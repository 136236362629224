/* eslint-disable jsx-a11y/anchor-is-valid */ import {FC, useEffect, useRef, useState} from 'react'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../_metronic/partials'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {DashboardItemStat} from './DashboardItemStat'
import {useRowSelect} from 'react-table'
import {IDashboardItem} from './core/_models'
import {Link} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'
import {useAuth} from '../../modules/auth'
import {usePageData} from '../../../_metronic/layout/core'
import {getCurrBalance} from '../balance/core/_requests'
import DashboardItemCardTitle from './DashboardItemCardTitle'
import {getConfig} from '../../../_metronic/i18n/Metronici18n'

interface Props {
  item: IDashboardItem
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
}

const DashboardItem = ({
  className,
  chartSize = 70,
  chartLine = 11,
  chartRotate = 145,
  item,
}: Props) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const {selectedLang} = getConfig()
  useEffect(() => {
    refreshChart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    setTimeout(() => {
      initChart(chartSize, chartLine, chartRotate)
    }, 10)
  }

  const {currentUser} = useAuth()
  const {setPageCustomData} = usePageData()
  const [currentState, setCurrentState] = useState<'avia' | 'sea'>('avia')

  const {rows, title, number, linkTo, blank, type, zeroItemsMessage, noData} = item

  return (
    <div className={`card card-flush ${className}  flex-shrink-1`}>
      <div className='card-header pt-5'>
        {linkTo ? (
          blank === true ? (
            <a href={toAbsoluteUrl(linkTo)} target='_blank' rel='noreferrer noopener'>
              <DashboardItemCardTitle number={+number} title={title} type={type} />
            </a>
          ) : (
            <Link to={`/${linkTo}`}>
              <DashboardItemCardTitle number={+number} title={title} type={type} />
            </Link>
          )
        ) : (
          <DashboardItemCardTitle number={+number} title={title} type={type} />
        )}
        {/* {type === 'track' && (
          <div className=' d-flex flex-gutter align-items-center'>
            <div className='nav-group nav-group-outline mx-auto' data-kt-buttons='true'>
              <button
                type='button'
                className={
                  'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ' +
                  (currentState === 'avia' && 'active')
                }
                onClick={() => {
                  setCurrentState('avia')
                }}
                data-kt-plan='avia'
              >
                Авіа
              </button>
              <button
                type='button'
                className={
                  'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ' +
                  (currentState === 'sea' && 'active')
                }
                onClick={() => {
                  setCurrentState('sea')
                }}
                data-kt-plan='sea'
              >
                Море
              </button>
            </div>
          </div>
        )} */}

        {!!linkTo && (
          <>
            {blank === true ? (
              <a
                href={toAbsoluteUrl(linkTo)}
                target='_blank'
                rel='noreferrer noopener'
                className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
              >
                <KTSVG path='media/icons/duotune/arrows/arr095.svg' className='svg-icon-2' />
              </a>
            ) : (
              <Link
                to={`/${linkTo}`}
                className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
              >
                <KTSVG path='media/icons/duotune/arrows/arr095.svg' className='svg-icon-2' />
              </Link>
            )}
          </>
        )}
      </div>

      {noData === true ? (
        <div className='d-flex flex-column flex-col-gutter align-items-center'>
          {' '}
          <p className='text-primary text-center fw-semibold fs-6 me-2 my-5 px-7'>
            {zeroItemsMessage}
          </p>
          {type === 'buyout' && (
            <Link to={'/buyouts/create'}>
              <button type='button' className='btn btn-primary mb-3'>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                <FormattedMessage id='BUYOUTS.LIST.ADD_BUTTON' />
              </button>
            </Link>
          )}
          {type === 'track' && (
            <Link to={'/tracks/create'}>
              <button type='button' className='btn btn-primary mb-3'>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                <FormattedMessage id='TRACKS.PAGE.LIST.ADD_BUTTON' />
              </button>
            </Link>
          )}
          {type === 'balance' && currentUser?.can_add_balance === true && (
            <Link to='/balance'>
              <button
                className='btn btn-primary'
                // @ts-ignore
                onClick={() =>
                  setPageCustomData({
                    checkoutShow: true,
                    checkoutSum:
                      currentUser?.balance && currentUser?.balance < 0
                        ? Math.abs(currentUser?.balance)
                        : '',
                    refetchFunction: getCurrBalance,
                    refetchQuery: 'currBalance',
                  })
                }
              >
                <FormattedMessage id='PAYMENT.ADD_BUTTON' />{' '}
              </button>
            </Link>
          )}
        </div>
      ) : (
        <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
          <div className='card-body p-0'>
            <table className='table'>
              {/* {type === 'track' && (
                <thead>
                  <tr className='py-4'>
                    <th className='pb-6 pt-0'></th>
                    <th className='pb-6 pt-0'>
                      <KTSVG
                        path='/media/icons/flight.svg'
                        className='svg-icon-primary svg-icon-3'
                      />
                    </th>
                    <th className='pb-6 pt-0 text-center'>
                      <KTSVG
                        path='/media/icons/anchor.svg'
                        className='svg-icon-primary svg-icon-3'
                      />
                    </th>
                  </tr>
                </thead>
              )} */}
              <tbody>
                {rows.map((row, index) => (
                  <DashboardItemStat
                    row={row}
                    key={index}
                    index={index}
                    rowsLength={rows?.length}
                    type={type}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

const initChart = function (
  chartSize: number = 70,
  chartLine: number = 11,
  chartRotate: number = 145
) {
  const el = document.getElementById('kt_card_widget_17_chart')
  if (!el) {
    return
  }
  el.innerHTML = ''

  var options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
    //percent:  el.getAttribute('data-kt-percent') ,
  }

  const canvas = document.createElement('canvas')
  const span = document.createElement('span')

  // @ts-ignore
  if (typeof G_vmlCanvasManager !== 'undefined') {
    // @ts-ignore
    G_vmlCanvasManager.initElement(canvas)
  }

  const ctx = canvas.getContext('2d')
  canvas.width = canvas.height = options.size

  el.appendChild(span)
  el.appendChild(canvas)

  // @ts-ignore
  ctx.translate(options.size / 2, options.size / 2) // change center
  // @ts-ignore
  ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

  //imd = ctx.getImageData(0, 0, 240, 240);
  const radius = (options.size - options.lineWidth) / 2

  const drawCircle = function (color: string, lineWidth: number, percent: number) {
    percent = Math.min(Math.max(0, percent || 1), 1)
    if (!ctx) {
      return
    }

    ctx.beginPath()
    ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
    ctx.strokeStyle = color
    ctx.lineCap = 'round' // butt, round or square
    ctx.lineWidth = lineWidth
    ctx.stroke()
  }

  // Init 2
  drawCircle('#E4E6EF', options.lineWidth, 100 / 100)
  drawCircle(getCSSVariableValue('--kt-primary'), options.lineWidth, 100 / 150)
  drawCircle(getCSSVariableValue('--kt-success'), options.lineWidth, 100 / 250)
}

export {DashboardItem}
