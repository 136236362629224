import {locationsApi} from '../../../../../../../_metronic/helpers/custom/api/locationsApi'
import {IBasicSelect} from '../../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {useState, useEffect} from 'react'
import {
  IShipmentAddress,
  IShipmentAddressForm,
} from '../../../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {PostMain} from './PostMain'
import {PostReserve} from './PostReserve'
import {
  IPostShipmentTitles,
  postMainShipmentDefault,
  postReserveShipmentDefault,
} from '../../../../../../../_metronic/helpers/custom/tsHelpers/locationsHelpers'

interface Props {
  values: IShipmentAddressForm
  editing: boolean
  dataForEditing?: IShipmentAddress
  withReserve?: boolean
  mainTitles?: IPostShipmentTitles
  resTitles?: IPostShipmentTitles
  withHeadTitles?: boolean
  vector: boolean
  filter?: number[]
}

export function PostShipment({
  values,
  editing,
  dataForEditing,
  withReserve = true,
  mainTitles = postMainShipmentDefault,
  resTitles = postReserveShipmentDefault,
  withHeadTitles = true,
  vector,
  filter,
}: Props) {
  console.log('v', vector)

  // main office
  const [mainOfficeCitiesLoading, setMainOfficeCitiesLoading] = useState(false)
  const [mainOfficeCitiesResults, setMainOfficeCitiesResults] = useState<IBasicSelect[]>([])

  const filterMainOfficeCities = async (query: string) => {
    setMainOfficeCitiesLoading(true)
    await locationsApi
      .getAddressCities({city: query, vector, warehouse: 1})
      .then((res) => {
        setMainOfficeCitiesResults(res)
      })
      .catch((err) => console.log(err))
      .finally(() => setMainOfficeCitiesLoading(false))
  }

  const [mainOfficesLoading, setMainOfficesLoading] = useState(false)
  const [mainOfficesResults, setMainOfficesResults] = useState<IBasicSelect[]>([])

  const [mainOfficeSearchQuery, setMainOfficeSearchQuery] = useState('')

  useEffect(() => {
    const filterMainOfficeNumbers = async () => {
      setMainOfficesLoading(true)
      await locationsApi
        .getNovaPoshtaOffices(mainOfficeSearchQuery, values.mainOfficeCity || '', vector, filter)
        .then((res) => {
          setMainOfficesResults(res)
        })
        .catch((err) => console.log(err))
        .finally(() => setMainOfficesLoading(false))
    }
    filterMainOfficeNumbers()
  }, [values.mainOfficeCity, mainOfficeSearchQuery])

  // reserve office

  const [reserveOfficeCitiesLoading, setReserveOfficeCitiesLoading] = useState(false)
  const [reserveOfficeCitiesResults, setReserveOfficeCitiesResults] = useState<IBasicSelect[]>([])

  const filterReserveOfficeCities = async (query: string) => {
    setReserveOfficeCitiesLoading(true)
    await locationsApi
      .getAddressCities({city: query, vector, warehouse: 1, big: 1})
      .then((res) => {
        setReserveOfficeCitiesResults(res)
      })
      .catch((err) => console.log(err))
      .finally(() => setReserveOfficeCitiesLoading(false))
  }

  const [reserveOfficesLoading, setReserveOfficesLoading] = useState(false)
  const [reserveOfficesResults, setReserveOfficesResults] = useState<IBasicSelect[]>([])

  const [reserveOfficeSearchQuery, setReserveOfficeSearchQuery] = useState('')

  useEffect(() => {
    const filterReserveOfficeNumbers = async () => {
      setReserveOfficesLoading(true)
      await locationsApi
        .getNovaPoshtaOffices(
          reserveOfficeSearchQuery,
          values.reserveOfficeCity || '',
          vector,
          filter
        )
        .then((res) => {
          setReserveOfficesResults(
            res.filter((item: {value: string; label: string; big: boolean}) => item.big === true)
          )
        })
        .catch((err) => console.log(err))
        .finally(() => setReserveOfficesLoading(false))
    }
    filterReserveOfficeNumbers()
  }, [values.reserveOfficeCity, reserveOfficeSearchQuery])

  useEffect(() => {
    if (editing === true) {
      console.log('post dataForEditing', dataForEditing)
      if (dataForEditing?.mainOffice) {
        filterMainOfficeCities(dataForEditing?.mainOffice?.city?.name?.toString().toLowerCase())
        setMainOfficeSearchQuery(dataForEditing?.mainOffice?.name?.toString().toLowerCase())
      } else {
        return
      }

      if (dataForEditing?.reserveOffice) {
        filterReserveOfficeCities(
          dataForEditing?.reserveOffice?.city?.name?.toString().toLowerCase()
        )
        setReserveOfficeSearchQuery(dataForEditing?.reserveOffice?.name?.toString().toLowerCase())
      } else {
        return
      }
    } else {
      filterMainOfficeCities('')
      filterReserveOfficeCities('')
    }
  }, [dataForEditing, editing])

  return (
    <>
      <PostMain
        citiesLoading={mainOfficeCitiesLoading}
        citiesResults={mainOfficeCitiesResults}
        offLoading={mainOfficesLoading}
        offResults={mainOfficesResults}
        values={values}
        filterCities={filterMainOfficeCities}
        setQuery={setMainOfficeSearchQuery}
        titles={mainTitles}
        withHeadTitles={withHeadTitles}
      />
      {withReserve === true && (
        <PostReserve
          citiesLoading={reserveOfficeCitiesLoading}
          citiesResults={reserveOfficeCitiesResults}
          offLoading={reserveOfficesLoading}
          offResults={reserveOfficesResults}
          values={values}
          filterCities={filterReserveOfficeCities}
          setQuery={setReserveOfficeSearchQuery}
          titles={resTitles}
          withHeadTitles={withHeadTitles}
        />
      )}
    </>
  )
}
