interface Props {
  type: string
  number: number
  title: string
}
const DashboardItemCardTitle = ({type, number, title}: Props) => {
  return (
    <div className='card-title d-flex flex-column'>
      <div className='d-flex align-items-center'>
        {type === 'balance' && (
          <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>$</span>
        )}{' '}
        <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{number}</span>
      </div>
      <span className='text-gray-400 pt-1 fw-semibold fs-6'>{title}</span>
    </div>
  )
}

export default DashboardItemCardTitle
