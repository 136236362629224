import {FormattedMessage} from 'react-intl'
import {statusColor} from '../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {User} from '../../../../../modules/apps/user-management/users-list/core/_models'

interface Props {
  user: User
}

export function ProfileHeaderName({user}: Props) {
  return (
    <div className='flex-grow-1'>
      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2 w-75'>
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center mb-4 align-items-start flex-column flex-md-row justify-content-between justify-content-md-start'>
            <Name user={user} />
            <Verification user={user} />
          </div>
        </div>
      </div>
    </div>
  )
}

const Name = ({user}: Props) => {
  const {firstName, lastName} = user

  return (
    <div className='d-flex align-items-center mb-md-none'>
      <p className='text-gray-800 text-hover-primary fs-2 fw-bolder m-0 me-3'>
        {`${firstName} ${lastName}`}
      </p>
    </div>
  )
}

const Verification = ({user}: Props) => {
  const {verified} = user

  return (
    <>
      {' '}
      {verified !== undefined ? (
        <span className={`p-1 me-2 fw-semobold  StatusBar__status ${statusColor(+verified)}`}>
          {+verified === 1 && <FormattedMessage id='GENERAL.STATUSES.VERIFIED' />}
          {+verified === 0 && <FormattedMessage id='GENERAL.STATUSES.VERIF_WAITING' />}
        </span>
      ) : null}
    </>
  )
}
