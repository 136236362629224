import {useFormikContext} from 'formik'
import {FormattedMessage, useIntl} from 'react-intl'
import {IPostShimpentProps} from '../../../../../../../_metronic/helpers/custom/tsHelpers/locationsHelpers'
import {InputTemplate} from '../../../../form-elements/InputTemplate'
import {ModalNotification} from '../../../../modals/ModalNotification'
import {Notification} from '../../../../Notification'

export const PostReserve = ({
  citiesLoading,
  citiesResults,
  offLoading,
  offResults,
  values,
  filterCities,
  setQuery,
  titles,
  withHeadTitles,
}: IPostShimpentProps) => {
  const intl = useIntl()
  const {setFieldValue} = useFormikContext()

  return (
    <>
      <div className='d-flex'>
        {withHeadTitles === true && (
          <h3 className='me-1'>
            <FormattedMessage id={titles.title} />
          </h3>
        )}
        <ModalNotification
          svg='/media/icons/duotune/general/gen046.svg'
          children={
            <div className='fs-7 fw-normal'>
              <FormattedMessage id='COMPONENTS.PostShipment.RESERVE_NP.EXPLANATION' />
            </div>
          }
        />
      </div>
      <div className='d-flex justify-content-around flex-column'>
        <InputTemplate
          inputName='reserveOfficeCity'
          required={true}
          type='select'
          value={values.reserveOfficeCity}
          title={intl.formatMessage({id: titles.cityTitle})}
          marginClass='me-4'
          reactSelectprops={{
            options: citiesResults,
            isLoading: citiesLoading,
            onInputChange: filterCities,
            isClearable: true,
            selectName: 'reserveOfficeCity',
            setFieldValue: setFieldValue,
            placeholder: intl.formatMessage({id: 'COMPONENTS.PostShipment.CITY_PLACEHOLDER'}),
            currentValue: citiesResults.find(
              (item: any) => item.value === values.reserveOfficeCity
            ),
          }}
        />
        <InputTemplate
          inputName='reserveOfficeNumber'
          required={true}
          type='select'
          title={intl.formatMessage({id: titles.officeTitle})}
          value={values.reserveOfficeNumber}
          reactSelectprops={{
            options: offResults,
            isLoading: offLoading,
            onInputChange: setQuery,
            isClearable: true,
            selectName: 'reserveOfficeNumber',
            setFieldValue: setFieldValue,
            placeholder: intl.formatMessage({id: titles.choosePlaceholder}),
            currentValue: offResults.find((item: any) => item.value === values.reserveOfficeNumber),
          }}
        />
      </div>
      <Notification
        noteText={intl.formatMessage({id: 'COMPONENTS.PostShipment.RESERVE_NP.PROMPT'})}
      />
    </>
  )
}
