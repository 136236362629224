import {FormattedMessage} from 'react-intl'
import {Link, useLocation} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ReportError} from '../../../../_metronic/partials/layout/report-error/ReportError'

type TProps = {error: {name: string; message: string; stack: string} | null}

const ErrorCustom = () => {
  const location = useLocation()
  const state = location.state as TProps

  // console.log('cs', state)

  return (
    <div className='px-6'>
      {state?.error && (
        <>
          <div className='d-flex flex-gutter justify-content-center align-items-center'>
            <div>
              <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'> {state.error.name}</h1>

              <div className='fw-semibold fs-6 text-gray-500 mb-7'> {state.error.message}</div>
            </div>
            <div className='mb-3'>
              <img
                src={toAbsoluteUrl('/media/illustrations/unitedpalms-1/9.png')}
                className='h-100px theme-light-show'
                alt=''
              />
              <img
                src={toAbsoluteUrl('/media/illustrations/unitedpalms-1/9-dark.png')}
                className='h-100px theme-dark-show'
                alt=''
              />
            </div>
          </div>
          <p> {state.error.stack}</p>

          <div className='mb-5'>
            <Link to='/' className='btn btn-sm btn-primary'>
              <FormattedMessage id='GENERAL.BUTTONS.RETURN_HOME' />
            </Link>
          </div>
          <ReportError error={state.error} />
        </>
      )}
    </div>
  )
}

export default ErrorCustom
