/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {statusColor} from '../../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {Receiver} from '../../../core/_models'

type Props = {
  receiver: Receiver
}

const ReceiverVerifiedCell = ({receiver}: Props) => {
  return (
    <div className='d-flex align-items-center justify-content-center'>
      {+receiver.verified === 1 && (
        <KTSVG
          path='/media/icons/duotune/general/gen043.svg'
          className='svg-icon-1 svg-icon-success'
        />
      )}
      {+receiver.verified === 0 && (
        <KTSVG
          path='/media/icons/duotune/general/gen040.svg'
          className='svg-icon-1 svg-icon-danger svg-bg-white'
        />
      )}
    </div>
  )
}

export {ReceiverVerifiedCell}
