import axios from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {establishOwnerFromDomain} from '../../../../_metronic/helpers/custom/funcs/establishOwnerFromDomain'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'

export interface IWarehouseData {
  id: ID
  countryConfig: {
    country: string
    locationAddressFields: string[]
  }
  addressData: string[]
  addressHidden: boolean
  secondaryAddressData: string[]
  secondaryAddressHidden: boolean
  addressName: string
  secondaryAddressName: string
}
export type WarehousesQueryResponse = Response<Array<IWarehouseData>>

const API = process.env.REACT_APP_API_PATH

const CRAUZER_BASE = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${API}`

export const getWarehouseData = async () => {
  const {selectedLang} = getConfig()

  const {data} = await axios.get<WarehousesQueryResponse>(
    `${CRAUZER_BASE}/locations/client?website=${establishOwnerFromDomain()}`,
    {
      headers: {
        'Accept-Language': selectedLang,
      },
    }
  )

  return data.data
}
