/* eslint-disable jsx-a11y/anchor-is-valid */
import {ReactNode} from 'react'
import {KTSVG} from '../../../_metronic/helpers'

interface Props {
  noteText: string | ReactNode
  borderTop?: boolean
  addClassName?: string
  link?: {text: string; url: string}
  color?: string
  icon?: string
  height?: string
  width?: string
}

const Notification = ({
  noteText,
  borderTop,
  link,
  addClassName,
  color = 'warning',
  icon = '/media/icons/duotune/general/gen044.svg',
  height = '',
  width = 'w-100',
}: Props) => {
  return (
    <div className={`card p-0 ${addClassName || ''} ${height}`}>
      <div className={` ${borderTop ? 'border-top' : ''}`}>
        <div
          className={`notice d-flex bg-light-${color} align-items-center rounded border-${color} border border-dashed px-2 px-md-6 py-2 py-md-4`}
        >
          <KTSVG path={icon} className={`svg-icon-2tx svg-icon-${color} me-4`} />

          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <div className={`fs-6 text-gray-600 ${width} w-md-100`}>
                {typeof noteText === 'string' ? (
                  link ? (
                    <p className='m-0'>
                      {noteText.split(link.text)[0]}
                      <a target='_blank' rel='noopener noreferrer' href={link.url}>
                        {link.text}
                      </a>
                      {noteText.split(link.text)[1]}
                    </p>
                  ) : (
                    noteText
                  )
                ) : (
                  noteText
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Notification}
