import {useState} from 'react'
import {KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
import {Formik, Form, FormikValues} from 'formik'
import {Modal} from 'react-bootstrap'
import {
  IShipmentAddress,
  IShipmentAddressForm,
} from '../../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {createShipmentAddressSchema} from '../../../../../../_metronic/helpers/custom/yup/yup-schemas/addresses'
import {AddressShipment} from './address-shipment/AddressShipment'
import {PostShipment} from './post-shipment/PostShipment'
import {InputTemplate} from '../../../form-elements/InputTemplate'
import {SearchQueryProps} from '../Filter'
import {useQueryClient, useMutation} from 'react-query'
import {locationsApi} from '../../../../../../_metronic/helpers/custom/api/locationsApi'
import {FormattedMessage, useIntl} from 'react-intl'
import {shapeAddressData} from '../../../../../../_metronic/helpers/custom/funcs/shapeAddressData'
import {TypeOfDelivery} from '../../../../../pages/fulfillment/goods/_modals/SendGoods/inputs/TypeOfDelivery'
import {IBasicSelect} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {
  postMainShipmentDefault,
  postReserveShipmentDefault,
} from '../../../../../../_metronic/helpers/custom/tsHelpers/locationsHelpers'
import DeliveryChoice from './DeliveryChoice'

interface Props extends SearchQueryProps {
  setIsOpen: (bool: boolean) => void
  isOpen: boolean
  profileAddresses: IShipmentAddress[]
  tempType: 0 | 1 | 2
  apiMutation: {
    apiRequest: (data: any, id?: string | number) => Promise<any>
    toInvalidate: string
    id?: string | number
  }
  vector: boolean
}

const CreateShipmentAddress = ({
  setIsOpen,
  isOpen,
  profileAddresses,
  tempType,
  apiMutation,
  vector,
}: Props) => {
  const queryClient = useQueryClient()
  const intl = useIntl()
  const [loading, setIsLoading] = useState(false)

  const inits: IShipmentAddressForm = {
    typeOfDelivery: +tempType === 0 ? 1 : +tempType,
    deliveryService: {value: 1, label: 'Нова Пошта'},
    mainAddress: profileAddresses.length === 0 ? true : false,
    city: '',
    premisesType: 'apartment',
    mainOfficeCity: '',
    mainOfficeNumber: '',
    reserveOfficeCity: '',
    reserveOfficeNumber: '',
  }

  const [initValues] = useState<IShipmentAddressForm>(inits)

  const [citiesLoading, setCitiesLoading] = useState(false)
  const [citiesResults, setCitiesResults] = useState<IBasicSelect[]>([])
  const filterCities = async (query: string) => {
    setCitiesLoading(true)
    await locationsApi
      .getAddressCities({city: query, vector})
      .then((res) => {
        setCitiesResults(res)
      })
      .catch((err) => console.log(err))
      .finally(() => setCitiesLoading(false))
  }

  const mutation = useMutation(apiMutation.apiRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(apiMutation.toInvalidate)
      setIsOpen(false)
    },
    onSettled: () => {
      setIsLoading(false)
    },
  })

  const closeModal = () => {
    setIsOpen(false)
  }

  const submitStep = async (values: IShipmentAddressForm, actions: FormikValues) => {
    console.log('values', values)

    const dataToSend = await shapeAddressData(values, vector)

    setIsLoading(true)
    if (apiMutation.toInvalidate === QUERIES.REC_ADDRESS_LIST) {
      mutation.mutate({...dataToSend, receiver_id: apiMutation.id})
    } else if (apiMutation.toInvalidate === QUERIES.FULF_REC_ADDRESS_LIST) {
      mutation.mutate({...dataToSend, receiver: apiMutation.id})
    } else {
      mutation.mutate(dataToSend)
    }

    actions.resetForm()
  }

  return (
    <Modal
      className='modal fade'
      show={isOpen}
      dialogClassName='modal-dialog-centered mw-1000px h-auto'
      id='kt_modal_create_sh_address'
      aria-hidden='true'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>
            {intl.formatMessage({id: 'COMPONENTS.CreateShipmentAddress.HEADING'})}{' '}
            {tempType === 1 &&
              intl.formatMessage({id: 'COMPONENTS.CreateShipmentAddress.HOME_ADDRESS_LABEL'})}
            {tempType === 2 &&
              intl.formatMessage({id: 'COMPONENTS.CreateShipmentAddress.NP_ADDRESS_LABEL'})}
            {tempType === 0 &&
              intl.formatMessage({id: 'COMPONENTS.CreateShipmentAddress.ADDRESS_LABEL'})}
          </h2>

          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            data-bs-dismiss='modal'
            onClick={closeModal}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>

        <div className='modal-body py-lg-10 px-0 px-lg-10'>
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            <Formik
              validationSchema={createShipmentAddressSchema(intl)}
              initialValues={initValues}
              onSubmit={submitStep}
              enableReinitialize={true}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({values, setFieldValue}) => (
                <Form className='form' noValidate>
                  {/* {JSON.stringify(values)} */}

                  <div>
                    <div className='w-100'>
                      {/* needed temporarily while ordinary receivers can have only two addresses: 1 post and 1 home */}
                      {tempType ? (
                        <>
                          {+tempType === 1 && (
                            <AddressShipment
                              setFieldValue={setFieldValue}
                              values={values}
                              editing={false}
                              vector={vector}
                            />
                          )}
                          {+tempType === 2 && (
                            <PostShipment values={values} editing={false} vector={vector} />
                          )}
                        </>
                      ) : (
                        <>
                          <InputTemplate
                            inputName='deliveryService'
                            required={true}
                            type='select'
                            value={values.deliveryService}
                            title={intl.formatMessage({
                              id: 'COMPONENTS.CreateShipmentAddress.DELIV_SERV',
                            })}
                            reactSelectprops={{
                              options: [{value: '1', label: 'Нова Пошта'}],
                              isLoading: false,
                              onInputChange: filterCities,
                              isClearable: true,
                              selectName: 'deliveryService',
                              setFieldValue: setFieldValue,
                              placeholder: intl.formatMessage({
                                id: 'COMPONENTS.CreateShipmentAddress.DELIV_SERV_PLACEHOLDER',
                              }),
                              currentValue: {value: '1', label: 'Нова Пошта'},
                            }}
                          />
                          <div className='d-flex flex-gutter flex-wrap mb-5'>
                            <DeliveryChoice values={values} fontSizeClass='fs-5' />
                          </div>

                          {/* {+values.typeOfDelivery !== 1 && (
                            <InputTemplate
                              inputName='city'
                              required={true}
                              type='select'
                              value={values.city}
                              title={intl.formatMessage({id: 'COMPONENTS.AddressShipment.CITY'})}
                              reactSelectprops={{
                                options: citiesResults,
                                isLoading: citiesLoading,
                                onInputChange: filterCities,
                                isClearable: true,
                                selectName: 'city',
                                setFieldValue: setFieldValue,
                                placeholder: intl.formatMessage({
                                  id: 'COMPONENTS.AddressShipment.CITY_PLACEHOLDER',
                                }),
                                currentValue: citiesResults.find(
                                  (item: any) => item.value === values.city
                                ),
                              }}
                            />
                          )} */}

                          {+values.typeOfDelivery === 1 && (
                            <AddressShipment
                              setFieldValue={setFieldValue}
                              values={values}
                              editing={false}
                              vector={vector}
                            />
                          )}
                          {+values.typeOfDelivery === 2 && (
                            <PostShipment
                              values={values}
                              editing={false}
                              vector={vector}
                              filter={[2, 4]}
                              mainTitles={{
                                ...postMainShipmentDefault,
                                choosePlaceholder: 'COMPONENTS.PostShipment.NP_POST_PLACEHOLDER',
                              }}
                              resTitles={{
                                ...postReserveShipmentDefault,
                                choosePlaceholder: 'COMPONENTS.PostShipment.NP_POST_PLACEHOLDER',
                              }}
                            />
                          )}
                          {+values.typeOfDelivery === 3 && (
                            <PostShipment
                              values={values}
                              editing={false}
                              vector={vector}
                              filter={[5]}
                              mainTitles={{
                                ...postMainShipmentDefault,
                                title: 'COMPONENTS.PostShipment.MAIN_NP_POSTBOX_TITLE',
                                officeTitle: 'COMPONENTS.PostShipment.NP_POSTBOX',
                                choosePlaceholder: 'COMPONENTS.PostShipment.NP_POSTBOX_PLACEHOLDER',
                              }}
                              resTitles={{
                                ...postReserveShipmentDefault,
                                title: 'COMPONENTS.PostShipment.RESERVE_NP_POSTBOX.TITLE',
                                officeTitle: 'COMPONENTS.PostShipment.NP_POSTBOX',
                                choosePlaceholder: 'COMPONENTS.PostShipment.NP_POSTBOX_PLACEHOLDER',
                              }}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div className='d-flex flex-stack pt-10'>
                    <div>
                      <button
                        type='submit'
                        className='btn btn-lg btn-primary me-3'
                        disabled={loading}
                      >
                        {loading === true ? (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Подождите...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        ) : (
                          <span className='indicator-label'>
                            {' '}
                            <FormattedMessage id='GENERAL.BUTTONS.SAVE' />
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {CreateShipmentAddress}
