import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {ReceiverOverview} from './components/overview/ReceiverOverview'
import {ReceiverContacts} from './components/contacts/ReceiverContacts'
import {ReceiverShipment} from './components/shipment/ReceiverShipment'
import {ReceiverHeader} from './header/ReceiverHeader'
import {useQuery} from 'react-query'
import {getReceiverById} from '../receivers-list/core/_requests'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Receiver} from '../receivers-list/core/_models'

const profileBreadCrumbs: Array<PageLink> = [
  // {
  //   title: 'Profile',
  //   path: 'overview',
  //   isSeparator: false,
  //   isActive: false,
  // },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

interface Props {
  id: number
}

const ReceiverPage = ({id}: Props) => {
  const intl = useIntl()
  const {data: receiverById} = useQuery('receiver', () => {
    return getReceiverById(id)
  })

  const [currReceiver, setCurrReceiver] = useState<Receiver | null>(null)

  useEffect(() => {
    if (receiverById) {
      setCurrReceiver(receiverById)
    }
  }, [receiverById])

  const [searchQuery, setSearchQuery] = useState('')

  return (
    <>
      {currReceiver && receiverById && (
        <Routes>
          <Route
            element={
              <>
                <ReceiverHeader receiver={currReceiver} />
                <Outlet />
              </>
            }
          >
            <Route
              path={`overview/:id`}
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>
                    {intl.formatMessage({id: 'RECEIVERS.REC_PAGE.PROFILE'})}
                  </PageTitle>
                  <ReceiverOverview receiver={currReceiver} setCurrReceiver={setCurrReceiver} />
                </>
              }
            />
            <Route
              path={`contacts/:id`}
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>
                    {intl.formatMessage({id: 'RECEIVERS.REC_PAGE.CONTACTS'})}
                  </PageTitle>
                  <ReceiverContacts receiver={currReceiver} setCurrReceiver={setCurrReceiver} />
                </>
              }
            />
            <Route
              path={`shipment/:id`}
              element={
                <>
                  <PageTitle breadcrumbs={profileBreadCrumbs}>
                    {intl.formatMessage({id: 'RECEIVERS.REC_PAGE.ADDRESS'})}
                  </PageTitle>
                  <ReceiverShipment
                    receiverId={id}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                  />
                </>
              }
            />

            <Route index element={<Navigate to={`overview/${id}`} />} />
          </Route>
        </Routes>
      )}
    </>
  )
}

export default ReceiverPage
