import {ErrorMessage, Field, FieldArray, useFormikContext} from 'formik'
import {KTSVG} from '../../../../../_metronic/helpers'
import {FormattedMessage} from 'react-intl'
import {usePageData} from '../../../../../_metronic/layout/core'
import {AddTrackForm} from '../../core/_models/_tracks-models'

interface Props {
  invoiceLinks: string[]
  withTitle?: boolean
  floating?: boolean
}
export const TrackInvoiceLinks = ({invoiceLinks, withTitle = true, floating = false}: Props) => {
  const {setFieldValue, errors, validateField, setFieldTouched} = useFormikContext<AddTrackForm>()
  // console.log('testlinks err', errors)

  return (
    <>
      {/* {withTitle === true && (
        <label
          className={`d-flex align-items-center fs-5 fw-bold mb-2 ${
            required === true ? 'required' : ''
          }`}
        >
          <FormattedMessage id='TRACKS.COMPONENTS.TrackInvoiceLinks.LABEL' />
        </label>
      )} */}
      <div className='d-flex flex-column text-primary w-100'>
        {' '}
        <div className='w-100'>
          <FieldArray
            name='invoiceLinks'
            render={(arrayHelpers) => (
              <div className='d-flex flex-column flex-col-gutter position-relative'>
                {invoiceLinks?.map((item, index) => (
                  <div
                    className='w-100 d-flex justify-content-between align-items-center'
                    key={index}
                  >
                    <div className={`${floating === true ? 'form-floating' : ''} w-100`}>
                      <Field
                        style={{
                          paddingTop:
                            floating === true ? (index === 0 ? '1.85rem' : '0.625rem') : '',
                        }}
                        className={`form-control form-control-lg form-control-solid`}
                        id={`invoiceLinks.${index}`}
                        name={`invoiceLinks.${index}`}
                        value={item}
                        type='text'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue(`invoiceLinks.${index}`, e.target.value.replace(/\s/g, ''))
                        }
                        onBlur={() => {
                          validateField(`invoiceLinks`)
                          setFieldTouched(`invoiceLinks.${index}`, true)
                        }}
                      />

                      {withTitle === true && index === 0 && (
                        <label htmlFor={`invoiceLinks.${index}`}>
                          <FormattedMessage id='TRACKS.COMPONENTS.TrackInvoiceLinks.LABEL' />
                        </label>
                      )}
                    </div>
                    {index === 0 && (
                      <button
                        disabled={item === '' || !!errors.invoiceLinks}
                        className='btn btn-icon btn-bg-light ms-3'
                        type='button'
                        onClick={() => {
                          arrayHelpers.unshift('')
                        }}
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen041.svg'
                          className='svg-icon-1x svg-icon-success d-block my-2'
                        />
                      </button>
                    )}
                    {invoiceLinks?.length > 1 && index !== 0 && (
                      <button
                        className='btn btn-icon btn-bg-light ms-3'
                        type='button'
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen040.svg'
                          className='svg-icon-1x svg-icon-danger d-block my-2'
                        />
                      </button>
                    )}
                  </div>
                ))}
                <div className='text-danger'>
                  <ErrorMessage name='invoiceLinks' />
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </>
  )
}
