import {useEffect} from 'react'
import {FormattedMessage} from 'react-intl'
import {Link, useLocation} from 'react-router-dom'
import {scrollToTabsStart} from '../../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'

export function ProfileHeaderNav() {
  const location = useLocation()

  const profileTabs = [
    {path: '/profile/overview', titleId: 'PROFILE.PAGE.PAGE_TITLE'},
    {path: '/profile/passport', title: 'Паспорт'},
    {path: '/profile/contacts', titleId: 'PROFILE.PAGE.CONTACTS.TITLE'},
    {path: '/profile/shipment', titleId: 'PROFILE.PAGE.ADDRESS.TITLE'},
    {path: '/profile/social-media', titleId: 'PROFILE.PAGE.SOCIAL_MEDIA.TITLE'},
    {path: '/profile/referal', titleId: 'PROFILE.PAGE.REFERAL.TITLE'},
    {path: '/profile/password', titleId: 'PROFILE.PAGE.PASSWORD.TITLE'},
    {path: '/profile/settings', titleId: 'PROFILE.PAGE.SETTINGS.TITLE'},
  ]

  useEffect(() => {
    const currentTab = profileTabs.find((tab) => tab.path === location.pathname)
    if (currentTab) {
      const ulElement = document.getElementById('item-page-header')
      if (ulElement) {
        const link = ulElement.querySelector(`a[href="${currentTab.path}"]`)
        if (link) {
          const parentRect = ulElement.getBoundingClientRect()
          const childRect = link.getBoundingClientRect()
          const offset =
            childRect.left - parentRect.left - parentRect.width / 2 + childRect.width / 2
          ulElement.scrollLeft += offset
        }
      }
    }
  }, [location.pathname, profileTabs])

  return (
    <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
        {profileTabs.map((tab, index) => (
          <li key={index} className='nav-item' onClick={scrollToTabsStart}>
            <Link
              className={`nav-link text-active-primary me-6 text-center ${
                location.pathname === tab.path && 'active'
              }`}
              to={tab.path}
            >
              {tab.titleId ? <FormattedMessage id={tab.titleId} /> : tab.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
