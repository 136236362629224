/* eslint-disable jsx-a11y/anchor-is-valid */
import {statusColor} from '../../../../../_metronic/helpers/custom/funcs/statusColor'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Receiver} from '../../receivers-list/core/_models'
import {FormattedMessage, useIntl} from 'react-intl'
import {ReceiverHeaderName} from './ReceiverHeaderName'
import {ReceiverHeaderInfo} from './ReceiverHeaderInfo'
import {scrollToTabsStart} from '../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'

interface Props {
  receiver: Receiver
}

const ReceiverHeader = ({receiver}: Props) => {
  const location = useLocation()
  const intl = useIntl()

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-between'>
          {' '}
          <div className='d-flex flex-row'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='Receiver' />
              </div>
            </div>
            <div className='d-flex flex-column'>
              <ReceiverHeaderName receiver={receiver} />{' '}
              <div className='d-none d-md-block'>
                {' '}
                {/* <HeaderMessage messageText={admin_comment} author={'Админ'} /> */}
                <ReceiverHeaderInfo receiver={receiver} />
              </div>
            </div>
          </div>
          <div className='d-md-none'>
            {' '}
            {/* <HeaderMessage messageText={admin_comment} author={'Админ'} /> */}
            <ReceiverHeaderInfo receiver={receiver} />
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item' onClick={scrollToTabsStart}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `/receivers/edit/overview/${receiver.id}` && 'active')
                }
                to={`/receivers/edit/overview/${receiver.id}`}
              >
                <FormattedMessage id='RECEIVERS.REC_PAGE.PROFILE' />
              </Link>
            </li>
            <li className='nav-item' onClick={scrollToTabsStart}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `/receivers/edit/contacts/${receiver.id}` && 'active')
                }
                to={`/receivers/edit/contacts/${receiver.id}`}
              >
                <FormattedMessage id='RECEIVERS.REC_PAGE.CONTACTS' />
              </Link>
            </li>
            <li className='nav-item' onClick={scrollToTabsStart}>
              <Link
                className={
                  `nav-link text-active-primary me-6 text-center ` +
                  (location.pathname === `/receivers/edit/shipment/${receiver.id}` && 'active')
                }
                to={`/receivers/edit/shipment/${receiver.id}`}
              >
                <FormattedMessage id='RECEIVERS.REC_PAGE.ADDRESS' />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {ReceiverHeader}
