import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from '../../core/QueryResponseProvider'
import {tracksColumns} from './columns/_columns'
import {Track} from '../../core/_models/_tracks-models'
import {TracksListLoading} from '../components/loading/TracksListLoading'
import {TracksListPagination} from '../components/pagination/TracksListPagination'
import {KTCardBody} from '../../../../../_metronic/helpers'
import {FormattedMessage} from 'react-intl'

const TracksTable = () => {
  const tracks = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => tracks, [tracks])

  const columns = useMemo(() => tracksColumns, [])

  const {response: tracksTotal} = useQueryResponse()

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='d-none d-md-block'>
        {tracksTotal?.totalItems !== undefined && tracksTotal?.itemsPerPage !== undefined && (
          <TracksListPagination
            showPagination={tracksTotal?.totalItems > tracksTotal?.itemsPerPage}
            showItemsPerPage={tracksTotal?.totalItems <= 10 ? false : true}
          />
        )}
      </div>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Track>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Track>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    <FormattedMessage id='TRACKS.PAGE.LIST.NO_RESULTS' />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {tracksTotal?.totalItems !== undefined && tracksTotal?.itemsPerPage !== undefined && (
        <TracksListPagination
          showPagination={tracksTotal?.totalItems > tracksTotal?.itemsPerPage}
          showItemsPerPage={tracksTotal?.totalItems <= 10 ? false : true}
          addClass='mt-4 mt-md-0'
        />
      )}
      {isLoading && <TracksListLoading />}
    </KTCardBody>
  )
}

export {TracksTable}
