import * as Yup from 'yup'
import {trackPartials} from './partials'

const {
  trackCode,
  receiverNeeded,
  receiver,
  deliveryType,
  description,
  prods,
  packageType,
  insurance,
  requireInvoice,
  invoiceFile,
  invoiceLinks,
  earlySubmitAllowed,
  orderInspection,
  inspectionDescription,
} = trackPartials

export const createTrackSchema = (intl: any, delType: 'avia' | 'sea') => [
  Yup.object({
    track_code: trackCode(intl),
    receiverNeeded,
    receiver: Yup.object().when('receiverNeeded', {
      is: true,
      then: receiver(intl),
    }),
    delivery_type: deliveryType,
    description,
  }),

  Yup.object({
    prods: prods(intl, delType),
  }),
  Yup.object({
    package_type: packageType,
    insurance,
  }),
  Yup.object().shape(
    {
      require_invoice: requireInvoice,
      invoice_file: invoiceFile(intl),
      invoiceLinks: invoiceLinks(intl),
    },
    [['invoice_file', 'invoiceLinks']]
  ),
  Yup.object({
    earlySubmitAllowed,
    orderInspection,
    inspectionDescription: inspectionDescription(intl),
  }),
]

export const createTrackOnePageSchema = (intl: any, delType: 'avia' | 'sea') =>
  Yup.object().shape(
    {
      track_code: trackCode(intl),
      receiverNeeded,
      receiver: Yup.object().when('receiverNeeded', {
        is: true,
        then: receiver(intl),
      }),
      delivery_type: deliveryType,
      description,
      prods: prods(intl, delType),
      package_type: packageType,
      insurance,
      orderInspection,
      inspectionDescription: inspectionDescription(intl),
      require_invoice: requireInvoice,
      invoice_file: invoiceFile(intl),
      invoiceLinks: invoiceLinks(intl),
    },
    [['invoice_file', 'invoiceLinks']]
  )
