export const statusColor = (
  status: boolean | number | 'on_storage' | 'dispatched' | 'received' | 'packed'
) => {
  if (status === 2 || status === 'dispatched') {
    return 'StatusBar_on-hold'
  } else if (status === true || status === 1 || status === 'received') {
    return 'StatusBar_active'
  } else if (status === false || status === 0) {
    return 'StatusBar_blocked'
  } else {
    return 'bg-grey-200 text-grey-400'
  }
}
