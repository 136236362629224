import React from 'react'
import {OverlayTrigger} from 'react-bootstrap'
import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'

interface Props {
  problematicEntries: [string, boolean][]
}

const WarningNote = ({problematicEntries}: Props) => {
  return (
    <OverlayTrigger
      placement='top'
      overlay={
        <div
          className='fs-7 fw-normal bg-white px-2 py-1 w-25'
          style={{boxShadow: '0px 1px 5px #8f8f8f', borderRadius: '5px'}}
        >
          <FormattedMessage id='TRACKS.PAGE.LIST.NUMBER.WARNING' />
          <ul className='m-0'>
            {problematicEntries.map(([key, _]) => (
              <li key={key}>
                <FormattedMessage id={`TRACKS.PAGE.LIST.NUMBER.WARNING_${key.toUpperCase()}`} />
              </li>
            ))}
          </ul>
        </div>
      }
    >
      <div className='me-2 cursor-pointer'>
        <KTSVG
          path='/media/icons/duotune/general/gen044.svg'
          className='svg-icon-3 svg-icon-danger'
        />
      </div>
    </OverlayTrigger>
  )
}

export default WarningNote
