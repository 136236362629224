import {useIntl} from 'react-intl'
import {InputTemplate} from '../../form-elements/InputTemplate'

interface Props {
  inputName: string
  disabled: boolean
  value: string
}

const BrandModel = ({inputName, disabled, value}: Props) => {
  const intl = useIntl()
  return (
    <div>
      <InputTemplate
        inputName={inputName}
        type='text'
        disabled={disabled}
        title={intl.formatMessage({id: 'COMPONENTS.BrandModel.TITLE'})}
        value={value}
      />
    </div>
  )
}

export default BrandModel
