import {FC, createContext, useContext, useState, useEffect} from 'react'
import {DefaultConfig} from './_LayoutConfig'
import {DefaultWD} from './_WD'
import {
  setLayoutIntoLocalStorage,
  getEmptyCssClasses,
  getEmptyCSSVariables,
  getEmptyHTMLAttributes,
  LayoutSetup,
} from './_LayoutSetup'

import {
  ILayout,
  ILayoutCSSVariables,
  ILayoutCSSClasses,
  ILayoutHTMLAttributes,
  LayoutType,
  ToolbarType,
  IWD,
} from './_Models'
import {GTM_SCRIPTS, WithChildren} from '../../helpers'
import {pSBC} from '../../helpers'
import {useAuth} from '../../../app/modules/auth'
import {useLang} from '../../i18n/Metronici18n'



export interface LayoutContextModel {
  config: ILayout
  classes: ILayoutCSSClasses
  attributes: ILayoutHTMLAttributes
  cssVariables: ILayoutCSSVariables
  setLayout: (config: LayoutSetup) => void
  setLayoutType: (layoutType: LayoutType) => void
  setToolbarType: (toolbarType: ToolbarType) => void
  setWebsiteData: (data: IWD) => void
  websiteData: IWD
}

const LayoutContext = createContext<LayoutContextModel>({
  config: DefaultConfig,
  classes: getEmptyCssClasses(),
  attributes: getEmptyHTMLAttributes(),
  cssVariables: getEmptyCSSVariables(),
  setLayout: (config: LayoutSetup) => {},
  setLayoutType: (layoutType: LayoutType) => {},
  setToolbarType: (toolbarType: ToolbarType) => {},
  setWebsiteData: (data: IWD) => {},
  websiteData: DefaultWD,
})

const enableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'flex')
  }
}

const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none')
  }
}

const LayoutProvider: FC<WithChildren> = ({children}) => {
  const [config, setConfig] = useState(LayoutSetup.config)
  const [classes, setClasses] = useState(LayoutSetup.classes)
  const [attributes, setAttributes] = useState(LayoutSetup.attributes)
  const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables)
  const [websiteData, setWD] = useState(DefaultWD)
  const {currentUser} = useAuth()
  const locale = useLang()

  const setLayout = (_themeConfig: Partial<ILayout>) => {
    enableSplashScreen()
    const bodyClasses = Array.from(document.body.classList)
    bodyClasses.forEach((cl) => document.body.classList.remove(cl))
    const updatedConfig = LayoutSetup.updatePartialConfig(_themeConfig)
    setConfig(Object.assign({}, updatedConfig))
    setClasses(LayoutSetup.classes)
    setAttributes(LayoutSetup.attributes)
    setCSSVariables(LayoutSetup.cssVariables)
    setTimeout(() => {
      disableSplashScreen()
    }, 500)
  }

  const setToolbarType = (toolbarType: ToolbarType) => {
    const updatedConfig = {...config}
    if (updatedConfig.app?.toolbar) {
      updatedConfig.app.toolbar.layout = toolbarType
    }

    setLayoutIntoLocalStorage(updatedConfig)
    window.location.reload()
  }

  const setLayoutType = (layoutType: LayoutType) => {
    const updatedLayout = {...config, layoutType}
    setLayoutIntoLocalStorage(updatedLayout)
    window.location.reload()
  }

  const setWebsiteData = (data: IWD) => {
    enableSplashScreen()
    setWD(data)
    document.documentElement.style.setProperty('--kt-primary', data.mainColor)
    document.documentElement.style.setProperty('--kt-text-primary', data.mainColor)
    document.documentElement.style.setProperty(
      '--kt-form-check-input-checked-bg-color',
      data.mainColor
    )
    document.documentElement.style.setProperty(
      '--kt-form-check-input-checked-border-color',
      data.mainColor
    )
    document.documentElement.style.setProperty(
      '--kt-form-check-input-checked-bg-color-solid',
      data.mainColor
    )
    document.documentElement.style.setProperty('--kt-primary-inverse', pSBC(0.9, data.mainColor))
    document.documentElement.style.setProperty('--kt-primary-active', pSBC(-0.1, data.mainColor))
    document.documentElement.style.setProperty('--kt-primary-bg', pSBC(0.013, data.mainColor))
    document.documentElement.style.setProperty('--kt-scrolltop-bg-color', data.mainColor)
    document.documentElement.style.setProperty('--kt-scrolltop-bg-color-hover', data.mainColor)

    // @ts-ignore
    const prepUrl = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
    if (data.name)
      // @ts-ignore
      document.getElementById('meta_title').innerText = data.name
    if (data.description)
      // @ts-ignore
      document.getElementById('meta_desc').content = data.description
    if (data.faviconUrl)
      // @ts-ignore
      document.getElementById('meta_fav').href = prepUrl + data.faviconUrl
    if (data.logoUrl) {
      // @ts-ignore
      document.getElementById('metaSplashLogo').src = prepUrl + data.logoUrl
    }

    setTimeout(() => {
      disableSplashScreen()
    }, 500)
  }

  const value: LayoutContextModel = {
    config,
    classes,
    attributes,
    cssVariables,
    setLayout,
    setLayoutType,
    setToolbarType,
    setWebsiteData,
    websiteData,
  }

  useEffect(() => {
    if (websiteData.gtmId) {
      const script = document.createElement('script')
      const scriptContent = document.createTextNode(GTM_SCRIPTS(websiteData.gtmId).dateTime)
      script.appendChild(scriptContent)
      document.head.insertBefore(script, document.head.firstChild)
      const noscriptContainer = document.createElement('div')
      noscriptContainer.innerHTML = GTM_SCRIPTS(websiteData.gtmId).iframe
      const noscriptElement = noscriptContainer.firstChild
      if (noscriptElement) {
        document.body.insertBefore(noscriptElement, document.body.firstChild)
      }
    }
  }, [websiteData.gtmId])

  useEffect(() => {
    if (currentUser?.chat_query) {
      const script = document.createElement('script')
      script.innerHTML = `chat_qeury = '${currentUser?.chat_query}';
          chat_lang='${locale}'`
      document.body.appendChild(script)
      const prepUrl = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
      const chatScript = document.createElement('script')
      chatScript.src = `${prepUrl}/js/chat.js`
      chatScript.async = true
      document.body.appendChild(chatScript)
    }
  }, [currentUser?.chat_query])

  useEffect(() => {
    disableSplashScreen()
  }, [])

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export {LayoutContext, LayoutProvider}

export function useLayout() {
  return useContext(LayoutContext)
}
