import {Route, Routes, Outlet, Navigate, useLocation, useParams} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import {ParcelsListWrapper} from './parcels-list/ParcelsList'
import ParcelPage from './parcel-page/ParcelPage'
import {useIntl} from 'react-intl'

const ParcelsPage = () => {
  const location = useLocation()
  const intl = useIntl()
  const params = useParams()
  const delType = params['*']?.split('/') || []

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list/:type'
          element={
            <>
              <PageTitle>{intl.formatMessage({id: 'PARCELS.PAGE.HEADING'})}</PageTitle>
              <ParcelsListWrapper />
            </>
          }
        />

        <Route
          path={`info/*`}
          element={
            <ParcelPage
              id={+location.pathname.split('/')[location.pathname.split('/').length - 1]}
            />
          }
        />
      </Route>
      <Route index element={<Navigate to={`/parcels/list/${delType[delType?.length - 1]}`} />} />
    </Routes>
  )
}

export default ParcelsPage
