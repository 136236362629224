/* eslint-disable jsx-a11y/anchor-is-valid */
import {Parcel} from '../../../../core/_models/_parcels-models'
import {FormattedMessage, useIntl} from 'react-intl'
import {useState} from 'react'

type Props = {
  parcel: Parcel
}

const ParcelItemsInfoCell = ({parcel}: Props) => {
  return (
    <div>
      <Dimensions parcel={parcel} />
      <ParcelProds parcel={parcel} />
    </div>
  )
}

export {ParcelItemsInfoCell}

const Dimensions = ({parcel}: Props) => {
  const intl = useIntl()
  const {height, width, length, weight} = parcel
  return (
    <span className='text-dark fw-bold d-block fs-6'>
      {!weight && !height && !length && !width
        ? ''
        : `${weight ? `${weight} ${intl.formatMessage({id: 'GENERAL.MEASURES.KG'})}` : '-'}, ${
            height ? `${height}${intl.formatMessage({id: 'GENERAL.MEASURES.CM'})}` : '-'
          } x ${width ? `${width}${intl.formatMessage({id: 'GENERAL.MEASURES.CM'})}` : '-'} x ${
            length ? `${length}${intl.formatMessage({id: 'GENERAL.MEASURES.CM'})}` : '-'
          }`}
    </span>
  )
}

const ParcelProds = ({parcel}: Props) => {
  const {prods} = parcel
  const [showAllGoods, setShowAllGoods] = useState(false)

  return (
    <>
      {prods && prods.length > 4 && prods.length > 5 && !showAllGoods ? (
        <>
          {prods?.slice(0, 4).map(({name, qty}, index) => (
            <ParcelProdsItem name={name} qty={+qty} index={index} key={index} />
          ))}
          <a
            className='text-muted fw-normal text-muted d-block fs-7 cursor-pointer text-hover-primary'
            onClick={() => setShowAllGoods(true)}
          >
            <FormattedMessage
              id='GENERAL.COUNTABLES.MORE.GOODS'
              values={{itemCount: prods.length - 4}}
            />
          </a>
        </>
      ) : (
        <>
          {' '}
          {prods?.map(({name, qty}, index) => (
            <ParcelProdsItem name={name} qty={+qty} index={index} key={index} />
          ))}
          {showAllGoods && (
            <a
              className='text-muted fw-normal text-muted d-block fs-7 cursor-pointer text-hover-primary'
              onClick={() => setShowAllGoods(false)}
            >
              Сховати
            </a>
          )}
        </>
      )}
    </>
  )
}

type TProps = {
  name: string
  qty: number
  index: number
}
const ParcelProdsItem = ({name, qty, index}: TProps) => {
  return (
    <span key={index} className='text-muted fw-semobold text-muted d-block fs-7'>
      {!name ? '' : `${name ? `${name}` : '-'} x ${qty ? `${qty}` : '-'}`}
    </span>
  )
}
