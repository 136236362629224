/* eslint-disable jsx-a11y/anchor-is-valid */
import {ListViewProvider, useListView} from '../core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from '../core/QueryRequestProvider'
import {QueryResponseProvider} from '../core/QueryResponseProvider'
import {
  getSavedFilter,
  getSavedPagination,
  initialQueryState,
  KTCard,
  LS_KEYS,
  QUERIES,
  TABLE_LIST_FILTER_KEY,
} from '../../../../_metronic/helpers'
import {ParcelsTable} from './table/ParcelsTable'
import {ParcelsListHeader} from './components/header/ParcelsListHeader'
import {useEffect} from 'react'
import {useParams} from 'react-router-dom'

const ParcelsList = () => {
  const {updateState} = useQueryRequest()
  const {type} = useParams()
  const {setIsArchive} = useListView()

  useEffect(() => {
    const retrievedData = getSavedPagination()
    const savedFilter = getSavedFilter(QUERIES.PARCELS_LIST)

    let state = {
      ...initialQueryState,
      filter: {...savedFilter},
      itemsPerPage: retrievedData,
    }

    if (setIsArchive) {
      if (type === 'archive') {
        setIsArchive(true)
        state = {
          ...initialQueryState,
          filter: {...savedFilter, archive: 1},
        }
      } else if (type === 'sea') {
        setIsArchive(false)
        state = {
          ...initialQueryState,
          filter: {...savedFilter, archive: 0, type_delive: 1},
        }
      } else if (type === 'air') {
        setIsArchive(false)
        state = {
          ...initialQueryState,
          filter: {...savedFilter, archive: 0, type_delive: 0},
        }
      }
    }

    updateState(state)
    window.localStorage.setItem(
      TABLE_LIST_FILTER_KEY(QUERIES.PARCELS_LIST),
      JSON.stringify(savedFilter)
    )
  }, [])
  return (
    <>
      <KTCard>
        <ParcelsListHeader />
        <ParcelsTable />
      </KTCard>
    </>
  )
}

const ParcelsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ParcelsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ParcelsListWrapper}
