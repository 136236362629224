import {FormattedMessage, useIntl} from 'react-intl'
import {Parcel} from '../../core/_models/_parcels-models'
import {ParcelTracksItem} from './ParcelTracksItem'

interface Props {
  parcel: Parcel
}

export function ParcelTracks({parcel}: Props) {
  const {tracks} = parcel
  const intl = useIntl()
  return (
    <div className='card mb-5 mb-xl-10 pt-10'>
      <div className='card-body py-9 px-20 cardBody'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                {/* <th className='min-w-190px'>
                  {intl.formatMessage({id: 'PARCELS.P_PAGE.TRACKS_SECTION.TRACK_COLUMN'})}
                </th>
                <th className='min-w-120px'>
                  {intl.formatMessage({id: 'PARCELS.P_PAGE.STATUSES.STATUS_COLUMN'})}
                </th> */}
                <th className='min-w-100px '>
                  <FormattedMessage id='PARCELS.P_PAGE.TRACKS_SECTION.TRACK_COLUMN' />
                </th>
              </tr>
            </thead>

            <tbody>
              {tracks?.map((track) => (
                <ParcelTracksItem key={track.id} track={track} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
