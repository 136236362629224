import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../_metronic/helpers'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {ParcelsQueryResponse, Parcel} from './_models/_parcels-models'

const API_URL = process.env.REACT_APP_API_PATH
const PARCELS_URL = `/parcel`
const BASE_URL = `${API_URL}${PARCELS_URL}`

const getParcels = (query: string): Promise<ParcelsQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<ParcelsQueryResponse>) => d.data)
}

const getParcelById = (id: ID): Promise<Parcel | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/getById`, {
      params: {id},
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((response: AxiosResponse<Parcel>) => response.data)
}

const archiveParcel = async (id: ID, status: number) => {
  const res = await axios.get(`${BASE_URL}/archive?id=${id}&state=${status}`)
  return res
}

export {getParcels, getParcelById, archiveParcel}
