import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {
  IReceiverShipmentAddressToSend,
  IShipmentAddress,
} from '../../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {getConfig} from '../../../../../../_metronic/i18n/Metronici18n'
import {
  IRecShipmentAddress,
  IRecShipmentAddressToSend,
  NewReceiverFullForm,
  Receiver,
  ReceiversQueryResponse,
} from './_models'

const APP_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}`
const API_URL = process.env.REACT_APP_API_PATH
const GEN_URL = `/receivers`
const ADDRESS_URL = `/receiver_addresses`

const GEN_BASE_URL = `${APP_URL}${API_URL}${GEN_URL}`
const ADDRESS_BASE_URL = `${APP_URL}${API_URL}${ADDRESS_URL}`

const getReceivers = (query: string): Promise<ReceiversQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${GEN_BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<ReceiversQueryResponse>) => d.data)
}

const getReceiverAddresses = async (id: number) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.get<IRecShipmentAddress[]>(`${ADDRESS_BASE_URL}?receiver=${id}`, {
    headers: {
      'Accept-Language': selectedLang,
    },
  })
  // console.log('receiver addresses', data)

  const reformedData = data.map(
    ({main, building, city, courierDelivery, flat, id, street, warehouse}) => {
      return {
        id,
        post: !courierDelivery,
        region: city.region,
        city: {id: city.id, name: city.name},
        address: {
          houseNumber: building,
          houseLetter: null,
          street: {id: street, name: street},
          apartment: flat,
          office: null,
        },
        mainOffice: warehouse
          ? {
              number: null,
              id: warehouse.id,
              name: warehouse.name,
              city: {id: city.id, name: city.name},
            }
          : null,
        reserveOffice: null,
        mainAddress: main,
      }
    }
  )

  return reformedData
}

const addReceiverAddress = async (data: IRecShipmentAddressToSend) => {
  console.log('chd', data)

  const {data: response} = await axios.post<IRecShipmentAddressToSend>(`${ADDRESS_BASE_URL}`, data)
  // console.log('receiver addAddress', response)

  return response
}

const editReceiverAddress = async (data: Partial<IRecShipmentAddressToSend>) => {
  console.log('chd', data)

  const {data: response} = await axios.put<Partial<IRecShipmentAddressToSend>>(
    `${ADDRESS_BASE_URL}/${data.id}`,
    data
  )
  // console.log('receiver addAddress', response)

  return response
}

const getReceiverById = (id: ID): Promise<Receiver | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${GEN_BASE_URL}/${id}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((response: AxiosResponse<Receiver>) => response.data)
}

const createReceiver = (
  receiver: NewReceiverFullForm | Partial<Receiver>
): Promise<Receiver | undefined | any> => {
  return axios
    .post(`${GEN_BASE_URL}`, receiver)
    .then((response: AxiosResponse<Response<Receiver>>) => response.data)
  // .catch((error) => {
  //   let errorMessage = ''
  //   if (error.response) {
  //     errorMessage = error.response.data.message
  //     console.log('Server error:', errorMessage)
  //   } else {
  //     errorMessage = error.message
  //     console.log('Request error:', error.message)
  //   }
  //   return errorMessage
  // })
}

const updateReceiver = async (receiver: Partial<Receiver>, id: ID) => {
  const {data} = await axios.put(`${GEN_BASE_URL}/${id}`, receiver)

  return data
}

const deleteReceiver = async (id: ID) => {
  const response = await axios.get(`${GEN_BASE_URL}/remove?id=${id}`)
  // console.log('res delete rec', response)
}

const uploadFile = async (name: string, file: File) => {
  let formData = new FormData()
  formData.append(name, file as any)

  try {
    const {data} = await axios.post(`${GEN_BASE_URL}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    // console.log('receivers addPassportScan response', data)
    return data
  } catch (err) {
    console.log(err)
  }
}

const editReceiverOverview = async (data: any) => {
  const {data: response} = await axios.post<any>(`${GEN_BASE_URL}/update`, data)
  return response
}

export {
  getReceivers,
  createReceiver,
  updateReceiver,
  deleteReceiver,
  getReceiverById,
  uploadFile,
  editReceiverOverview,
  getReceiverAddresses,
  addReceiverAddress,
  editReceiverAddress,
}
