/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

import {ITtn} from '../../../core/_models'

type Props = {
  ttn: ITtn
}

const TtnNumberCell: FC<Props> = ({ttn}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex justify-content-start flex-column'>
        <span className='text-dark fw-bold fs-6'>{ttn.code}</span>
      </div>
    </div>
  )
}

export {TtnNumberCell}
