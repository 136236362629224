import React, {useState} from 'react'
import {establishFileEndpoint} from '../../../../_metronic/helpers/custom/funcs/establishOwnerFromDomain'
import {ImageLightbox} from './ImageLightbox'

const BASE_URL = `${process.env.REACT_APP_URL}`
const FILE_LINK_BASE = establishFileEndpoint()

interface Props {
  link: string
  alt: string
  base: boolean
  loading: boolean
  linkEndpoint: string
  dimensions?: number
  marginClass?: string
}

function ImgThumb({
  link,
  alt,
  base,
  loading,
  linkEndpoint,
  dimensions = 100,
  marginClass = '',
}: Props) {
  // console.log('link', link)
  const finalLink = base ? `${BASE_URL}${link}` : link
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }
  const imgSrc =
    !link || link === `${FILE_LINK_BASE}${linkEndpoint}/`
      ? '/media/icons/duotune/general/gen006.svg'
      : finalLink
  return (
    <>
      {loading === true ? (
        <span className='text-center h-30px w-30px spinner-border spinner-border-sm align-middle ms-2'></span>
      ) : (
        <>
          <img
            src={imgSrc}
            alt={alt}
            className={`img-thumbnail LogoThumb ${marginClass} cursor-pointer`}
            height={dimensions}
            width={dimensions}
            onClick={
              imgSrc === '/media/icons/duotune/general/gen006.svg'
                ? () => {
                    return
                  }
                : () => {
                    openLightbox()
                  }
            }
          />
          <ImageLightbox
            viewerIsOpen={viewerIsOpen}
            closeLightbox={closeLightbox}
            currentImage={currentImage}
            images={[{title: alt, src: finalLink}]}
          />
        </>
      )}
    </>
  )
}

export {ImgThumb}
