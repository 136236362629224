import {FormattedMessage} from 'react-intl'
import {Link, useLocation} from 'react-router-dom'
import {scrollToTabsStart} from '../../../../../_metronic/helpers/custom/funcs/scrollToTabsStart'
import {Parcel} from '../../core/_models/_parcels-models'

interface Props {
  parcel: Parcel
}

const ParcelHeaderNav = ({parcel}: Props) => {
  const location = useLocation()

  const {prods, id} = parcel

  return (
    <div className='d-flex overflow-auto h-55px mb-5 mb-md-0' id='item-page-header'>
      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap flex-nowrap'>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `/parcels/info/content/${id}` && 'active')
            }
            to={`/parcels/info/content/${id}`}
          >
            <FormattedMessage id='BUYOUT_PAGE.CONTENT_TAB' values={{items: `(${prods.length})`}} />
          </Link>
        </li>
        <li
          className='nav-item'
          id='parcel_tracks_header'
          data-kt-sticky-name='header'
          onClick={scrollToTabsStart}
        >
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `/parcels/info/tracks/${id}` && 'active')
            }
            to={`/parcels/info/tracks/${id}`}
          >
            <FormattedMessage id='PARCELS.P_PAGE.TRACKS_SECTION' />
          </Link>
        </li>
        <li className='nav-item' onClick={scrollToTabsStart}>
          <Link
            className={
              `nav-link text-active-primary me-6 text-center ` +
              (location.pathname === `/parcels/info/status/${id}` && 'active')
            }
            to={`/parcels/info/status/${id}`}
          >
            <FormattedMessage id='PARCELS.P_PAGE.STATUSES_HEADING' />
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default ParcelHeaderNav
