/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import {ID} from '../../../../../../../_metronic/helpers'
import {useAuth} from '../../../../../../modules/auth'
import {ITtn} from '../../../core/_models'

type Props = {
  ttn: ITtn
}

const TtnReceiverCell: FC<Props> = ({ttn}) => {
  const {receiver} = ttn
  const {currentUser} = useAuth()
  const zeroPad = (num: ID, places: number) => String(num).padStart(places, '0')
  // const forStatusColor = (stat: string) => (stat === VERIFIED ? 1 : stat === WAITING ? 2 : 0)
  const receiverIsOwner = receiver.id === 0
  return (
    <div className='d-flex align-items-center'>
      <Link to={receiverIsOwner ? '/profile' : `/receivers/edit/overview/${receiver.id}`}>
        {' '}
        <div className='d-flex flex-column'>
          <span className='fw-semobold text-black d-block fs-6 me-1'>
            {zeroPad(receiverIsOwner ? currentUser?.id : receiver.id, 5) || '-'}
          </span>{' '}
          <span className='fw-normal text-black d-block fs-7'>
            {receiver.lastName} {receiver.firstName}{' '}
            {receiver.patronymic ? receiver.patronymic : ''}
          </span>
        </div>
      </Link>
    </div>
  )
}

export {TtnReceiverCell}
