import {Component, ErrorInfo, lazy, FC, Suspense, ReactNode} from 'react'

import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TracksPage from '../pages/tracks/TracksPage'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {WithChildren} from '../../_metronic/helpers'
import ProfilePage from '../pages/profile/vector/ProfilePage'
import BuyoutsPage from '../pages/buyouts/vector/BuyoutsPage'
import {News} from '../pages/news/News'
import ParcelsPage from '../pages/parcels/ParcelsPage'
import FulfReceiversPage from '../pages/fulfillment/receivers/FulfReceiversPage'
import WarehousesList from '../pages/warehouse/WarehousesList'
import ReceiversPage from '../pages/receivers/ReceiversPage'
import TtnsPage from '../pages/ttns/TtnsPage'

const PrivateRoutes = () => {
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const BalanceListWrapper = lazy(() => import('../pages/balance/BalanceList'))
  const GoodsPage = lazy(() => import('../pages/fulfillment/goods/GoodsPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path='receivers/*'
          element={
            <SuspensedView>
              <ReceiversPage />
            </SuspensedView>
          }
        />

        <Route
          path='news'
          element={
            <SuspensedView>
              <News />
            </SuspensedView>
          }
        />

        <Route
          path='warehouse'
          element={
            <SuspensedView>
              <WarehousesList />
            </SuspensedView>
          }
        />

        <Route
          path='buyouts/*'
          element={
            <SuspensedView>
              <BuyoutsPage />
            </SuspensedView>
          }
        />
        <Route
          path='archived-buyouts/*'
          element={
            <SuspensedView>
              <BuyoutsPage />
            </SuspensedView>
          }
        />

        <Route
          path='parcels/*'
          element={
            <SuspensedView>
              <ParcelsPage />
            </SuspensedView>
          }
        />

        <Route
          path='ttns/*'
          element={
            <SuspensedView>
              <TtnsPage />
            </SuspensedView>
          }
        />

        <Route
          path='fulfillment/goods/*'
          element={
            <SuspensedView>
              <GoodsPage />
            </SuspensedView>
          }
        />
        <Route
          path='fulfillment/receivers/*'
          element={
            <SuspensedView>
              <FulfReceiversPage />
            </SuspensedView>
          }
        />

        <Route
          path='tracks/*'
          element={
            <SuspensedView>
              <TracksPage />{' '}
            </SuspensedView>
          }
        />

        {/* 
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} /> */}
        {/* Lazy Modules */}

        <Route
          path='balance'
          element={
            <SuspensedView>
              <BalanceListWrapper />
            </SuspensedView>
          }
        />
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
            path='apps/user-management/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          /> */}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
