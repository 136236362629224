import {ProfileNumberItem} from './ProfileNumberItem'
import {FormattedMessage} from 'react-intl'
import {statusColor} from '../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useFormikContext} from 'formik'
import {useLayout} from '../../../../../../_metronic/layout/core'
import {ICrauzerUser} from '../../../../../../_metronic/helpers/custom/tsHelpers/crauzerUser'

type TProps = {
  user: ICrauzerUser | undefined
}

export function ProfilePhoneNumber({user}: TProps) {
  const {websiteData} = useLayout()

  const {isSubmitting} = useFormikContext<Partial<ICrauzerUser>>()

  return (
    <>
      <div className='row mb-6'>
        <label className='col-lg-4 col-form-label required fw-bold fs-6'>
          <FormattedMessage id='PROFILE.PAGE.CONTACTS.NUMBER' />
        </label>
        <div className='col-lg-8 w-auto px-0'>
          <div className='d-flex'>
            <ProfileNumberItem user={user} />
            {user?.phoneNumber &&
              (user?.phoneNumberConfirmed === false ||
                (!user.phoneNumberConfirmed && user.phoneNumberTelegramConfirmed === false)) && (
                <OverlayTrigger
                  placement='left'
                  overlay={<Tooltip>Підтвердити через Телеграм-бот.</Tooltip>}
                >
                  <a
                    target={'_blank'}
                    href={`https://t.me/${websiteData.telegramBotName}`}
                    rel='noreferrer noopener'
                    // type='button'
                    className='btn btn-icon btn-light btn-lg ms-2'
                    // onClick={confirmThroughTelegram}
                    // disabled={isSubmitting}
                  >
                    <KTSVG path='/media/svg/brand-logos/telegram-app.svg' className='svg-icon-1' />
                  </a>
                </OverlayTrigger>
              )}
          </div>
        </div>
      </div>
      {user?.phoneNumber && user?.phoneNumberTelegramConfirmed === false && (
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
          <div className='col-lg-8 p-0 w-50'>
            <span
              className={`p-1 fs-7 fw-semobold  StatusBar__status cursor-pointer ${statusColor(
                false
              )}`}
            >
              Номер не підтверджено
            </span>
          </div>
        </div>
      )}
    </>
  )
}
