export const emailRegex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
export const passwordRegex = /^[A-Za-z0-9+=\-,.!?/*&^@#$%:;_\\()\s|]+$/
export const phoneRegex = /^\+38 \([0-9]{3}\) [0-9]{3} [0-9]{2} [0-9]{2}$/

export const cyrillicOnly = /^[аА-яЯґҐєЄіІїЇ '-]+$/
export const latinAndNumbersOnly = /^[A-Za-z0-9\s-_]+$/
export const noOddSymbols = /[^A-Za-z0-9\s-_]/
export const lettersOnly = /^[aA-zZаА-яЯ\s]+$/

export const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-zA-Z]{2,})(:[0-9]+)?\/?$/
