import clsx from 'clsx'
import {FC, PropsWithChildren, useMemo} from 'react'
import {HeaderProps} from 'react-table'
import {ModalNotification} from '../../../../../modules/custom/modals/ModalNotification'
import {initialQueryState} from '../../../../../../_metronic/helpers'
import {DateRow, Parcel} from '../../../core/_models/_parcels-models'
import {useQueryRequest} from '../../../core/QueryRequestProvider'
import {FormattedMessage} from 'react-intl'

type Props = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<Parcel | DateRow>>
  notePopup?: HTMLElement
}
const ParcelCustomHeader: FC<Props> = ({className, title, tableProps, notePopup}) => {
  const id = tableProps.column.id
  const {state, updateState} = useQueryRequest()

  const isSelectedForSorting = useMemo(() => {
    return state.sort && state.sort === id
  }, [state, id])
  const order: 'asc' | 'desc' | undefined = useMemo(() => state.order, [state])

  const sortColumn = () => {
    // avoid sorting for these columns
    if (id === 'actions' || id === 'selection') {
      return
    }

    if (!isSelectedForSorting) {
      // enable sort asc
      updateState({order: 'asc', ...initialQueryState})
      return
    }

    if (isSelectedForSorting && order !== undefined) {
      if (order === 'asc') {
        // enable sort desc
        updateState({order: 'desc', ...initialQueryState})
        return
      }

      // disable sort
      updateState({sort: undefined, order: undefined, ...initialQueryState})
    }
  }

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className,
        isSelectedForSorting && order !== undefined && `table-sort-${order}`
      )}
      style={{cursor: 'pointer'}}
      onClick={sortColumn}
    >
      {notePopup && (
        <div className='d-flex'>
          <span className='me-1'>
            <FormattedMessage id={title} />
          </span>
          <ModalNotification
            svg='/media/icons/duotune/general/gen046.svg'
            addClass='ModalNotification__position'
            children={notePopup}
          />
        </div>
      )}
      {!notePopup && title && <FormattedMessage id={title} />}
    </th>
  )
}

export {ParcelCustomHeader}
