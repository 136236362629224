import {useEffect, useRef, useState} from 'react'
import {Form, Formik, FormikValues} from 'formik'
import {NewReceiverFullForm} from '../core/_models'
import {useListView} from '../core/ListViewProvider'
import {StepperComponent} from '../../../../../../_metronic/assets/ts/components'
import {addReceiverAddress, createReceiver, uploadFile} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'
import {addReceiverSchema} from '../core/yup/receiver-page'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {ISetFieldValue} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {handleFileUpload} from '../../../../../../_metronic/helpers/custom/funcs/handleFileUpload'
import {useMutation} from 'react-query'
import {CreateError} from '../../../../../modules/custom/responseHandlers/CreateError'
import {PatronymicCheckbox} from '../../elements/PatronymicCheckbox'
import {ReceiverContact} from '../../elements/ReceiverContact'
import {FormattedMessage, useIntl} from 'react-intl'
import {ReceiverType} from '../../elements/ReceiverType'
import {FileInput} from '../../../../../modules/custom/form-elements/file-inputs/FileInput'
import {PostShipment} from '../../../../../modules/custom/sections/shipment/_modals/post-shipment/PostShipment'
import {AddressShipment} from '../../../../../modules/custom/sections/shipment/_modals/address-shipment/AddressShipment'
import DeliveryChoice from '../../../../../modules/custom/sections/shipment/_modals/DeliveryChoice'
import {postMainShipmentDefault} from '../../../../../../_metronic/helpers/custom/tsHelpers/locationsHelpers'
import {shapeAddressData} from '../../../../../../_metronic/helpers/custom/funcs/shapeAddressData'

const AddReceiverModalForm = () => {
  const intl = useIntl()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [loading, setLoading] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const [createResponse, setCreateResponse] = useState('')
  // @ts-ignore
  const [submittedValues, setSubmittedValues] = useState<any>(null)
  const [addressValues, setAddressValues] = useState<any>(null)

  const [submittedForm, setSubmittedForm] = useState(false)
  const [newReceiverId, setNewReceiverId] = useState('')
  const [imageIsLoading, setImageIsLoading] = useState(false)
  const [currPassport, setCurrPassport] = useState({documentUrl: '', document: ''})

  const inits: NewReceiverFullForm = {
    firstName: '',
    lastName: '',
    patronymic: '',
    premisesType: 'apartment',
    noPatronymic: false,
    document: currPassport.documentUrl,
    receiverType: [1],
    typeOfDelivery: 1,
    city: '',
    phoneNumber: '',
  }

  const filterValues = (accepted: string[], values: any) => {
    const result = {}
    //@ts-ignore
    for (const type in values) if (accepted.indexOf(type) > -1) result[type] = values[type]

    return result
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  const createReceiverMutation = useMutation(createReceiver, {
    onMutate: () => {
      setCreateResponse('loading')
    },
    onSuccess: (data) => {
      setCreateResponse('success')
      setNewReceiverId(data.id)
    },

    onError: (error: any) => {
      setCreateResponse('error')

      console.log('cr error', error.response.data.message)
    },
    onSettled: () => {
      cancel(true)
      setLoading(false)
    },
  })

  const addReceiverAddressMutation = useMutation(addReceiverAddress, {
    onSuccess: () => {
      setCreateResponse('success')
      setItemIdForUpdate(undefined)
    },
    onError: (error: TypeError) => {
      setCreateResponse('error')
      console.log('cr error', error.message)
    },
    onSettled: () => {
      cancel(true)
      setLoading(false)
    },
  })

  const saveImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: ISetFieldValue
  ) => {
    setLoading(true)
    setImageIsLoading(true)

    const linkResponse = await handleFileUpload(
      e,
      setFieldValue,
      'document',
      'document',
      uploadFile,
      false,
      'documentUrl'
    )

    if (linkResponse) {
      setCurrPassport(linkResponse)
    } else {
      // set error
      console.log('ошибка')
    }
    setImageIsLoading(false)
    setLoading(false)
  }

  const deleteImage = () => {
    setCurrPassport({documentUrl: '', document: ''})
  }

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const [currentSchema, setCurrentSchema] = useState(addReceiverSchema(intl)[0])
  const [initValues] = useState<NewReceiverFullForm>(inits)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(addReceiverSchema(intl)[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: NewReceiverFullForm, actions: FormikValues) => {
    console.log('values', values)

    if (!stepper.current) {
      return
    }

    setCurrentSchema(addReceiverSchema(intl)[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex === stepper.current.totatStepsNumber) {
      stepper.current?.goNext()
      const {
        apartment,
        office,
        premisesType,
        city,
        email,
        firstName,
        houseNumber,
        lastName,
        document,
        patronymic,
        phoneNumber,
        receiverType,
        street,
        typeOfDelivery,
        mainOfficeCity,
        mainOfficeNumber,
        reserveOfficeCity,
        reserveOfficeNumber,
        noPatronymic,
      } = values
      const toSend = {
        firstName: firstName?.trim(),
        houseNumber,
        lastName: lastName?.trim(),
        noPatronymic,
        // passport: currPassportScan.filename,
        document: currPassport.document,
        documentUrl: currPassport.documentUrl,
        patronymic: patronymic?.trim(),
        phoneNumber: '+38' + phoneNumber,
        international: receiverType.includes(1) ? true : false,
        final: receiverType.includes(2) ? true : false,
        withinCountry: receiverType.includes(3) ? true : false,
        mainOfficeCity,
        mainOfficeNumber,
        reserveOfficeCity,
        reserveOfficeNumber,
        apartment,
        office,
        premisesType,
        city,
        email,
        receiverType,
        street,
        typeOfDelivery,
        mainAddress: true,
      }

      setSubmittedValues(toSend)
      setSubmittedForm(true)

      stepper.current?.goNext()
    } else if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
      setCurrPassport({documentUrl: '', document: ''})
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  useEffect(() => {
    if (submittedForm && submittedValues) {
      setLoading(true)
      createReceiverMutation.mutate(submittedValues)

      shapeAddressData(submittedValues, false).then((addressToSend) =>
        setAddressValues(addressToSend)
      )
    }
  }, [submittedValues])

  useEffect(() => {
    if (submittedForm && addressValues && newReceiverId) {
      addReceiverAddressMutation.mutate({...addressValues, receiver: newReceiverId})
    }
  }, [addressValues, newReceiverId])

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_modal_create_receiver_stepper'
    >
      <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
        <div className='stepper-nav ps-lg-10'>
          <div className='stepper-item current CustomStepper' data-kt-stepper-element='nav'>
            <div className='stepper-wrapper'>
              {' '}
              <div className='stepper-icon w-40px h-40px'>
                <i className='stepper-check fas fa-check'></i>
                <span className='stepper-number'>1</span>
              </div>
              <div className='stepper-label'>
                <h3 className='stepper-title'>
                  <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.PROFILE' />
                </h3>

                <div className='stepper-desc'>
                  <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.PERS_DATA' />
                </div>
              </div>
            </div>
          </div>
          <div className='stepper-item CustomStepper' data-kt-stepper-element='nav'>
            <div className='stepper-line h-40px'></div>

            <div className='stepper-wrapper'>
              <div className='stepper-icon w-40px h-40px'>
                <i className='stepper-check fas fa-check'></i>
                <span className='stepper-number'>2</span>
              </div>

              <div className='stepper-label'>
                <h3 className='stepper-title'>
                  <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.DELIVERY' />
                </h3>

                <div className='stepper-desc'>
                  <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.CONTACTS_ADDRESS' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex-row-fluid py-lg-5 px-lg-0'>
        <Formik
          validationSchema={currentSchema}
          initialValues={initValues}
          onSubmit={submitStep}
          enableReinitialize={true}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({values, setFieldValue, errors}) => (
            <Form noValidate className='form'>
              {' '}
              {/* {JSON.stringify(errors)}*/}
              {/* {JSON.stringify(values)} */}
              <div className='current' data-kt-stepper-element='content'>
                <div className='w-100'>
                  <div className='fv-row mb-10 d-flex flex-md-gutter flex-wrap flex-md-nowrap'>
                    <InputTemplate
                      inputName='lastName'
                      type='text'
                      required={true}
                      title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.LAST_NAME'})}
                      addSymbol={
                        <KTSVG
                          path='/media/icons/duotune/communication/com006.svg'
                          className='svg-icon-3'
                        />
                      }
                    />

                    <InputTemplate
                      inputName='firstName'
                      type='text'
                      required={true}
                      title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.NAME'})}
                      addSymbol={
                        <KTSVG
                          path='/media/icons/duotune/communication/com006.svg'
                          className='svg-icon-3'
                        />
                      }
                    />
                    <InputTemplate
                      inputName='patronymic'
                      type='text'
                      title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.PATRONYMIC'})}
                      required={!values.noPatronymic}
                      addSymbol={
                        <KTSVG
                          path='/media/icons/duotune/communication/com006.svg'
                          className='svg-icon-3'
                        />
                      }
                    />
                  </div>
                  <div className='fv-row mb-10'>
                    <PatronymicCheckbox
                      setFieldValue={setFieldValue}
                      title={intl.formatMessage({
                        id: 'RECEIVERS.ADD_REC_MODAL.NO_PATRONYMIC_CHECKBOX',
                      })}
                      value={values.noPatronymic}
                    />
                  </div>
                  <div className='fv-row mb-10'>
                    <div className='row mb-2'>
                      <label
                        className='col-lg-2 col-form-label fw-bold fs-6 
                      required'
                      >
                        <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.REC_TYPE' />
                      </label>
                      <div className='col-lg-9'>
                        <ReceiverType values={values} />
                      </div>
                    </div>
                  </div>{' '}
                  <div className='fv-row '>
                    {' '}
                    <FileInput
                      inputId='add-receiver-form'
                      inputName='document'
                      title={intl.formatMessage({
                        id: 'RECEIVERS.ADD_REC_MODAL.FIRST_PASSPORT_PAGE',
                      })}
                      uploadedFile={{
                        base: `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}` || '',
                        link: currPassport.documentUrl,
                      }}
                      setFieldValue={setFieldValue}
                      uploadFunction={saveImage}
                      simpleDeleteFunction={deleteImage}
                      placeholder={intl.formatMessage({
                        id: 'RECEIVERS.ADD_REC_MODAL.FIRST_PASSPORT_PAGE',
                      })}
                      notification={{
                        bool: true,
                        text: intl.formatMessage({
                          id: 'RECEIVERS.ADD_REC_MODAL.FIRST_PASSPORT_PAGE.PROMPT',
                        }),
                        borderTop: false,
                      }}
                      loading={imageIsLoading}
                      required={values.receiverType.includes(1) || values.receiverType.includes(2)}
                    />
                  </div>
                </div>
              </div>
              <div data-kt-stepper-element='content'>
                <div className='w-100'>
                  <div className='fv-row d-flex flex-gutter'>
                    <InputTemplate
                      inputName='phoneNumber'
                      required={true}
                      title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.PHONE_LABEL'})}
                      type='tel'
                      fieldWidth='w-50'
                      setFieldValue={setFieldValue}
                      value={values.phoneNumber}
                      custom={
                        <ReceiverContact
                          inputName='phoneNumber'
                          setFieldValue={setFieldValue}
                          value={values.phoneNumber || ''}
                          setDisableSubmit={setDisableSubmit}
                        />
                      }
                    />

                    {/* {values.receiverType.includes(2) && ( */}
                    <InputTemplate
                      inputName='email'
                      type='mail'
                      title={intl.formatMessage({id: 'RECEIVERS.ADD_REC_MODAL.EMAIL_LABEL'})}
                      fieldWidth='w-50'
                    />
                    {/* )} */}
                  </div>
                  <div className='fv-row'>
                    <div>
                      <div className='d-flex flex-gutter flex-wrap mb-5'>
                        <DeliveryChoice values={values} />
                      </div>
                      {+values.typeOfDelivery === 1 && (
                        <AddressShipment
                          setFieldValue={setFieldValue}
                          // @ts-ignore
                          values={filterValues(
                            [
                              'apartment',
                              'city',
                              'houseLetter',
                              'houseNumber',
                              'mainAddress',
                              'office',
                              'premisesType',
                              'street',
                              'typeOfDelivery',
                            ],
                            values
                          )}
                          editing={false}
                          vector={false}
                        />
                      )}
                      {+values.typeOfDelivery === 2 && (
                        <PostShipment
                          // @ts-ignore

                          values={filterValues(
                            [
                              'city',
                              'mainAddress',
                              'mainOfficeCity',
                              'mainOfficeNumber',
                              'reserveOfficeCity',
                              'reserveOfficeNumber',
                              'typeOfDelivery',
                            ],
                            values
                          )}
                          editing={false}
                          vector={false}
                        />
                      )}

                      {+values.typeOfDelivery === 3 && (
                        <PostShipment
                          // @ts-ignore
                          values={filterValues(
                            [
                              'city',
                              'mainAddress',
                              'mainOfficeCity',
                              'mainOfficeNumber',
                              'reserveOfficeCity',
                              'reserveOfficeNumber',
                              'typeOfDelivery',
                            ],
                            values
                          )}
                          editing={false}
                          vector={false}
                          filter={[5]}
                          mainTitles={{
                            ...postMainShipmentDefault,
                            title: 'COMPONENTS.PostShipment.MAIN_NP_POSTBOX_TITLE',
                            officeTitle: 'COMPONENTS.PostShipment.NP_POSTBOX',
                            choosePlaceholder: 'COMPONENTS.PostShipment.NP_POSTBOX_PLACEHOLDER',
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-stack pt-10'>
                <div className='me-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    disabled={loading}
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-4 me-1'
                    />
                    <FormattedMessage id='GENERAL.BUTTONS.BACK' />
                  </button>
                </div>

                <div>
                  <button
                    type='submit'
                    disabled={loading === true || disableSubmit === true ? true : false}
                    className='btn btn-lg btn-primary me-3'
                  >
                    <span className='indicator-label'>
                      {loading === true ? (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          <FormattedMessage id='GENERAL.BUTTONS.WAIT' />

                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      ) : (
                        <>
                          {' '}
                          {stepper.current?.currentStepIndex !==
                            stepper.current?.totatStepsNumber! && (
                            <FormattedMessage id='GENERAL.BUTTONS.NEXT' />
                          )}
                          {stepper.current?.currentStepIndex ===
                            stepper.current?.totatStepsNumber! && (
                            <FormattedMessage id='GENERAL.BUTTONS.CREATE' />
                          )}
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                          />
                        </>
                      )}
                    </span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export {AddReceiverModalForm}
