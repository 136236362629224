import {ErrorMessage, Field, Form, Formik, FormikHelpers} from 'formik'
import React, {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useMutation} from 'react-query'
import {InputTemplate} from '../../../../../app/modules/custom/form-elements/InputTemplate'
import {establishOwnerFromDomain} from '../../../../helpers/custom/funcs/establishOwnerFromDomain'
import {usePageData} from '../../../../layout/core'
import {AddFeedbackForm} from '../core/_models'
import {addFeedback} from '../core/_requests'
import {createFeedbackSchema} from '../core/_yup'

const FeedbackForm = () => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false)
  const {addNotification} = usePageData()

  const inits: AddFeedbackForm = {
    // issueType: 1,
    message: '',
  }

  const addFeedbackMutation = useMutation(addFeedback, {
    onMutate: () => {
      setIsLoading(true)
    },
    onSuccess: (data) => {
      addNotification({
        status: 'success',
        body: 'COMPONENTS.FeedbackForm.SUCCESS_NOTIFICATION',
      })
      const closeButton: HTMLButtonElement | null = document.querySelector('#kt_report_close')
      if (closeButton) {
        closeButton.click()
      }
    },
    onError: (error) => {
      console.log(error)
    },
    onSettled: () => {
      setIsLoading(false)
    },
  })

  const handleSubmit = (values: AddFeedbackForm, formikHelpers: FormikHelpers<AddFeedbackForm>) => {
    addFeedbackMutation.mutate({
      formData: values,
      website: establishOwnerFromDomain(),
    })
    formikHelpers.resetForm()
  }
  return (
    <Formik
      validationSchema={createFeedbackSchema(intl)}
      initialValues={inits}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({values, setFieldValue, errors, isSubmitting}) => (
        <Form>
          <div className='col'>
            {/* <div className='fv-row mb-10'>
              <p className='required fs-5 fw-bold mb-7'>
                Вкажіть проблему:
              
              </p>
              <div className='d-flex flex-column flex-col-gutter'>
                <label
                  className='btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-4'
                  data-kt-button='true'
                >
                  <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start'>
                    <Field
                      className='form-check-input'
                      type='radio'
                      name='issueType'
                      value={1}
                      checked={values.issueType === 1}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('issueType', +e.target.value)
                      }}
                    />
                  </span>

                  <span className={`ms-2 fw-bold text-gray-800 d-block`}>
                    Інтерфейс
                  </span>
                </label>{' '}
                <label
                  className='btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-4'
                  data-kt-button='true'
                >
                  <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start'>
                    <Field
                      className='form-check-input'
                      type='radio'
                      name='issueType'
                      value={2}
                      checked={values.issueType === 2}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('issueType', +e.target.value)
                      }}
                    />
                  </span>

                  <span className={`ms-2 fw-bold text-gray-800 d-block`}>
                    Функціонал
                  </span>
                </label>{' '}
                <label
                  className='btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-4'
                  data-kt-button='true'
                >
                  <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start'>
                    <Field
                      className='form-check-input'
                      type='radio'
                      name='issueType'
                      value={3}
                      checked={values.issueType === 3}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('issueType', +e.target.value)
                      }}
                    />
                  </span>

                  <span className={`ms-2 fw-bold text-gray-800 d-block`}>
                    Сервіс
                  </span>
                </label>
              </div>
              <div className='text-danger'>
                <ErrorMessage name='issueType' />
              </div>
            </div> */}
            <p className='btn btn-outline btn-outline-dashed cursor-default text-center p-4'>
              <FormattedMessage id='COMPONENTS.FeedbackForm.NOTE' />
            </p>
            <div className='fv-row mb-3'>
              <InputTemplate
                type='textarea'
                backgroundClass='h-250px'
                required={true}
                inputName='message'
                title={intl.formatMessage({
                  id: 'TRACKS.ADD_MODAL.FORM.COMMENT_TITLE',
                })}
                titleFontSize='fs-5'
                containerMarginBottom={false}
              />
            </div>
          </div>
          <div className='d-flex justify-content-around'>
            <button
              type='reset'
              className='btn  btn-light btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
              disabled={isLoading}
            >
              <FormattedMessage id='GENERAL.BUTTONS.CLEAR' />
            </button>

            <button type='submit' disabled={isLoading} className='btn btn-primary'>
              {isLoading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  <FormattedMessage id='GENERAL.BUTTONS.WAIT' />

                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                <FormattedMessage id='GENERAL.BUTTONS.SEND' />
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default FeedbackForm
