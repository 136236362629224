/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useAuth} from '../../../../app/modules/auth'
import {getUser} from '../../../../app/modules/auth/core/_requests'
import {ActionConfirmation} from '../../../../app/modules/custom/modals/ActionConfirmation'
import {crauzerProfileApi} from '../../../../app/pages/profile/_requests'
import {ID, KTSVG} from '../../../helpers'
import {pollsApi} from '../../../helpers/custom/api/pollsApi'
import {IPoll} from '../../../helpers/custom/tsHelpers/pollsHelpers'
import PollForm from '../../content/forms/poll/PollForm'
import {Portal} from '../../content/portal/Portal'

const PollDrawer = () => {
  const intl = useIntl()
  const [currPoll, setCurrPoll] = useState<(IPoll & {rescheduled: boolean}) | null>(null)
  const [confirmModalOpen, setconfirmModalOpen] = useState(false)
  const {currentUser, setCurrentUser} = useAuth()

  // funcs

  const refetchUser = async () => {
    const {data: user} = await getUser()
    const crUser = await crauzerProfileApi.getCurrentUser()
    setCurrentUser({...user, ...crUser, justLoggedIn: false})
  }
  const reschedulePoll = async () => {
    await pollsApi.reschedulePoll(currPoll?.id)

    await refetchUser()
  }

  const rejectPoll = async () => {
    try {
      if (currPoll) {
        await pollsApi.rejectPoll({
          poll: {
            id: currPoll.id,
          },
          closed: true,
        })

        await refetchUser()

        setconfirmModalOpen(false)
      }
    } catch (error) {}
  }

  // useEffects

  useEffect(() => {
    if (currentUser?.availablePolls || currentUser?.rescheduledPolls) {
      const allPolls = [...currentUser?.availablePolls, ...currentUser?.rescheduledPolls]

      if (allPolls.length > 0) {
        pollsApi.getPollById(allPolls[0]).then((res) => {
          if (res) {
            setCurrPoll({...res, rescheduled: currentUser?.rescheduledPolls.includes(allPolls[0])})
          }
        })
      }
    }
  }, [currentUser?.availablePolls, currentUser?.rescheduledPolls])

  useEffect(() => {
    if (currPoll && !currPoll.rescheduled && currentUser?.justLoggedIn) {
      const toggleButton: HTMLButtonElement | null = document.querySelector('#kt_poll_toggle')
      if (toggleButton) {
        const timeoutId = setTimeout(() => {
          toggleButton.click()
        }, 1000)

        return () => clearTimeout(timeoutId)
      }
    }
  }, [currPoll, currentUser?.justLoggedIn])

  return (
    <div
      id='kt_poll'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='poll'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'320px', 'md': '500px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_poll_toggle'
      data-kt-drawer-close='#kt_poll_close'
    >
      {/* begin::Card */}
      <div className='card shadow-none rounded-0 w-100'>
        {confirmModalOpen && (
          <Portal
            children={
              <ActionConfirmation
                actionName={intl.formatMessage({id: 'COMPONENTS.PollForm.REJECT_CONFIRMATION'})}
                setModalOpen={setconfirmModalOpen}
                handleAction={rejectPoll}
                isOpen={confirmModalOpen}
                noConfirmQuestion={reschedulePoll}
                noConfirmButtonText={intl.formatMessage({
                  id: 'COMPONENTS.PollForm.RESCHEDULE_BUTTON',
                })}
              />
            }
            className='modal-root'
          />
        )}
        {/* begin::Header */}
        <div className='card-header bg-primary' id='kt_poll_header'>
          <h5 className='card-title fw-bold text-white'>
            {' '}
            <FormattedMessage id='COMPONENTS.PollDrawer.TOGGLE' />
          </h5>

          <div className='card-toolbar'>
            <button
              className='btn menu-title text-white text-hover-secondary px-0'
              onClick={reschedulePoll}
              id='kt_poll_close'
            >
              <FormattedMessage id='COMPONENTS.PollForm.RESCHEDULE_BUTTON' />
            </button>
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              onClick={() => setconfirmModalOpen(true)}
              id='kt_poll_close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon-2 svg-icon-white'
              />
            </button>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body' id='kt_poll_body'>
          {/* begin::Content */}
          <div
            id='kt_poll_scroll'
            className='hover-scroll-overlay-y'
            data-kt-scroll='true'
            data-kt-scroll-height='auto'
            data-kt-scroll-wrappers='#kt_poll_body'
            data-kt-scroll-dependencies='#kt_poll_header'
            data-kt-scroll-offset='5px'
          >
            {currPoll && <PollForm poll={currPoll} />}
          </div>
          {/* end::Content */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Card */}
    </div>
  )
}

export {PollDrawer}
