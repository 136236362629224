import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Receiver} from '../../receivers-list/core/_models'
interface Props {
  receiver: Receiver
}

export function ReceiverHeaderInfo({receiver}: Props) {
  return (
    <div className='d-flex flex-column'>
      {' '}
      <div className='d-flex flex-column fw-bold fs-6 mb-4 pe-2'>
        <p className='d-flex align-items-center text-gray-400 text-hover-primary m-0 mb-2'>
          <KTSVG path='/media/icons/duotune/general/gen018.svg' className='svg-icon-4 me-1' />
          {receiver.region + ', ' + receiver.city + ', ' + receiver.address}
        </p>
      </div>
      <div className='card-toolbar mb-2'>
        <div className='d-flex'>
          {' '}
          {receiver.international === true ? (
            <KTSVG
              path='/media/icons/duotune/arrows/arr016.svg'
              className='svg-icon-3 me-1 svg-icon-success'
            />
          ) : (
            <KTSVG
              path='/media/icons/duotune/arrows/arr015.svg'
              className='svg-icon-3 me-1 svg-icon-danger'
            />
          )}
          <span className='d-flex align-items-center me-5 mb-2 fs-8'>
            <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.REC_TYPE.INTER' />
          </span>
        </div>
        <div className='d-flex'>
          {receiver.final === true ? (
            <KTSVG
              path='/media/icons/duotune/arrows/arr016.svg'
              className='svg-icon-3 me-1 svg-icon-success'
            />
          ) : (
            <KTSVG
              path='/media/icons/duotune/arrows/arr015.svg'
              className='svg-icon-3 me-1 svg-icon-danger'
            />
          )}
          <span className='d-flex align-items-center fs-8  me-5 mb-2'>
            <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.REC_TYPE.FINAL' />
          </span>
        </div>
        <div className='d-flex'>
          {receiver.withinCountry === true ? (
            <KTSVG
              path='/media/icons/duotune/arrows/arr016.svg'
              className='svg-icon-3 me-1 svg-icon-success'
            />
          ) : (
            <KTSVG
              path='/media/icons/duotune/arrows/arr015.svg'
              className='svg-icon-3 me-1 svg-icon-danger'
            />
          )}
          <span className='d-flex align-items-center fs-8  me-5 mb-2'>
            <FormattedMessage id='RECEIVERS.ADD_REC_MODAL.REC_TYPE.LOCAL' />
          </span>
        </div>
      </div>
    </div>
  )
}
