import {Column} from 'react-table'
import {ParcelCustomHeader} from './ParcelCustomHeader'
import {ParcelItemsInfoCell} from './cells/ParcelItemsInfoCell'
import {ParcelNumberCell} from './cells/ParcelNumberCell'
import {ParcelStatusCell} from './cells/ParcelStatusCell'
import {DateRow, Parcel} from '../../../core/_models/_parcels-models'
import {ParcelCostCell} from './cells/ParcelCostCell'
import {ParcelActionsCell} from "./cells/ParcelActionsCell";

const parcelsColumns: ReadonlyArray<Column<Parcel | DateRow>> = [
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={'PARCELS_COLUMNS_PARCEL_NUMBER'}
        className='min-w-50px'
      />
    ),
    id: 'parcelNumber',
    Cell: ({...props}) => <ParcelNumberCell parcel={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={'PARCELS_COLUMNS_CONTENT'}
        className='min-w-150px'
      />
    ),
    id: 'items',
    Cell: ({...props}) => <ParcelItemsInfoCell parcel={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={'PARCELS_COLUMNS_COST'}
        className='min-w-125px text-center'
      />
    ),
    id: 'cost',
    Cell: ({...props}) => <ParcelCostCell parcel={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={'PARCELS_COLUMNS_STATUS'}
        className='min-w-125px text-center'
      />
    ),
    id: 'status',
    Cell: ({...props}) => <ParcelStatusCell parcel={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ParcelCustomHeader
        tableProps={props}
        title={'COLUMNS_ACTIONS'}
        className='min-w-50px text-center'
      />
    ),
    id: 'actions',
    Cell: ({...props}) => <ParcelActionsCell parcel={props.data[props.row.index]} />,
  },
]

export {parcelsColumns}
