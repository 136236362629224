import * as Yup from 'yup'
import {lettersOnly} from '../../regexs'
import {object_shape_basic_select} from './generalPartials'

export const typeOfDeliverySchema = (intl: any) =>
  Yup.number().required().label(intl.messages.YUP_VALIDATION_SHIPMENT_DELIVERY_TYPE)

export const deliveryServiceSchema = (intl: any) =>
  object_shape_basic_select.required('Оберіть службу доставки')

export const houseNumberSchema = (intl: any) =>
  Yup.string()
    .typeError(intl.messages.YUP_VALIDATION_GENERAL_ONLY_NUMBERS)
    .when('typeOfDelivery', {
      is: 1,
      then: Yup.string()
        .min(1, intl.messages.YUP_VALIDATION_GENERAL_NO_ZERO_OR_NEG)
        .required(intl.messages.YUP_VALIDATION_SHIPMENT_ENTER_HOUSE_NUMBER)
        .label(intl.messages.YUP_VALIDATION_SHIPMENT_HOUSE_NUMBER),
    })

export const houseLetterSchema = (intl: any) =>
  Yup.string().when('typeOfDelivery', {
    is: 1,
    then: Yup.string()
      .max(1, intl.messages.YUP_VALIDATION_GENERAL_ONLY_ONE_SYMBOL)
      .label(intl.messages.YUP_VALIDATION_SHIPMENT_HOUSE_LETTER)
      .matches(lettersOnly, intl.messages.YUP_VALIDATION_GENERAL_LETTERS_ONLY),
  })

export const streetSchema = (intl: any) =>
  Yup.string().when('typeOfDelivery', {
    is: 1,
    then: Yup.string()
      .required(intl.messages.YUP_VALIDATION_SHIPMENT_ENTER_STREET)
      .label(intl.messages.YUP_VALIDATION_SHIPMENT_STREET),
  })

export const premisesTypeSchema = (intl: any) =>
  Yup.string().when('typeOfDelivery', {
    is: 1,
    then: Yup.string()
      .required(intl.messages.YUP_VALIDATION_SHIPMENT_ENTER_APT_TYPE)
      .label(intl.messages.YUP_VALIDATION_SHIPMENT_APT_TYPE),
  })

export const apartmentSchema = (intl: any) =>
  Yup.string().when('typeOfDelivery', {
    is: 1,
    then: Yup.string().label(intl.messages.YUP_VALIDATION_SHIPMENT_APT_NUMBER),
  })

export const officeSchema = (intl: any) =>
  Yup.string().when('typeOfDelivery', {
    is: 1,
    then: Yup.string().label(intl.messages.YUP_VALIDATION_SHIPMENT_OFF_NUMBER),
  })
export const citySchema = (intl: any) =>
  Yup.string().when('typeOfDelivery', {
    is: 1,
    then: Yup.string()
      .required(intl.messages.YUP_VALIDATION_SHIPMENT_ENTER_CITY)
      .label(intl.messages.YUP_VALIDATION_SHIPMENT_CITY),
  })
export const mainOfficeCitySchema = (intl: any) =>
  Yup.string().when('typeOfDelivery', {
    is: 2 || 3,
    then: Yup.string()
      .required(intl.messages.YUP_VALIDATION_SHIPMENT_ENTER_MAIN_WH_CITY)
      .label(intl.messages.YUP_VALIDATION_SHIPMENT_MAIN_WH_CITY),
  })
export const mainOfficeNumberSchema = (intl: any) =>
  Yup.string().when('typeOfDelivery', {
    is: 2 || 3,
    then: Yup.string()
      .required(intl.messages.YUP_VALIDATION_SHIPMENT_ENTER_MAIN_WH_NUMBER)
      .label(intl.messages.YUP_VALIDATION_SHIPMENT_MAIN_WH_NUMBER),
  })
export const reserveOfficeCitySchema = (intl: any) =>
  Yup.string().when('typeOfDelivery', {
    is: 2 || 3,
    then: Yup.string()
      .required(intl.messages.YUP_VALIDATION_SHIPMENT_ENTER_RES_WH_CITY)
      .label(intl.messages.YUP_VALIDATION_SHIPMENT_RES_WH_CITY),
  })
export const reserveOfficeNumberSchema = (intl: any) =>
  Yup.string().when('typeOfDelivery', {
    is: 2 || 3,
    then: Yup.string()
      .required(intl.messages.YUP_VALIDATION_SHIPMENT_ENTER_RES_WH_NUMBER)
      .label(intl.messages.YUP_VALIDATION_SHIPMENT_RES_WH_NUMBER),
  })
