import {useFormikContext} from 'formik'
import {FormattedMessage} from 'react-intl'
import {AddBuyoutForm} from '../core/_models/_buyout-models'

interface Props {
  handleStepperItemClick: (
    e: React.MouseEvent<HTMLSpanElement>,
    number: number,
    delType: 'avia' | 'sea'
  ) => void
  currentStepperIndex: number
}

const BuyoutStepper = ({handleStepperItemClick, currentStepperIndex}: Props) => {
  const {values} = useFormikContext<AddBuyoutForm>()

  return (
    <div className='d-flex justify-content-md-center justify-content-xl-start flex-row-auto w-100 w-xl-300px mb-3 mb-md-0'>
      <div className='stepper-nav ps-lg-10'>
        <div
          className='stepper-item current CustomStepper'
          data-kt-stepper-element='nav'
          onClick={(e: React.MouseEvent<HTMLSpanElement>) =>
            handleStepperItemClick(e, 1, values.delivery_type === 0 ? 'avia' : 'sea')
          }
        >
          <div className='stepper-wrapper'>
            <div className='stepper-icon w-40px h-40px'>
              <span className='stepper-number'>1</span>
            </div>

            <div className='stepper-label'>
              <h3 className='stepper-title'>
                <FormattedMessage id='BUYOUTS.ADD_MODAL.FORM.SETTINGS_TAB' />
              </h3>
            </div>
            <span className='arrow'>
              <span></span>
              <span></span>
            </span>
          </div>
        </div>
        <div
          className='stepper-item CustomStepper'
          data-kt-stepper-element='nav'
          onClick={(e: React.MouseEvent<HTMLSpanElement>) =>
            handleStepperItemClick(e, 2, values.delivery_type === 0 ? 'avia' : 'sea')
          }
        >
          <div className='stepper-line h-15px h-md-40px'></div>
          <div className='stepper-wrapper'>
            <div className='stepper-icon w-40px h-40px'>
              <span className='stepper-number'>2</span>
            </div>
            <div className='stepper-label'>
              <h3 className='stepper-title'>
                <FormattedMessage id='BUYOUTS.ADD_MODAL.FORM.CONTENT_TAB' />
              </h3>
            </div>
            <span className='arrow'>
              <span></span>
              <span></span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuyoutStepper
