/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedMessage, useIntl} from 'react-intl'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {Receiver} from '../../../core/_models'

type Props = {
  receiver: Receiver
}

const ReceiverTypeCell = ({receiver}: Props) => {
  const intl = useIntl()
  return (
    <>
      <div className='text-center mb-2'>
        {+receiver.international === 1 && (
          <span className={`p-1 fs-7 fw-semobold  StatusBar__status bg-light`}>
            <FormattedMessage id='RECEIVERS.STATUSES.INTERN_REC' />
          </span>
        )}
      </div>

      <div className='text-center'>
        {+receiver.receiver === 1 && (
          <span className={`p-1 fs-7 fw-semobold  bg-light StatusBar__status`}>
            <FormattedMessage id='RECEIVERS.STATUSES.FINAL_REC' />
          </span>
        )}
      </div>
    </>
  )
}

export {ReceiverTypeCell}
