/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {useMutation} from 'react-query'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {Portal} from '../../../../../../../_metronic/partials'
import {ActionConfirmation} from '../../../../../../modules/custom/modals/ActionConfirmation'
import {useQueryResponse} from '../../../core/QueryResponseProvider'
import {Receiver} from '../../../core/_models'
import {deleteReceiver} from '../../../core/_requests'

type Props = {
  receiver: Receiver
}

const ReceiverActionsCell: FC<Props> = ({receiver}) => {
  const intl = useIntl()
  const {refetch} = useQueryResponse()

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const deleteMutation = useMutation<any, Error>(
    async () => {
      return await deleteReceiver(receiver.id)
    },
    {
      onSuccess: () => {},
      onSettled: () => {
        setDeleteModalOpen(false)
        refetch()
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )

  return (
    <div className='d-flex justify-content-center flex-shrink-0'>
      {deleteModalOpen && (
        <Portal
          children={
            <ActionConfirmation
              actionName={intl.formatMessage({id: 'RECEIVERS.PAGE.LIST.DELETE_CONF'})}
              setModalOpen={setDeleteModalOpen}
              handleAction={() => deleteMutation.mutate()}
              id={receiver.id}
              isOpen={deleteModalOpen}
            />
          }
          className='modal-root'
        />
      )}
      <Link
        to={receiver.id === 0 ? '/profile/overview' : `/receivers/edit/overview/${receiver.id}`}
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
      >
        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
      </Link>

      {receiver.removable === 1 && (
        <button
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={() => setDeleteModalOpen(true)}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </button>
      )}
    </div>
  )
}

export {ReceiverActionsCell}
