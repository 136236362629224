/* eslint-disable @typescript-eslint/no-unused-expressions */
export function alterColor(color: string, value: number) {
  if (color[0] !== '#') {
    throw new Error('Invalid color format. Expected hexadecimal color.')
  }

  const hex = color.slice(1) // Remove the "#" character
  const r = parseInt(hex.substr(0, 2), 16)
  const g = parseInt(hex.substr(2, 2), 16)
  const b = parseInt(hex.substr(4, 2), 16)
  let a = 1

  // Extract the alpha value if present
  if (hex.length === 8) {
    a = parseInt(hex.substr(6, 2), 16) / 255
  }

  const newAlpha = Math.max(a - value, 0) // Reduce the alpha value

  return `rgba(${r}, ${g}, ${b}, ${newAlpha})`
}

function rgbToHsl(r: number, g: number, b: number) {
  r /= 255
  g /= 255
  b /= 255

  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  let h,
    s,
    l = (max + min) / 2

  if (max === min) {
    h = s = 0
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
      default:
        h = 0
    }

    h /= 6
  }

  return {
    h: h as number, // Type assertion to ensure h is a number
    s,
    l,
    a: 1, // Default alpha value
  }
}

function hslToRgb(h: number, s: number, l: number) {
  let r, g, b

  if (s === 0) {
    r = g = b = l
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1
      if (t > 1) t -= 1
      if (t < 1 / 6) return p + (q - p) * 6 * t
      if (t < 1 / 2) return q
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
      return p
    }

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s
    const p = 2 * l - q
    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }

  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255),
  }
}
export const pSBC = (p: any, c0: any, c1?: any, l?: any) => {
  let r: any,
    g: any,
    b: any,
    P: any,
    f: any,
    t: any,
    h: any,
    m = Math.round,
    a: any = typeof c1 == 'string'
  if (
    typeof p != 'number' ||
    p < -1 ||
    p > 1 ||
    typeof c0 != 'string' ||
    (c0[0] !== 'r' && c0[0] !== '#') ||
    (c1 && !a)
  )
    return null
    // eslint-disable-next-line
  ;(h = c0.length > 9),
    (h = a ? (c1.length > 9 ? true : c1 == 'c' ? !h : false) : h),
    (f = pSBCr(c0)),
    (P = p < 0),
    (t =
      c1 && c1 != 'c'
        ? pSBCr(c1)
        : P
        ? {r: 0, g: 0, b: 0, a: -1}
        : {r: 255, g: 255, b: 255, a: -1}),
    (p = P ? p * -1 : p),
    (P = 1 - p)
  if (!f || !t) return null
  // eslint-disable-next-line
  if (l) (r = m(P * f.r + p * t.r)), (g = m(P * f.g + p * t.g)), (b = m(P * f.b + p * t.b))
  // eslint-disable-next-line
  else
    (r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)),
      (g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)),
      (b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5))
    // eslint-disable-next-line
  ;(a = f.a),
    (t = t.a),
    (f = a >= 0 || t >= 0),
    (a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0)
  if (h)
    return (
      'rgb' + (f ? 'a(' : '(') + r + ',' + g + ',' + b + (f ? ',' + m(a * 1000) / 1000 : '') + ')'
    )
  else
    return (
      '#' +
      (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0))
        .toString(16)
        .slice(1, f ? undefined : -2)
    )
}
const pSBCr = (d: any) => {
  const i = parseInt
  let n = d.length,
    x: any = {}
  if (n > 9) {
    const [r, g, b, a] = (d = d.split(','))
    n = d.length
    if (n < 3 || n > 4)
      return null
      // eslint-disable-next-line
    ;(x.r = i(r[3] == 'a' ? r.slice(5) : r.slice(4))),
      (x.g = i(g)),
      (x.b = i(b)),
      (x.a = a ? parseFloat(a) : -1)
  } else {
    if (n === 8 || n === 6 || n < 4) return null
    if (n < 6) d = '#' + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : '')
    d = i(d.slice(1), 16)
    // eslint-disable-next-line
    if (n == 9 || n == 5)
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      (x.r = (d >> 24) & 255),
        (x.g = (d >> 16) & 255),
        (x.b = (d >> 8) & 255),
        (x.a = Math.round((d & 255) / 0.255) / 1000)
    // eslint-disable-next-line
    else (x.r = d >> 16), (x.g = (d >> 8) & 255), (x.b = d & 255), (x.a = -1)
  }
  return x
}
