import {useFormikContext} from 'formik'
import {FormattedMessage, useIntl} from 'react-intl'
import {ISettingsForm} from '../../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {InputTemplate} from '../../../../../modules/custom/form-elements/InputTemplate'

const DeliveryTypeSetting = () => {
  const intl = useIntl()
  const {setFieldValue, values} = useFormikContext<ISettingsForm>()
  return (
    <div>
      <span className='form-check-label d-flex flex-column align-items-start mb-5'>
        <span className='fw-bolder fs-5 '>
          <FormattedMessage id='PROFILE.PAGE.SETTINGS.DEFAULT_DELIVERY_TYPE' />:
        </span>
      </span>{' '}
      <div className='d-flex mw-500px '>
        <InputTemplate
          inputName='delivery_type'
          type='radio'
          title={intl.formatMessage({id: 'GENERAL.DELIVERY_TYPE_NAME.AVIA'})}
          checkboxProps={{
            checked: values.delivery_type === 0 ? true : false,
            disabled: false,
            setFieldValue: setFieldValue,
            value: 0,
            inputName: 'delivery_type',
            type: 'radio',
          }}
        />{' '}
        <InputTemplate
          inputName='delivery_type'
          type='radio'
          title={intl.formatMessage({id: 'GENERAL.DELIVERY_TYPE_NAME.SEA'})}
          checkboxProps={{
            checked: values.delivery_type === 1 ? true : false,
            disabled: false,
            setFieldValue: setFieldValue,
            value: 1,
            inputName: 'delivery_type',
            type: 'radio',
          }}
        />
      </div>
    </div>
  )
}

export default DeliveryTypeSetting
