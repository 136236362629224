export const scrollToTabsStart = () => {
  setTimeout(() => {
    const tabsHeader = document.getElementById('item-page-header')
    const headerHeight = document.getElementById('kt_app_header_container')?.offsetHeight
    if (tabsHeader && headerHeight) {
      const scrollToPosition =
        tabsHeader.getBoundingClientRect().top + window.pageYOffset - headerHeight
      window.scrollTo({top: scrollToPosition, behavior: 'smooth'})
    }
  }, 100)
}
