import {Field} from 'formik'
import {ReactNode} from 'react'
import {ISetFieldValue} from '../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'

interface Props {
  description?: string | ReactNode
  title: string
  inputName: string
  value: string | number
  formValues: any
  setFieldValue: ISetFieldValue
  disabled?: boolean
  type?: string
  checkboxIsChecked?: boolean
}

export function SettingsRadio({
  title,
  description,
  inputName,
  value,
  formValues,
  setFieldValue,
  disabled = false,
  type = 'radio',
  checkboxIsChecked,
}: Props) {
  return (
    <label className='form-check form-check-custom form-check-solid align-items-start SettingsRadio'>
      <Field
        className='form-check-input me-3'
        type={type}
        name={inputName}
        disabled={disabled}
        checked={type === 'checkbox' ? checkboxIsChecked : +formValues[inputName] === +value}
        value={+value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          console.log('e', e.target.checked)

          if (type === 'radio') {
            setFieldValue(inputName, +value)
          } else if (type === 'checkbox') {
            setFieldValue(inputName, e.target.checked)
          }
        }}
      />

      <span className='form-check-label d-flex flex-column align-items-start m-0'>
        <span className='fw-bolder fs-5 m-0'>{title}</span>
        {description && typeof description === 'string' ? (
          <span className='text-muted fs-6'>{description}</span>
        ) : (
          description
        )}
      </span>
    </label>
  )
}
