import {Formik, Form, Field, ErrorMessage, FormikValues, FormikHelpers} from 'formik'
import {profileContactsSchema} from '../../core/yup/user-profile'
import {CardFooter} from '../../../../../modules/custom/form-elements/CardFooter'
import {useState, Dispatch, SetStateAction} from 'react'
import {VectorUserModel} from '../../../../../modules/auth'
import {useIntl} from 'react-intl'
import {IProfileContacts} from '../../core/_models'
import {ProfileContactNumbers} from './ProfileContactNumbers'
import {ProfilePhoneNumber} from './ProfilePhoneNumber'
import {crauzerProfileApi} from '../../../_requests'
import {ICrauzerUser} from '../../../../../../_metronic/helpers/custom/tsHelpers/crauzerUser'
import {ProfileEmail} from './ProfileEmail'
import {TFooterNotification} from '../../../../../../_metronic/helpers/custom/tsHelpers/generalHelpers'
import {CARDFOOTER_NOTIFICATIONS} from '../../../../../../_metronic/helpers'

interface Props {
  user: VectorUserModel
  setCurrentUser: Dispatch<SetStateAction<VectorUserModel | undefined>>
  crUser: ICrauzerUser | undefined
}

export function ProfileContacts({user, setCurrentUser, crUser}: Props) {
  const intl = useIntl()

  const [footerNotification, setFooterNotification] = useState<TFooterNotification>(null)

  const initialValues = {
    phoneNumber: crUser?.phoneNumber || '',
    email: crUser?.email || '',
    contactPhoneNumbers:
      crUser?.contactPhoneNumbers && crUser?.contactPhoneNumbers.length > 0
        ? [...crUser?.contactPhoneNumbers]
        : [''],
  }
  const [loading, setLoading] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [error, setError] = useState('')

  const handleSubmit = async (
    values: IProfileContacts,
    formikHelpers: FormikHelpers<IProfileContacts>
  ) => {
    console.log('profile contacts values', values)
    setLoading(true)
    try {
      const response = await crauzerProfileApi.editProfile({
        email: values.email,
        phoneNumber: values.phoneNumber,
        contactPhoneNumbers: values.contactPhoneNumbers,
      })

      setError('')
      setCurrentUser(response)
      setFooterNotification({
        ...CARDFOOTER_NOTIFICATIONS().SUCCESSFULLY_SAVED,
        intl: 'GENERAL.TOOLTIPS.SUCCESSFUL_SAVING',
      })
      setTimeout(() => {
        setFooterNotification(null)
      }, 3000)

      console.log('resp', response)
    } catch (error: any) {
      let errMsg = intl.formatMessage({id: 'ERROR'})

      let ecount = 0

      if (error.response.data?.errors) {
        for (const [key, value] of Object.entries(error.response.data.errors)) {
          console.log('val', value)

          if (key in values) {
            // @ts-ignore

            formikHelpers.setFieldError(key, intl.formatMessage({id: value, defaultMessage: value}))
            ecount++
          } else {
            if (value && ecount === 0)
              // @ts-ignore
              errMsg = intl.formatMessage({id: value, defaultMessage: value})
          }
        }
        console.log(ecount)
      }
      if (ecount === 0) formikHelpers.setStatus(errMsg)
      // setError(intl.formatMessage({id: 'GENERAL.ERRORS.USED_EMAIL'}))
      // formikHelpers.setErrors(error.response.data.errors)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div id='kt_account_profile_details ' className='collapse show'>
        <Formik
          validationSchema={profileContactsSchema(intl)}
          initialValues={initialValues}
          // @ts-ignore
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({values, errors, setFieldValue}) => (
            <Form noValidate className='form'>
              <div className='card-body p-9'>
                {/* {JSON.stringify(values)}
                {JSON.stringify(values)} */}
                <ProfileEmail user={crUser} setFooterNotification={setFooterNotification} />
                <ProfilePhoneNumber user={crUser} />
                <ProfileContactNumbers user={crUser} />
              </div>
              <CardFooter
                loading={loading}
                disabled={loading}
                success={footerNotification?.success || false}
                notification={footerNotification}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
