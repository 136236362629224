import {FormattedMessage} from 'react-intl'

interface Props {
  //   addressType: {id: number; label: string}
  setModalOpen: (bool: boolean) => void
  itemName: string
}

export function CreateItemCard({setModalOpen, itemName}: Props) {
  return (
    <div className='col-sm-6 col-xl-4 '>
      <div className='card h-100'>
        {' '}
        <button
          className='btn bg-white btn-primary text-center align-items-center CreateCard py-13 h-100'
          onClick={() => {
            setModalOpen(true)
            // setTempType(addressType.id)
          }}
        >
          <p className='text-gray-400 text-hover-white fw-bold fs-5 mt-1 '>
            <FormattedMessage id='GENERAL.BUTTONS.ADD' values={{item: itemName}} />
          </p>
          <span className='fs-5x mb-3'>+</span>
        </button>
      </div>
    </div>
  )
}
