import axios from 'axios'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'

const BASE_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${process.env.REACT_APP_API_PATH}`

export const GET_WEBSITE_DATA = `${BASE_URL}/public/websites/`
export const GET_TRANSLATION_DATA = `${BASE_URL}/public/company/translations`

export function getWebsiteData(id: number | string) {
  const {selectedLang} = getConfig()
  return axios.get(GET_WEBSITE_DATA + id, {
    headers: {authorization: false, 'Accept-Language': selectedLang},
  })
}

export function getTranslationData(owner: number) {
  const {selectedLang} = getConfig()
  return axios.get(GET_TRANSLATION_DATA, {
    headers: {authorization: false, 'Accept-Language': selectedLang, owner},
  })
}
