import {useFormik, useFormikContext} from 'formik'
import {useEffect, useRef, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useMutation} from 'react-query'
import {useNavigate} from 'react-router-dom'
import {useLayout} from '../../../../_metronic/layout/core'
import {confirmViaTelegram} from '../core/_requests'

type TProps = {
  values: any
}

const RegistrationConfirmOptions = ({values}: TProps) => {
  const navigate = useNavigate()
  const {websiteData} = useLayout()
  const intervalRef = useRef<NodeJS.Timer | null>(null)
  const [confToken, setConfToken] = useState<string | null>(null)
  // @ts-ignore
  const mutation = useMutation(() => confirmViaTelegram(confToken), {
    onSuccess: (data: {data: {token: string}}) => {
      console.log('td', data)
      if (data.data.token) {
        clearInterval(intervalRef.current!)
        return navigate(`/auth/confirm/${data.data.token}`, {state: {telegram: true}})
      }
      // else {
      //   triggerMutation()
      // }
    },
    onSettled: () => {},
    onError: (e) => {
      console.log('t reg e', e)
    },
  })

  useEffect(() => {
    return () => {
      setConfToken(null)
    }
  }, [])

  const triggerMutation = () => {
    if (values.confToken && confToken) {
      mutation.mutate(values.confToken)
    }
  }

  useEffect(() => {
    if (confToken) {
      intervalRef.current = setInterval(() => {
        triggerMutation()
      }, 2000)

      return () => {
        clearInterval(intervalRef.current!)
      }
    } else {
      return () => {
        clearInterval(intervalRef.current!)
      }
    }
  }, [confToken])

  useEffect(() => {
    if (values.confToken) setConfToken(values.confToken)
  }, [values.confToken])

  return (
    <div className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
      <div className='mb-10 text-center'>
        <div className='fw-bold fs-4'>
          <div className='mb-5'>
            <p className='fs-5 fw-bold DeleteConfirmation__confirm m-0'>
              <FormattedMessage id='AUTH.REGISTER_CONFIRM.QUESTION' />
            </p>
          </div>
          <div className='d-flex align-items-center flex-column px-10'>
            <button
              className='btn btn-sm btn-primary btn-active-dark-primary mb-5 w-75'
              onClick={() => {
                return navigate('/auth/registration/done', {state: {email: values.email}})
              }}
            >
              <FormattedMessage id='AUTH.REGISTER_CONFIRM.EMAIL' />
            </button>

            <a
              target={'_blank'}
              href={
                `https://t.me/${websiteData.telegramBotName}` || 'https://t.me/LogisticLocalBot'
              }
              className='btn btn-sm btn-primary w-75'
              rel='noreferrer noopener'
            >
              <FormattedMessage id='AUTH.REGISTER_CONFIRM.TELEGRAM' />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegistrationConfirmOptions
