/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedMessage} from 'react-intl'
import {statusColor} from '../../../../../../../_metronic/helpers/custom/funcs/statusColor'
import {Receiver} from '../../../core/_models'

type Props = {
  receiver: Receiver
}

const ReceiverStatusCell = ({receiver}: Props) => {
  return (
    <div className='d-flex align-items-center justify-content-center'>
      <span className={`p-1 fs-7 fw-semobold  StatusBar__status ${statusColor(+receiver.active)}`}>
        {+receiver.active === 1 && <FormattedMessage id='GENERAL.STATUSES.ACTIVE' />}
        {+receiver.active === 0 && <FormattedMessage id='GENERAL.STATUSES.BLOCKED' />}
      </span>
    </div>
  )
}

export {ReceiverStatusCell}
