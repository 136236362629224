import {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {
  FULF_RECEIVER_VERIFICATION_STATUS,
  initialQueryState,
  KTSVG,
  QUERIES,
  TABLE_LIST_FILTER_KEY,
} from '../../../../../../../_metronic/helpers'
import {Dropdown1} from '../../../../../../../_metronic/partials'
import {CheckboxSwitcherNoFormik} from '../../../../../../modules/custom/form-elements/checkboxes/CheckboxSwitcherNoFormik'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'

const {VERIFIED, CHECKED, WAITING} = FULF_RECEIVER_VERIFICATION_STATUS

const ReceiversListFilter = () => {
  const {updateState} = useQueryRequest()
  const intl = useIntl()
  const {isLoading} = useQueryResponse()
  const [verifStatus, setVerifStatus] = useState<string | undefined>(undefined)
  const [final, setFinal] = useState<1 | 0 | null>(1)
  const [international, setInternational] = useState<boolean | null>(null)
  const [withinCountry, setWithinCountry] = useState<boolean | null>(null)
  const [enabled, setEnabled] = useState<boolean | null>(null)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({...initialQueryState, filter: undefined})
    window.localStorage.setItem(TABLE_LIST_FILTER_KEY(QUERIES.FULF_RECEIVERS_LIST), '')
    setVerifStatus(undefined)
    setFinal(null)
    setInternational(null)
    setWithinCountry(null)
    setEnabled(null)
  }

  const filterData = () => {
    const filter = {verifStatus, final, international, withinCountry, enabled}

    updateState({
      filter,
      ...initialQueryState,
    })
    const data = JSON.stringify(filter)
    window.localStorage.setItem(TABLE_LIST_FILTER_KEY(QUERIES.FULF_RECEIVERS_LIST), data)
  }

  return (
    <>
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-sm btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        <FormattedMessage id='GENERAL.TABLE_FILTER.HEADER' />
      </button>
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>
            {' '}
            <FormattedMessage id='GENERAL.TABLE_FILTER.HEADER' />
          </div>
        </div>
        <div className='separator border-gray-200'></div>

        <div className='px-7 py-3' data-kt-user-table-filter='form'>
          <div className='mb-10'>
            <label className='form-label fw-bold'>Статус верифікації:</label>

            <div>
              <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                onChange={(e) => setVerifStatus(e.target.value)}
                value={verifStatus}
              >
                <option value={undefined}>Всі</option>
                <option value={VERIFIED}>
                  <FormattedMessage id='GENERAL.STATUSES.VERIFIED' />
                </option>
                <option value={CHECKED}>
                  <FormattedMessage id='GENERAL.STATUSES.CHECKED' />
                </option>
                <option value={WAITING}>
                  <FormattedMessage id='GENERAL.STATUSES.VERIF_WAITING' />
                </option>
              </select>
            </div>
          </div>

          <div className='mb-10'>
            <label className='form-label fw-bold'>Тип отримувача:</label>

            <div className='d-flex'>
              <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  onChange={(e) => setWithinCountry(e.target.checked)}
                />
                <span className='form-check-label'>По країні</span>
              </label>
              <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value='2'
                  defaultChecked={true}
                  onChange={(e) => setInternational(e.target.checked)}
                />
                <span className='form-check-label'>Міжнародний</span>
              </label>

              <label className='form-check form-check-sm form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value='3'
                  defaultChecked={true}
                  onChange={(e) => setFinal(e.target.checked === true ? 1 : 0)}
                />
                <span className='form-check-label'>Кінцевий</span>
              </label>
            </div>
          </div>

          <div className='mb-10'>
            <label className='form-label fw-bold'>Статус:</label>
            <CheckboxSwitcherNoFormik
              inputName='enabledReceivers'
              firstOption={intl.formatMessage({id: 'GENERAL.STATUSES.BLOCKED_PL'})}
              secondOption={intl.formatMessage({id: 'GENERAL.STATUSES.ACTIVE_PL'})}
              checked={enabled}
              bgColor={{first: 'var(--kt-success)', second: 'var(--kt-danger)'}}
              handleChange={setEnabled}
            />
          </div>

          {/* <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Блоки:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => setRole(e.target.value)}
              value={role}
            >
              <option value=''></option>
              <option value='VERIFIED'>Общее</option>
              <option value='CHECKED'>Контакты</option>
              <option value='WAITING'>Адрес</option>
              <option value='activity'>Активность</option>
              <option value='finances'>Финансы</option>
              <option value='reports'>Отчеты</option>
            </select>
          </div> */}

          {/* <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Last login:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='two-step'
              data-hide-search='true'
              onChange={(e) => setLastLogin(e.target.value)}
              value={lastLogin}
            >
              <option value=''></option>
              <option value='Yesterday'>Yesterday</option>
              <option value='20 mins ago'>20 mins ago</option>
              <option value='5 hours ago'>5 hours ago</option>
              <option value='2 days ago'>2 days ago</option>
            </select>
          </div> */}

          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              <FormattedMessage id='GENERAL.TABLE_FILTER.RESET' />
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              <FormattedMessage id='GENERAL.TABLE_FILTER.APPLY' />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export {ReceiversListFilter}
