/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {KTSVG} from '../../../helpers'
import FeedbackForm from '../../content/forms/feedback/FeedbackForm'

const ReportDrawer = () => {
  return (
    <div
      id='kt_report'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='report'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'350px', 'md': '350px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_report_toggle'
      data-kt-drawer-close='#kt_report_close'
    >
      {/* begin::Card */}
      <div className='card shadow-none rounded-0 w-100'>
        {/* begin::Header */}
        <div className='card-header bg-primary' id='kt_report_header'>
          <h5 className='card-title fw-bold text-white'>
            <FormattedMessage id='COMPONENTS.ReportDrawer.TOGGLE' />
          </h5>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              id='kt_report_close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon-2 svg-icon-white'
              />
            </button>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body' id='kt_report_body'>
          {/* begin::Content */}
          <div
            id='kt_report_scroll'
            className='hover-scroll-overlay-y'
            data-kt-scroll='true'
            data-kt-scroll-height='auto'
            data-kt-scroll-wrappers='#kt_report_body'
            data-kt-scroll-dependencies='#kt_report_header'
            data-kt-scroll-offset='5px'
          >
            <FeedbackForm />
          </div>
          {/* end::Content */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Card */}
    </div>
  )
}

export {ReportDrawer}
