/* eslint-disable jsx-a11y/anchor-is-valid */
import {FormattedDate, FormattedMessage, FormattedTime, useIntl} from 'react-intl'
import {IParcelStatusLog} from '../../core/_models/_parcels-models'

interface Props {
  log: IParcelStatusLog
}

function ParcelStatusItem({log}: Props) {
  const intl = useIntl()

  return (
    <tr>
      <td>
        <div className='d-flex flex-column'>
          <span className='text-dark fw-bold fs-6'>
            <FormattedDate year='numeric' month='numeric' day='numeric' value={log.date_time} />
          </span>
          <span className='text-muted fw-normal fs-6'>
            <FormattedTime value={log.date_time} hour='numeric' minute='numeric' second='numeric' />
          </span>
        </div>
      </td>

      <td>
        <span className='text-dark text-hover-primary d-block fs-6'>{log.status}</span>
      </td>
      <td>
        <span className='text-dark text-hover-primary d-block fs-6'>
          {log.admin_id ? (
            log.admin_role ? (
              log.admin_role
            ) : (
              <FormattedMessage id='TRACK_PAGE.STATUSES.ADMIN' />
            )
          ) : (
            <FormattedMessage id='TRACK_PAGE.STATUSES.CLIENT' />
          )}
        </span>
      </td>
    </tr>
  )
}

export {ParcelStatusItem}
