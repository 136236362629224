import {Route, Routes, Outlet, Navigate, useLocation} from 'react-router-dom'
import {ReceiversListWrapper} from './receivers-list/ReceiversList'
import ReceiverPage from './receiver-page/ReceiverPage'

const FulfReceiversPage = () => {
  const location = useLocation()

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <ReceiversListWrapper />
            </>
          }
        />

        <Route
          path={`edit/*`}
          element={
            <ReceiverPage
              id={+location.pathname.split('/')[location.pathname.split('/').length - 1]}
            />
          }
        />
      </Route>
      <Route index element={<Navigate to='/fulfillment/receivers/list' />} />
    </Routes>
  )
}

export default FulfReceiversPage
