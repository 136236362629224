import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {
  IReceiverShipmentAddressToSend,
  IShipmentAddress,
} from '../../../../../_metronic/helpers/custom/tsHelpers/profileHelpers'
import {getConfig} from '../../../../../_metronic/i18n/Metronici18n'
import {NewReceiverFullForm, Receiver, ReceiversQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_PATH
const RECEIVERS_URL = `/receivers`
const BASE_URL = `${API_URL}${RECEIVERS_URL}`

const getReceivers = (query: string): Promise<ReceiversQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<ReceiversQueryResponse>) => d.data)
}

const getReceiverAddresses = async (id: string | number) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.post<IShipmentAddress[]>(`${BASE_URL}/addresses`, {
    receiver_id: id,
    headers: {
      'Accept-Language': selectedLang,
    },
  })
  // console.log('receiver addresses', data)

  return data
}

const addReceiverAddress = async (data: IReceiverShipmentAddressToSend | IShipmentAddress) => {
  const {data: response} = await axios.post<any>(`${BASE_URL}/address_update`, data)
  // console.log('receiver addAddress', response)

  return response
}

const getReceiverById = (id: ID): Promise<Receiver | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/getById`, {
      params: {id},
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((response: AxiosResponse<Receiver>) => response.data)
}

const createReceiver = (
  receiver: NewReceiverFullForm | Partial<Receiver>
): Promise<Receiver | undefined | any> => {
  return axios
    .post(`${BASE_URL}/addEdit`, receiver)
    .then((response: AxiosResponse<Response<Receiver>>) => response.data)
    .then((response: Response<Receiver>) => response.data)
}



const deleteReceiver = async (id: ID) => {
  const response = await axios.get(`${BASE_URL}/remove?id=${id}`)
  // console.log('res delete rec', response)
}

const addPassportScan = async (name: string, file: File) => {
  let formData = new FormData()
  formData.append(name, file as any)

  try {
    const {data} = await axios.post(`${BASE_URL}/upload_passport`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    // console.log('receivers addPassportScan response', data)
    return data
  } catch (err) {
    console.log(err)
  }
}

const editReceiverOverview = async (data: any) => {
  const {data: response} = await axios.post<any>(`${BASE_URL}/update`, data)
  return response
}

export {
  getReceivers,
  createReceiver,
  deleteReceiver,
  getReceiverById,
  addPassportScan,
  editReceiverOverview,
  getReceiverAddresses,
  addReceiverAddress,
}
