import {useFormikContext} from 'formik'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {InputTemplate} from '../../../../modules/custom/form-elements/InputTemplate'
import {Notification} from '../../../../modules/custom/Notification'
import {InspectionConditions} from '../../../buyouts/vector/_modals/tabs/goods/InspectionConditions'
import {useListView} from '../../core/ListViewProvider'
import {AddTrackForm} from '../../core/_models/_tracks-models'

interface Props {
  values: AddTrackForm
}

export function TrackInspectionTab({values}: Props) {
  const {setFieldValue} = useFormikContext()
  const intl = useIntl()
  const {itemIdForUpdate} = useListView()
  const [showInvoicePrompt, setShowInvoicePrompt] = useState(false)
  const noInvoiceForDisabling =
    values?.invoiceLinks && values?.invoiceLinks[0] === '' && !values.invoice_file
  const disabledInspectionCheckbox = !!itemIdForUpdate || !!noInvoiceForDisabling

  // const sendErrors = () => {
  //   const obj = {
  //     formikContext,
  //     window,
  //     currentUser,
  //   }
  //   console.log('formikContext', formikContext)
  //   console.log('rec', values.receiver, receiversListData)

  //   // JSON.stringify(obj)
  // }

  // useEffect(() => {
  //   console.log('js', JSON.stringify(formikContext))

  //   const sendStuffToServer = () => {
  //     sendErrors()
  //     console.log('w', window)
  //   }

  //   window.onerror = sendStuffToServer

  //   return () => {
  //     window.onerror = null
  //   }
  // }, [])
  useEffect(() => {
    if (showInvoicePrompt) {
      const timer = setTimeout(() => {
        setShowInvoicePrompt(false)
      }, 4000)

      return () => clearTimeout(timer)
    }
  }, [showInvoicePrompt])

  return (
    <div className='w-100'>
      <div
        className={`fv-row mt-2 ${values.orderInspection === true ? 'mb-5' : ''}`}
        onClick={() => {
          if (noInvoiceForDisabling) {
            setShowInvoicePrompt(true)
          }
        }}
      >
        <InputTemplate
          inputName='orderInspection'
          title={intl.formatMessage({
            id: 'TRACKS.ADD_MODAL.FORM.INSPECTION_TITLE',
          })}
          type='checkbox'
          checkboxProps={{
            checked: values.orderInspection,
            disabled: disabledInspectionCheckbox,
            setFieldValue: setFieldValue,
            value: 1,
            inputName: 'orderInspection',
            type: 'checkbox',
          }}
          containerMarginBottom={false}
        />
        {values.orderInspection === true && <InspectionConditions />}{' '}
      </div>
      {values.orderInspection === true && (
        <div className='fv-row mb-5'>
          <InputTemplate
            type='textarea'
            inputName='inspectionDescription'
            title={intl.formatMessage({
              id: 'TRACKS.ADD_MODAL.FORM.INSPECTION_DESC_TITLE',
            })}
            required={true}
            disabled={!!itemIdForUpdate}
          />
        </div>
      )}
      {values?.orderInspection && (
        <div className='fv-row mb-3'>
          <Notification
            noteText={intl.formatMessage({
              id: 'TRACKS.ADD_MODAL.FORM.INSPECTION_PROMPT',
            })}
          />
        </div>
      )}
      {showInvoicePrompt && (
        <div className='mb-3'>
          <Notification
            noteText={intl.formatMessage({
              id: 'TRACK_PAGE.INSPECTION_TAB.UPL_INVOICE',
            })}
          />
        </div>
      )}
    </div>
  )
}
