import {establishCountryFromOwner} from '../../../_metronic/helpers/custom/funcs/establishOwnerFromDomain'
import {VectorUserModel} from '../../modules/auth'
import {IDashboardInfo, IDashboardItem} from './core/_models'

export const dashboardData = (
  dashboard: IDashboardInfo,
  currentUser: VectorUserModel | undefined,
  intl: any
): IDashboardItem[][] => {
  const {buyout, parcel, track, balance} = dashboard
  const country = establishCountryFromOwner()
  // console.log('dash', intl.messages[`DASHBOARD_B_IN_${country.intl}_WH_ROW_TITLE`])

  return [
    [
      {
        title: intl.messages.DASHBOARD_BUYOUTS_TITLE,
        number: currentUser?.ordersCount || 0,
        linkTo: 'buyouts',
        blank: false,
        zeroItemsMessage: intl.messages.DASHBOARD_BUYOUTS_ZERO,
        noData: currentUser?.ordersCount === 0,
        type: 'buyout',
        rows: [
          {title: intl.messages.DASHBOARD_B_COMP_TO_CONF_ROW_TITLE, stat: buyout?.created},
          {title: intl.messages.DASHBOARD_B_CLIENT_TO_CONF_ROW_TITLE, stat: buyout?.approved},
          {
            title: intl.messages[`DASHBOARD_B_IN_${country.intl}_WH_ROW_TITLE`],
            stat: buyout?.in_storage,
          },
        ],
      },
      {
        title: intl.messages.DASHBOARD_TRACKS_TITLE,
        number: currentUser?.tracksCount || 0,
        blank: false,
        zeroItemsMessage: intl.messages.DASHBOARD_TRACKS_ZERO,
        noData: currentUser?.tracksCount === 0,
        linkTo: 'tracks/list/air',
        type: 'track',
        rows: [
          {
            title: intl.messages.DASHBOARD_T_EXPECTED_ROW_TITLE,
            // stat: {
            //   avia: {number: track?.await, linkTo: '/tracks/list/air'},
            //   sea: {number: track?.await, linkTo: '/tracks/list/sea'},
            //   filters: {in_storage: 0},
            // },
            stat: track?.await,

            // link: 'tracks',
            // filters: {},
          },
          {
            title: intl.messages.DASHBOARD_T_ARRIVED_ROW_TITLE,
            // stat: {
            //   avia: {number: track?.in_storage, linkTo: '/tracks/list/air'},
            //   sea: {number: track?.in_storage, linkTo: '/tracks/list/sea'},
            //   filters: {in_storage: 1},
            // },
            stat: track?.in_storage,

            // link: 'tracks',
            // filters: {},
          },
          {
            title: intl.messages.DASHBOARD_T_SHIPMENT_PROH_ROW_TITLE,
            // stat: {
            //   avia: {number: track?.in_storage_stop, linkTo: '/tracks/list/air'},
            //   sea: {number: track?.in_storage_stop, linkTo: '/tracks/list/sea'},
            //   filters: {dispatch: 0},
            // },
            stat: track?.in_storage_stop,

            // link: 'tracks',
            // filters: {},
          },
        ],
      },
    ],
    [
      {
        title: intl.messages.DASHBOARD_PARCELS_TITLE,
        number: currentUser?.packagesCount || 0,
        linkTo: 'parcels/list/air',
        blank: false,
        zeroItemsMessage: intl.messages.DASHBOARD_PARCELS_ZERO,
        noData: currentUser?.packagesCount === 0,
        type: 'parcel',
        rows: [
          {title: intl.messages.DASHBOARD_P_COMING_ROW_TITLE, stat: parcel?.sent},
          {title: intl.messages.DASHBOARD_P_ARRIVED_ROW_TITLE, stat: parcel?.arrived},
          // {title: intl.messages.DASHBOARD_P_WAREHOUSE_ROW_TITLE, stat: parcel?.arrived},
          {
            title: intl.messages.DASHBOARD_P_TO_BE_PAID_ROW_TITLE,
            stat: parcel?.detained,
            add: parcel?.to_release,
          },
        ],
      },
      {
        title: intl.messages.DASHBOARD_BALANCE_TITLE,
        number: currentUser?.balance ? +currentUser.balance : 0,
        linkTo: 'balance',
        blank: false,
        zeroItemsMessage: intl.messages.DASHBOARD_BALANCE_ZERO,
        noData: !balance?.plus && !balance?.minus,
        type: 'balance',
        rows: [
          {
            title: intl.messages.DASHBOARD_BAL_LAST_PLUS_ROW_TITLE,
            stat: balance?.plus?.sum ? `$${balance?.plus.sum}` : '-',
            date: `${balance?.plus?.time ? balance?.plus.time : ''}`,
          },
          {
            title: intl.messages.DASHBOARD_BAL_LAST_MINUS_ROW_TITLE,
            stat: balance?.minus?.sum ? `$${balance?.minus.sum}` : '-',
            date: `${balance?.minus?.time ? balance?.minus.time : ''}`,
          },
        ],
      },
    ],
  ]
}
