import axios from 'axios'
import {TelegramUser} from 'telegram-login-button'
import {ID} from '../../../../_metronic/helpers'
import {
  establishBackendFromUrl,
  establishOwnerFromDomain,
} from '../../../../_metronic/helpers/custom/funcs/establishOwnerFromDomain'
import {getConfig} from '../../../../_metronic/i18n/Metronici18n'
import {AuthModel, VectorUserModel} from './_models'

const INIT_API_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${process.env.REACT_APP_API_PATH}`
const V_API_URL = `${process.env.REACT_APP_HTTP}${establishBackendFromUrl()}${
  process.env.REACT_APP_API_PATH
}`

let API_URL = INIT_API_URL
if (localStorage.getItem('vector') === 'true') {
  API_URL = V_API_URL
}

export const GET_TOS = (websiteId: ID) => `${INIT_API_URL}/public/websites/${websiteId}/tos`

export const GET_USER = () => `${API_URL}/profile`
export const LOGIN_URL = () => `${INIT_API_URL}/login_check`
export const GOOGLE_LOGIN_URL = () => `${INIT_API_URL}/auth/google`
export const FACEBOOK_LOGIN_URL = () => `${INIT_API_URL}/auth/facebook`
export const TELEGRAM_LOGIN_URL = () => `${INIT_API_URL}/auth/telegram`
export const REGISTER_URL = () => `${INIT_API_URL}/register`
export const CONFIRM_URL = () => `${INIT_API_URL}/register/confirm-email`
export const RESEND_URL = () => `${INIT_API_URL}/register/resend`
export const confirmTelegram = `${INIT_API_URL}/register/check_telegram_confirm`
export const REQUEST_PASSWORD_URL = () => `${INIT_API_URL}/remind`
export const RECOVERY_PASSWORD_URL = () => `${API_URL}/recovery`

// Server should return AuthModel
export function switchToVector(vector: boolean) {
  if (vector) {
    API_URL = V_API_URL
    localStorage.setItem('vector', 'true')
  } else {
    API_URL = INIT_API_URL
    localStorage.setItem('vector', 'false')
  }
  return true
}

export function crauzerRegister(
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  phoneNumber: string,
  lang: string
) {
  return axios.post(
    REGISTER_URL(),
    {
      email,
      firstName,
      lastName,
      password,
      phoneNumber,
    },
    {headers: {'Accept-Language': lang}}
  )
}

export function getTOS(websiteId: ID) {
  const {selectedLang} = getConfig()

  return axios.get(GET_TOS(websiteId), {headers: {'Accept-Language': selectedLang}})
}

export function confirmViaTelegram(token: string) {
  return axios.post(
    confirmTelegram,
    {
      token,
    },
    {headers: {owner: establishOwnerFromDomain()}}
  )
}

export function login(username: string, password: string, lang: string, vector: boolean) {
  API_URL = INIT_API_URL
  localStorage.setItem('vector', 'false')

  return axios.post<AuthModel>(
    LOGIN_URL(),
    {
      username,
      password,
    },
    {headers: {'Accept-Language': lang, Owner: establishOwnerFromDomain()}}
  )
}

export function googleLogin(token: string) {
  return axios.post<AuthModel>(GOOGLE_LOGIN_URL(), {accessToken: token})
}
export function facebookLogin(token: string) {
  return axios.post<AuthModel>(FACEBOOK_LOGIN_URL(), {accessToken: token})
}

export function telegramLogin(user: TelegramUser) {
  const {selectedLang} = getConfig()
  return axios
    .post<{token: string}>(TELEGRAM_LOGIN_URL(), user, {
      headers: {'Accept-Language': selectedLang},
    })
    .then((res) => res)
}

export function reg_confirm(token: string) {
  return axios.post(CONFIRM_URL(), {token})
}

export function confirmEmailResend(email: string) {
  return axios.post(RESEND_URL(), {email})
}

export function requestPassword(email: string) {
  const {selectedLang} = getConfig()

  return axios.post<{success: boolean; message: string}>(
    `${INIT_API_URL}/password-reset/request`,
    {
      email,
    },
    {headers: {'Accept-Language': selectedLang}}
  )
}

// for profile tab
export function updatePassword(password: string) {
  return axios.post(`${V_API_URL}/profile/update`, {
    password,
  })
}

// for forgotpassword
export function crauzerUpdatePassword(password: string, token: string) {
  return axios.post(`${INIT_API_URL}/password-reset`, {
    newPassword: password,
    token,
  })
}

export function getUser() {
  if (establishOwnerFromDomain() !== 8) {
    switchToVector(true)
  }
  return axios.get<VectorUserModel>(GET_USER())
}

// obsolete, maybe delete
export function requestRecovery(token: string, id: string) {
  switchToVector(true)
  return axios.post<{success: boolean; message: string; token?: string}>(RECOVERY_PASSWORD_URL(), {
    token,
    id,
  })
}

// obsolete, maybe delete
export function register(
  email: string,
  phoneNumber: string,
  firstName: string,
  lastName: string,
  password: string,
  password_confirmation: string,
  lang: string
) {
  switchToVector(true)
  return axios.post(
    REGISTER_URL(),
    {
      email,
      phoneNumber: phoneNumber && phoneNumber.indexOf('+') === -1 ? `+${phoneNumber}` : '',
      firstName,
      lastName,
      password,
    },
    {headers: {'Accept-Language': lang}}
  )
}
