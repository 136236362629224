import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {
  GoodsBrandsQueryResponse,
  GoodsCategoriesQueryResponse,
} from '../../../../../_metronic/helpers/custom/tsHelpers/categoriesHelpers'
import {getConfig} from '../../../../../_metronic/i18n/Metronici18n'
import {
  ICreateNewBuyout,
  IEditBuyout,
  Buyout,
  BuyoutsQueryResponse,
  IBuyoutLastMessage,
} from './_models/_buyout-models'

const API_URL = process.env.REACT_APP_API_PATH
const BUYOUT_URL = `/buyout`
const BASE_URL = `${API_URL}${BUYOUT_URL}`

const CRAUZER_URL = `${process.env.REACT_APP_HTTP}${process.env.REACT_APP_URL}${process.env.REACT_APP_API_PATH}`

const getBuyouts = (query: string): Promise<BuyoutsQueryResponse> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<BuyoutsQueryResponse>) => d.data)
}

const getBuyoutById = (id: ID): Promise<Buyout | undefined> => {
  const {selectedLang} = getConfig()

  return axios
    .get(`${BASE_URL}/getById`, {
      params: {id},
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((response: AxiosResponse<Buyout>) => response.data)
}

const checkBuyout = async (code: string | number) => {
  const {selectedLang} = getConfig()

  const {data} = await axios.post(`${BASE_URL}/check_buyout`, {
    code,
    headers: {
      'Accept-Language': selectedLang,
    },
  })

  return data
}

const archiveBuyout = async (id: ID, status: number) => {
  const res = await axios.get(`${BASE_URL}/archive?id=${id}&state=${status}`, {
    params: {id},
  })

  return res
}

const deleteBuyout = async (id: ID) => {
  const {selectedLang} = getConfig()

  const res = await axios.get(`${BASE_URL}/delete`, {
    params: {id},
    headers: {
      'Accept-Language': selectedLang,
    },
  })

  return res
}

const addBuyout = (buyout: ICreateNewBuyout): Promise<Buyout | undefined> => {
  return axios
    .post(`${BASE_URL}/add`, buyout)
    .then((response: AxiosResponse<Response<Buyout>>) => response.data)
    .then((response: Response<Buyout>) => response.data)
}

const editBuyout = (buyout: IEditBuyout): Promise<Buyout | undefined> => {
  return axios
    .post(`${BASE_URL}/edit`, buyout)
    .then((response: AxiosResponse<Response<Buyout>>) => response.data)
    .then((response: Response<Buyout>) => response.data)
}

const confirmBuyout = async (id: ID) => {
  const res = await axios.get(`${BASE_URL}/confirm?id=${id}`)

  return res
}

const getBuyoutCategories = async (
  category: string,
  group: string | number
): Promise<GoodsCategoriesQueryResponse> => {
  const {selectedLang} = getConfig()

  return await axios
    .post(
      `${BASE_URL}/nomcats`,
      {
        value: category,
        group,
      },
      {
        headers: {
          'Accept-Language': selectedLang,
        },
      }
    )
    .then((d: AxiosResponse<GoodsCategoriesQueryResponse>) => d.data)
}

const getBuyoutBrandsByCat = async (category: number): Promise<GoodsBrandsQueryResponse> => {
  const {selectedLang} = getConfig()

  return await axios
    .post(
      `${BASE_URL}/brands`,
      {
        category,
      },
      {
        headers: {
          'Accept-Language': selectedLang,
        },
      }
    )
    .then((d: AxiosResponse<GoodsBrandsQueryResponse>) =>
      d.data.map((item) => {
        return {...item, label: item.name || '', value: item.id || ''}
      })
    )
}

const getBuyoutLastMessage = (id: string | number) => {
  const {selectedLang} = getConfig()
  return axios
    .get(`${CRAUZER_URL}/buyouts/last-message?externalId=${id}`, {
      headers: {
        'Accept-Language': selectedLang,
      },
    })
    .then((d: AxiosResponse<Response<IBuyoutLastMessage>>) => d.data)
    .then((d: Response<IBuyoutLastMessage>) => d.data)
}

export {
  getBuyouts,
  deleteBuyout,
  checkBuyout,
  addBuyout,
  editBuyout,
  getBuyoutById,
  archiveBuyout,
  confirmBuyout,
  getBuyoutCategories,
  getBuyoutLastMessage,
  getBuyoutBrandsByCat,
}
